import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { GlobalService } from "../../globals/global.service";
import { BehaviorSubject, Observable } from "rxjs/Rx";
import "rxjs/Rx";

@Injectable()
export class CampaignService {
  public data;
  activeCampaign: BehaviorSubject<any> = new BehaviorSubject(0);
  campaignChange: BehaviorSubject<any> = new BehaviorSubject(false);
  changeCampaign: BehaviorSubject<any> = new BehaviorSubject(false);
  campaignDisabled: BehaviorSubject<any> = new BehaviorSubject(true);
  cartStatus: BehaviorSubject<any> = new BehaviorSubject(false);
  constructor(private http: HttpClient, public globalService: GlobalService) {}

  /**
   * Service for Categories
   */

  getCampaign(categoryId = null) {
    let params = "";
    if (categoryId !== null) {
      params = "/" + categoryId;
    }
    const userSettings = this.globalService.getUserSettings();
    const webShopSettings = this.globalService.webShopSettings();
    const userData = this.globalService.getLoggedInData();


    return this.http.get(environment.ENVIRONMENT.baseURL + userSettings.LanguageCode + "/webshopcategories" + params + "?customerID=" + webShopSettings.CustomerID + (userData != null ? "&userID=" + userData.UserID : ""),{ responseType: "json" }).publishReplay(1).refCount();
  }

  getCampaignForUser() {
    const userSettings = this.globalService.getUserSettings();
    const userData = this.globalService.getLoggedInData();
    if(userData) {
      return this.http.get(environment.ENVIRONMENT.baseURL + userSettings.LanguageCode + "/UserCampaignList/" + userData.UserID, { responseType: "json" }).publishReplay(1).refCount();
    }
  }

  getCampaignProducts() {
    const userSettings = this.globalService.getUserSettings();
    const userData = this.globalService.getLoggedInData();
    return this.http.get(environment.ENVIRONMENT.baseURL + userSettings.LanguageCode + "/UserCampaignList/" + userData.UserID, { responseType: "json" }).publishReplay(1).refCount();
  }

  addRewards(params){
    const userSettings = this.globalService.getUserSettings();
    return this.http.post(environment.ENVIRONMENT.baseURL + userSettings.LanguageCode + "/customer/AddReward", params, { responseType: "json" });
  }

  setActiveCampaign(activeCampaign: any) {
    this.activeCampaign.next(activeCampaign);
  }

  getActiveCampaign(): Observable<any> {
    return this.activeCampaign.asObservable();
  }

  setCampaignStatus(status: any){
    this.campaignDisabled.next(status)
  }

  getCampaignStatus(): Observable<any>{
    return this.campaignDisabled.asObservable()
  }


  setChangeCampaign(change: any){
    this.changeCampaign.next(change);
  }

  getChangeCampaign(): Observable<any>{
    return this.changeCampaign.asObservable();
  }

  setCartStatus(status: any){
    this.cartStatus.next(status);
  }

  getCartStatus(): Observable<any>{
    return this.cartStatus.asObservable();
  }

}
