import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ToastService } from 'app/typescripts/pro/alerts';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ProductService, MessageService, CampaignService } from 'app/linknet-common/restful-services/restful.service';
import { GlobalService } from 'app/linknet-common/globals/global.service';
import { INgxMyDpOptions } from 'ngx-mydatepicker';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { ValidationService } from 'app/linknet-common/validators';
import { ToastrService } from 'ngx-toastr';
import { MdbCompleterDirective } from 'app/typescripts/pro';

@Component({
  selector: 'shop-app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss'],
  exportAs: 'child'
})
export class AdvancedSearchComponent implements OnInit {

  public totalWebhsopProduct: any;
  @Input() dropdown: any;
  @Input() productListPage: any;
  @Input() productName: any;
  public productSearch: any;
  public productNameSearch: any;
  public selectedColorID: any;
  public advancedSearchParam: FormGroup;
  public optionsSelect: Array<any>;
  public categories;
  public categorySelect: Array<any>;
  public colorsList: Array<any>;
  public advancedSearchSorting = [];
  public productSearchList: Array<any>;
  public isLikedProducts: any;
  public isProductBlocks: any;
  public isFolder: any;
  public isJoinOrders: any;
  public selectedCityId: any;
  public queryDate = [];
  public prefillDate: any;
  public myOptions: INgxMyDpOptions = {
    dateFormat: 'dd-mm-yyyy',
  };
  public CID: any;
  public queryParams: any;
  public id: any;
  public translatedMessage: any;
  public sort0: any;
  public sort1: any;
  public sort2: any;
  public sort3: any;
  public sort4: any;
  public clearButton: any;
  public colorSelectStatus: Boolean = false;
  public advancedSearchParamStatus: Boolean = true;
  public productLabel: any;
  public searchLabel: any;
  public productNameLabel: any;
  public userSettings: any;
  public selectedValueText: any;
  public isAdvancedSearchCleared: any;
  public brandingOptions = [];
  public brandingOption1: any;
  public brandingOption2: any;
  public brandingOption3: any;
  public brandingOption4: any;
  public brandingOption5: any;
  public brandingOption6: any;
  public brandingOption7: any;
  public brandingOption8: any;
  public brandingOption9: any;
  public webShopSettings: any;
  webshopDetail: any;
  ProductTagsGroups: any = [];
  productTagsDetail: any;
  isTagsFilterExists: boolean = false;
  public changeDeliveryDateText: Boolean = false;  // for display text as delivery date / Earliest disp. date in HTML Page
  public wsaStatus: Boolean = false;
  isHrm: boolean = false;
  @ViewChild('mdbcompleter') public mdbcompleter: MdbCompleterDirective;

  constructor(public router: Router, private toastService: ToastService,
    public formBuilder: FormBuilder, public globalService: GlobalService,
    public _httpProductService: ProductService,
    private translate: TranslateService, public route: ActivatedRoute, private toastr: ToastrService,
    private _httpMessagesService: MessageService, private campaignService :CampaignService) {
    this.optionsSelect = [];
    this.productSearchList = [];
    this.CID = this.router.routerState.snapshot.url;
    this.queryParams = this.router.routerState.snapshot.root.queryParams;
    this.CID = this.CID.split('/');
    this.isLikedProducts = this.router.routerState.snapshot.root.queryParams.likedProducts;
    this.isFolder = this.router.routerState.snapshot.root.queryParams.FolderID;
    this.isJoinOrders = this.router.routerState.snapshot.root.queryParams.joinlinkorders;
    this.isProductBlocks = this.CID[1];
    this.userSettings = this.globalService.getUserSettings();
    this.campaignService.getActiveCampaign().subscribe(
      data=>{
        if(data){
          this.getTotalProduct();
        }
      }
    );
  }

  ngOnInit() {
    this._httpMessagesService.currentProductTagsData.subscribe(data => this.ProductTagsGroups = data)
    const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    if(webshopDetail.DomainName === "247hrm" || webshopDetail.DomainName === "247hrm"){
      this.isHrm = true;
    }
    if (webshopDetail.ChangeDeliveryDateText) {
      this.changeDeliveryDateText = true;
    }
    if (Number(webshopDetail.CustomerID) === 45) {
      this.wsaStatus = true;
    }

    this.setTagsFilterValues();

    this.getTotalProduct();
    this.translate.get('HEADING.ALL_PRODUCTS').subscribe((res: string) => {
      this.advancedSearchSorting[0] = {
        'value': 0,
        'label': res
      };
    });
    this.translate.get('HEADING.NEWEST_PRODUCT').subscribe((res: string) => {
      this.advancedSearchSorting[1] = {
        'value': 1,
        'label': res
      };
    });
    this.translate.get('HEADING.CHEAPEST_PRODUCT').subscribe((res: string) => {
      this.advancedSearchSorting[2] = {
        'value': 2,
        'label': res
      };
    });
    this.translate.get('HEADING.OLDEST_PRODUCTS').subscribe((res: string) => {
      this.advancedSearchSorting[3] = {
        'value': 3,
        'label': res
      };
    });
    this.translate.get('HEADING.EXPENSIVE_PRODUCT').subscribe((res: string) => {
      this.advancedSearchSorting[4] = {
        'value': 4,
        'label': res
      };
    });

    // Populate "Show prices including branding cost" dropdown list
    setTimeout(() => {
      this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_1COLOR').subscribe((res: string) => {
        this.brandingOption1 = res;
        this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_2COLOR').subscribe((res: string) => {
          this.brandingOption2 = res;
          this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_3COLOR').subscribe((res: string) => {
            this.brandingOption3 = res;
            this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_4COLOR').subscribe((res: string) => {
              this.brandingOption4 = res;
              this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_EMBROIDERY').subscribe((res: string) => {
                this.brandingOption5 = res;
                this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_ENGRAVING').subscribe((res: string) => {
                  this.brandingOption6 = res;
                  this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_EMBOSSING').subscribe((res: string) => {
                    this.brandingOption7 = res;
                    this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_DEBOSSING').subscribe((res: string) => {
                      this.brandingOption8 = res;
                      this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_PHOTOPRINT').subscribe((res: string) => {
                        this.brandingOption9 = res;
                        //console.log("After Branding Response");
                        this.brandingOptions = [
                          { 'value': 1, 'label': this.brandingOption1 },
                          { 'value': 2, 'label': this.brandingOption2 },
                          { 'value': 3, 'label': this.brandingOption3 },
                          { 'value': 4, 'label': this.brandingOption4 },
                          { 'value': 5, 'label': this.brandingOption5 },
                          { 'value': 6, 'label': this.brandingOption6 },
                          { 'value': 7, 'label': this.brandingOption7 },
                          { 'value': 8, 'label': this.brandingOption8 },
                          { 'value': 9, 'label': this.brandingOption9 }
                        ];
                      });
                    });
                  });
                });
              });
            });
          });
        });
      });










    }, 150);

    this.isAdvancedSearchCleared = false;
    this.clearButton = '';
    if (this.CID.indexOf('/search?') === -1) {
      this.clearButton = 'disabled';
    }

    try {
      this.webShopSettings = this.globalService.webShopSettings();
    } catch (error) {
      this.toastr.error(error);
    }

    try {
      this.initializeFormValues();
      this.categorySelect = JSON.parse(sessionStorage.getItem('categoryOptionList'))
      // console.log( this.categorySelect )
      // console.log( sessionStorage.getItem('categoryOptionList') )
    } catch (error) {
      this.toastr.error(error);
    }

    try {
      if (this.productListPage === true) {
        this._httpProductService.getProductColorsByID(JSON.parse(sessionStorage.getItem('colorListDropDownStatus'))).subscribe(
          (data: any[]) => {
            this.colorsList = data;
          },
          _error => {
            // this.toastService.error('Error: ' + error._body);
            // this.toastr.error('Error: ' + error.error.Message);
            //console.log(error)

          }
        );
      } else {
        if (JSON.parse(sessionStorage.getItem('colorsList')) === undefined ||
          JSON.parse(sessionStorage.getItem('colorsList')) === null) {
          this._httpProductService.getProductColors(true).subscribe(
            (data: any[]) => {
              this.colorsList = data;
              sessionStorage.setItem('colorsList', JSON.stringify(this.colorsList));
            },
            _error => {
              // this.toastService.error('Error: getProductColors' + error._body);
              //console.log(error)
              // this.toastr.error('Error: ' + error.error.Message);
            }
          );
        } else {
          this.colorsList = JSON.parse(sessionStorage.getItem('colorsList'));
        }
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }

  clearFormValues() {
    this.id = null;
    this.productSearch = null;
    this.productNameSearch = null;
    this.selectedColorID = null;
    this.advancedSearchParam.reset();
    this.isAdvancedSearchCleared = true;
    this.advancedSearchParam.patchValue({
      PriceFrom: '', PriceTo: '', Quantity: '', SortBy: '',
      CategoryID: null, ShowPricesIncludingLogoType: null
    });

    sessionStorage.removeItem('advanceSearchParams');
    setTimeout(() => {
      this.clearButton = 'disabled';
      this.advancedSearchParamStatus = true;
    }, 100);
  }

  initializeFormValues() {
    try {
      //console.log('initializeFormValues', this.queryParams);
      this.advancedSearchParam = this.formBuilder.group({
        'CategoryID': [this.CID[3] ? this.CID[3] : null, []],
        'ShowPricesIncludingLogoType': [this.queryParams.ShowPricesIncludingLogoType ? this.queryParams.ShowPricesIncludingLogoType
          : null, []],
        'Quantity': [this.queryParams.Quantity ? this.queryParams.Quantity : '', []],
        'PriceFrom': [this.queryParams.PriceFrom ? this.queryParams.PriceFrom : '', [ValidationService.isNumberCheck]],
        'PriceTo': [this.queryParams.PriceTo ? this.queryParams.PriceTo : '', [ValidationService.isNumberCheck]],
        'DeliveryDate': [this.queryParams.DeliveryDate ? this.queryParams.DeliveryDate : '', []],
        'SortBy': [this.queryParams.SortBy ? this.queryParams.SortBy : '', []],
        'SortByColor': ['']
      });
      this.productNameSearch = this.productName ? this.productName : this.queryParams.ProductName ? this.queryParams.ProductName : '';

      this.router.events
        .filter((e): e is NavigationEnd => e instanceof NavigationEnd)
        .pairwise().subscribe(() => {
          this.setTagsFilterValues();
        });

      this.router.events.subscribe(() => {
        this.queryParams = this.router.routerState.snapshot.root.queryParams;
        if ((this.queryParams.DeliveryDate !== null && this.queryParams.DeliveryDate !== ''
          && this.queryParams.DeliveryDate !== undefined)) {
          this.queryDate = this.queryParams.DeliveryDate.split('/');
          this.prefillDate = {
            date: {
              year: this.queryDate[0], month: this.queryDate[1],
              day: this.queryDate[2]
            }
          };
        } else {
          this.prefillDate = '';
        }

        if ((this.queryParams.ColorID !== null && this.queryParams.ColorID !== ''
          && this.queryParams.ColorID !== undefined)) {
          this.id = this.queryParams.ColorID;
        } else {
          this.id = null;
        }
        this.advancedSearchParam.patchValue({
          CategoryID: this.selectedValueText,
          ShowPricesIncludingLogoType: this.queryParams.ShowPricesIncludingLogoType,
          Quantity: this.queryParams.Quantity,
          PriceFrom: this.queryParams.PriceFrom,
          PriceTo: this.queryParams.PriceTo,
          DeliveryDate: this.prefillDate
        }, { onlySelf: false, emitEvent: false });
        // this.setTagsFilterValues();
        if (this.productNameSearch === '' || this.productNameSearch === null) {
          if (this.queryParams.ProductName !== null && this.queryParams.ProductName !== ''
            && this.queryParams.ProductName !== undefined) {
            this.productNameSearch = this.queryParams.ProductName
          }
        }
        if (this.queryParams.Quantity === undefined) {
          // this.advancedSearchParam.patchValue({
          //   Quantity: '',
          // }, { onlySelf: false, emitEvent: false });
          this.advancedSearchParam = this.formBuilder.group({
            'CategoryID': [this.queryParams.CategoryID ? this.queryParams.CategoryID : null, []],
            'ShowPricesIncludingLogoType': [this.queryParams.ShowPricesIncludingLogoType
              ? this.queryParams.ShowPricesIncludingLogoType : null, []],
            'Quantity': [this.queryParams.Quantity ? this.queryParams.Quantity : '', []],
            'PriceFrom': [this.queryParams.PriceFrom ? this.queryParams.PriceFrom : '', [ValidationService.isNumberCheck]],
            'PriceTo': [this.queryParams.PriceTo ? this.queryParams.PriceTo : '', [ValidationService.isNumberCheck]],
            'DeliveryDate': [this.prefillDate ? this.prefillDate : '', []],
            'SortBy': [this.queryParams.SortBy ? this.queryParams.SortBy : '', []],
            'SortByColor': ['']
          });
          this.productNameSearch = this.productName ? this.productName : this.queryParams.ProductName ? this.queryParams.ProductName : '';
        }
        const searchParams = JSON.parse(sessionStorage.getItem('advanceSearchParams'))
        if (searchParams !== null) {
          this.productNameSearch = searchParams.ProductName ? searchParams.ProductName :
            this.queryParams.ProductName ? this.queryParams.ProductName : '';
          if ((this.queryParams.ColorID !== null && this.queryParams.ColorID !== ''
            && this.queryParams.ColorID !== undefined)) {
            this.id = this.queryParams.ColorID;
          } else {
            this.id = searchParams.ColorID;
          }
          if ((this.queryParams.DeliveryDate !== null && this.queryParams.DeliveryDate !== ''
            && this.queryParams.DeliveryDate !== undefined)) {
            this.queryDate = this.queryParams.DeliveryDate.split('/');
            this.prefillDate = {
              date: {
                year: this.queryDate[0], month: this.queryDate[1],
                day: this.queryDate[2]
              }
            };
          } else {
            if (searchParams.DeliveryDate !== null) {
              this.queryDate = searchParams.DeliveryDate.split('/');
              this.prefillDate = {
                date: {
                  year: this.queryDate[0], month: this.queryDate[1],
                  day: this.queryDate[2]
                }
              };
            }
          }
          this.advancedSearchParam.patchValue({
            CategoryID: searchParams.CategoryID ? searchParams.CategoryID : this.queryParams.CategoryID ? this.queryParams.CategoryID : null,
            ShowPricesIncludingLogoType: searchParams.ShowPricesIncludingLogoType ? searchParams.ShowPricesIncludingLogoType
              : this.queryParams.ShowPricesIncludingLogoType ? this.queryParams.ShowPricesIncludingLogoType : null,
            Quantity: searchParams.Quantity ? searchParams.Quantity : this.queryParams.Quantity ? this.queryParams.Quantity : '',
            PriceFrom: searchParams.PriceFrom ? searchParams.PriceFrom : this.queryParams.PriceFrom ? this.queryParams.PriceFrom : '',
            PriceTo: searchParams.PriceTo ? searchParams.PriceTo : this.queryParams.PriceTo ? this.queryParams.PriceTo : '',
            DeliveryDate: this.prefillDate
          }, { onlySelf: false, emitEvent: false });
        }
      });
      setTimeout(() => {
        if (this.router.routerState.snapshot.url.indexOf('?') !== -1) {
          this.clearButton = '';
        } else {
          this.clearButton = 'disabled';
        }
      }, 100);
    } catch (error) {
      this.toastr.error(error);
    }
  }

  onPriceChanged(_value: any, _toastrstatus: boolean) {
    const quantityControl = this.advancedSearchParam.get('Quantity');
    const priceFrom = this.advancedSearchParam.get('PriceFrom');
    const priceTo = this.advancedSearchParam.get('PriceTo');
    this.toastr.clear();
    setTimeout(() => {
     /* if ((value > 0 || priceFrom.value > 0 || priceTo.value > 0)) {
        quantityControl.setValidators([Validators.required]);
        if ((quantityControl.value === '' || quantityControl.value === null
          || quantityControl.value === undefined) && ((priceFrom.value !== null && priceFrom.value !== undefined) ||
            (priceTo.value !== null && priceTo.value !== undefined))) {
          this.advancedSearchParamStatus = false;
          //  this.translatedMessage = (this.translate.get('HEADING.PLEASE_ENTER_QUANTITY'));
          this.translate.get('HEADING.PLEASE_ENTER_QUANTITY').subscribe((res: string) => {
            this.translatedMessage = res;
          });
          if (toastrstatus) {
            // this.toastService.error(this.translatedMessage.value);
            this.toastr.error(this.translatedMessage);
          }
        }
      } else {
        this.advancedSearchParamStatus = true;
        quantityControl.clearValidators();
      }
      quantityControl.updateValueAndValidity();*/
    }, 200);
  }

  getProducts(param) {
    try {
      sessionStorage.removeItem('pageIndex');
      sessionStorage.removeItem('pageSize');
      this.productSearch = param;
      if(this.productSearch){
        this.router.navigate(['/search'], { queryParams: { ProductName: param }, replaceUrl: false });
      }
    } catch (error) {
      this.toastr.error(error);
    }
    /*var element = document.querySelectorAll(".navbar-toggler")[0] as HTMLElement;
    if(element){
      element.click();
    }*/
  }

  searchProduct(param) {
    try {
      this._httpProductService.searchProduct(param).subscribe(
        (data: any[]) => {
          this.productSearchList = data.map(a => a.ProductName);
        },
        error => {
          if(error.Message){
            this.toastr.error('Error: ' + error.Message);
          }
        }
      );
    } catch (error) {
      this.toastr.error(error);
    }
  }

  advancedSearch(params) {
    var mobileToggle = document.querySelectorAll('.navbar-collapse.collapse.show')[0] as HTMLElement;
    if(mobileToggle) {
      mobileToggle.classList.remove('show');
    } 
    sessionStorage.removeItem('pageIndex');
    sessionStorage.removeItem('pageSize');
    // To reset window height set in browser && sessionStorage for Pagination
    // window.sessionStorage.height = 0;
    const searchParams = JSON.parse(sessionStorage.getItem('advanceSearchParams'))
    if (!this.colorSelectStatus) {
      if (searchParams !== null) {
        if (searchParams.ColorID !== null) {
          this.selectedColorID = searchParams.ColorID;
        }
      } else if (this.selectedColorID) {
        this.selectedColorID = this.selectedColorID;
      } else {
        this.selectedColorID = null;
      }
    }

    sessionStorage.setItem('colorListDropDownStatus', JSON.stringify(1));
    let sortBy = 0;
    let categoryID = '';

    if (params.SortBy.value !== undefined) {
      sortBy = params.SortBy.value;
    }

    if (params.CategoryID.value !== undefined) {
      if (params.CategoryID.value !== null && params.CategoryID.value !== '' && params.CategoryID.value.value !== undefined) {
        categoryID = params.CategoryID.value.value;
        this.selectedValueText = params.CategoryID.value.label;
      } else if (!this.isAdvancedSearchCleared) {
        categoryID = this.queryParams.CategoryID;
      } else {
        categoryID = null;
        this.selectedValueText = null;
      }
    }

    this.isAdvancedSearchCleared = false;

    this.onPriceChanged('', true);
    // if (!this.productSearch) {
    //   this.productSearch = this.productNameSearch ? this.productNameSearch : '';
    // }
    this.advancedSearchParamStatus = true;
    if (this.advancedSearchParamStatus) {
      const postParams = {
        'CategoryID': categoryID,
        'ShowPricesIncludingLogoType': params.ShowPricesIncludingLogoType ? params.ShowPricesIncludingLogoType.value : null,
        'Quantity': params.Quantity ? params.Quantity.value : null,
        'PriceFrom': params.PriceFrom ? params.PriceFrom.value : null,
        'PriceTo': params.PriceTo ? params.PriceTo.value : null,
        'ColorID': this.selectedColorID ? this.selectedColorID : null,
        'ProductName': this.productNameSearch ? this.productNameSearch : null,
        'DeliveryDate': (params.DeliveryDate.value !== null && params.DeliveryDate.value !== ''
          && params.DeliveryDate.value !== undefined)
          ? (params.DeliveryDate.value.date.year + '/' + params.DeliveryDate.value.date.month
            + '/' + params.DeliveryDate.value.date.day) : null,
        'SortBy': sortBy,
        'isTagsFilterExists': this.isTagsFilterExists
      };

      try {
        sessionStorage.setItem('searchParamsbreadCrumbs', JSON.stringify(postParams));
        this.colorSelectStatus = false;
        if (this.CID[2] !== undefined) {
          this.router.navigate(['/search'], { queryParams: postParams, replaceUrl: false });
        } else {
          this.router.navigate(['/search'], { queryParams: postParams, replaceUrl: false });
        }

      } catch (error) {
        this.toastr.error('Error: ' + error);
      }
    }
    this.buttonHoverout();
  }

  selectedColor(param) {
    this.selectedColorID = param;
    this.colorSelectStatus = true;
  }

  clearAdvacedSearch() {
    try {
      sessionStorage.setItem('isSaveTagsFilterSaved', JSON.stringify(false));
      this.isTagsFilterExists = false;
      sessionStorage.removeItem('productTags');
      this.ProductTagsGroups.forEach(item => {
        item.value = null;
      });
      this.advancedSearchParamStatus = true;
      this.toastr.clear();
      this.clearFormValues();
      this.router.url.substring(0, this.router.url.indexOf('?'));
    } catch (error) {
      this.toastr.error(error);
    }
  }

  clearFilter() {
    try {
      this.router.navigate(['/search', this.CID[2], '']);
    } catch (error) {
      this.toastr.error(error);
    }
  }

  onlyNumberKey(event) {
    return (event.charCode === 8 || event.charCode === 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  // for Decimal you can use this as
  onlyDecimalNumberKey(event) {
    let pattern;
    if (this.userSettings.CurrencyCode === 'DKK') {
      pattern = new RegExp('^[0-9\,[0-9]{0,2}]?$');
    } else {
      pattern = new RegExp('^[0-9\.[0-9]{0,2}]?$');
    }
    const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!pattern.test(key) && event.charCode !== 0) {
      event.preventDefault();
      return false;
    }
  }

  // for Decimal you can use this as
  enterKeyRestrict(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    // tslint:disable-next-line:curly
    if (charCode === 13) {
      event.preventDefault();
      return false;
    } else {
      return charCode;
    }
  }

  checkFormFields(param) {
    if ((param.PriceTo.value !== '' && param.PriceTo.value !== null) ||
      (param.PriceFrom.value !== '' && param.PriceFrom.value !== null)) {
      if (param.Quantity.value === '' || param.Quantity.value === null) {
        this.advancedSearchParamStatus = false;
      } else {
        this.advancedSearchParamStatus = true;
      }
    }
    if (this.advancedSearchParam.controls.PriceFrom.errors !== null
      || this.advancedSearchParam.controls.PriceTo.errors !== null) {
      this.advancedSearchParamStatus = false;
    }
    setTimeout(() => {
      if ((param.CategoryID.value !== null && param.CategoryID.value !== '') ||
        (param.ShowPricesIncludingLogoType.value !== null && param.ShowPricesIncludingLogoType.value !== '') ||
        (param.DeliveryDate.value !== null && param.DeliveryDate.value !== '') ||
        (param.PriceFrom.value !== null && param.PriceFrom.value !== '') ||
        (param.PriceTo.value !== null && param.PriceTo.value !== '') ||
        (param.Quantity.value !== null && param.Quantity.value !== '') ||
        (param.SortBy.value !== null && param.SortBy.value !== '') ||
        (this.selectedColorID !== null && this.selectedColorID !== '' && this.selectedColorID !== undefined)) {
        this.clearButton = '';
      } else {
        this.clearButton = 'disabled';
      }
    }, 100);
  }

  placeholderLabel(){
    this.translate.get('HEADING.PRODUCTS').subscribe((res: string) => {
      this.productLabel = res;
      //console.log('this.productLabel', this.productLabel)
    });
    this.translate.get('HEADING.SEARCH_LABEL').subscribe((res: string) => {
      this.searchLabel = res;
      //console.log('this.searchLabel', this.searchLabel)

    });
    this.translate.get('HEADING.PRODUCT_NAMES').subscribe((res: string) => {
      this.productNameLabel = res;
     // console.log('this.productNameLabel', this.productNameLabel)

    });
  }
  getTotalProduct() {
      this.placeholderLabel()
    try {
      this._httpProductService.getTotalProduct().subscribe(
        (data: any[]) => {
          this.totalWebhsopProduct = data
          // this.translate.get('HEADING.PRODUCTS').subscribe((res: string) => {
          //   this.productLabel = res;
          //   console.log('this.productLabel', this.productLabel)
          // });
          // this.translate.get('HEADING.SEARCH_LABEL').subscribe((res: string) => {
          //   this.searchLabel = res;
          //   console.log('this.searchLabel', this.searchLabel)

          // });
          // this.translate.get('HEADING.PRODUCT_NAMES').subscribe((res: string) => {
          //   this.productNameLabel = res;
          //   console.log('this.productNameLabel', this.productNameLabel)

          // });
        },
        _error => {
          //this.toastr.error('Error: getTotalProduct ' + error.Message);
        }
      );
    } catch (error) {
      this.toastr.error(error);
    }
  }
  saveDetails(params) {
    const searchParams = {
      'CategoryID': params.CategoryID.value,
      'ShowPricesIncludingLogoType': params.ShowPricesIncludingLogoType ? params.ShowPricesIncludingLogoType.value : null,
      'Quantity': params.Quantity ? params.Quantity.value : null,
      'PriceFrom': params.PriceFrom ? params.PriceFrom.value : null,
      'PriceTo': params.PriceTo ? params.PriceTo.value : null,
      'ColorID': this.selectedColorID ? this.selectedColorID : null,
      'ProductName': this.productNameSearch ? this.productNameSearch : null,
      'DeliveryDate': (params.DeliveryDate.value !== null && params.DeliveryDate.value !== ''
        && params.DeliveryDate.value !== undefined)
        ? (params.DeliveryDate.value.date.year + '/' + params.DeliveryDate.value.date.month
          + '/' + params.DeliveryDate.value.date.day) : null,
      'isTagsFilterExists': this.isTagsFilterExists
    };
    sessionStorage.setItem('isSaveTagsFilterSaved', JSON.stringify(true));
    sessionStorage.setItem('advanceSearchParams', JSON.stringify(searchParams));
    sessionStorage.setItem('productTags', JSON.stringify(this.ProductTagsGroups));
  }

  buttonHover() {
    const selectElement = document.getElementById('advanceSerc');
    selectElement.classList.add('displayAdsearch');
  }
  buttonHoverout() {
    const selectElement = document.getElementById('advanceSerc');
    selectElement.classList.remove('displayAdsearch');
  }

  onTagFilterUpdate(item, val) {
    this.ProductTagsGroups.forEach((list) => {
      if (list.productTagsGroupID == item.productTagsGroupID) {
        list.label = [];
      }
    })
    val.forEach(list => {
      const listVal = item.productTags.find((item) => {
        return item.value == list
      });

      const productTags = this.ProductTagsGroups.find((list) => {
        return list.productTagsGroupID == item.productTagsGroupID
      });
      productTags.label.push(listVal.label);
      this.isTagsFilterExists = true;

    })
    sessionStorage.setItem('productTags', JSON.stringify(this.ProductTagsGroups));


    const sessionProductTags = JSON.parse(sessionStorage.getItem('productTags'));
    if (this.webshopDetail.ProductTagsGroups.length > 0 && this.webshopDetail.ProductTagsGroups !== null && this.webshopDetail.ProductTagsGroups !== undefined) {
      let productIds = [];
      if (sessionProductTags !== null && sessionProductTags !== undefined) {
        if (sessionProductTags.length > 0) {
          sessionProductTags.forEach((item) => {
            if (item.value.length > 0) {
              item.value.forEach((val) => {
                productIds.push(parseInt(val));
              });
            }
          });
        }
      }
      this._httpProductService.getProductSelectedTags(productIds).subscribe(
        (_tagdata: any[]) => {
          this.ProductTagsGroups = [];
          _tagdata.forEach((item, index) => {
            let selectedValue = [];
            let selectedlabel = [];
            if (sessionProductTags[index] !== undefined && sessionProductTags[index] !== null) {
              selectedValue = sessionProductTags[index].value;
              selectedlabel = sessionProductTags[index].label;
            }
            let products = {
              productTagsGroupID: null,
              productTagsGroupName: null,
              value: [],
              label: [],
              productTags: []
            };
            products = {
              productTagsGroupID: item.ProductTagsGroupID,
              productTagsGroupName: item.Name,
              value: selectedValue,
              label: selectedlabel,
              productTags: []
            }
            if (item.ProductTags.length > 0 && item.ProductTags !== null && item.ProductTags !== undefined) {
              item.ProductTags.forEach((element) => {
                products.productTags.push({
                  'value': element.ProductTagID,
                  'label': element.Name,
                })
              });
            }
            if (products.productTags.length > 0) {
              this.ProductTagsGroups.push(products);
            }
          });

          sessionStorage.setItem('productTags', JSON.stringify(this.ProductTagsGroups));
          this._httpMessagesService.setProductTagsSource(this.ProductTagsGroups);
        });
    }
  }

  setTagsFilterValues() {
    this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    const sessionProductTags = JSON.parse(sessionStorage.getItem('productTags'));
    // this.ProductTagsGroups = this.productTagsDetail;
    const isSaved = sessionStorage.getItem('isSaveTagsFilterSaved');
    const responseProductTags = [];
    if (this.webshopDetail.ProductTagsGroups.length > 0 && this.webshopDetail.ProductTagsGroups !== null && this.webshopDetail.ProductTagsGroups !== undefined) {
      this.webshopDetail.ProductTagsGroups.forEach((item) => {
        let products = {
          productTagsGroupID: null,
          productTagsGroupName: null,
          value: [],
          label: [],
          productTags: []
        };
        products = {
          productTagsGroupID: item.ProductTagsGroupID,
          productTagsGroupName: item.Name,
          value: [],
          label: [],
          productTags: []
        }
        if (item.ProductTags.length > 0 && item.ProductTags !== null && item.ProductTags !== undefined) {
          item.ProductTags.forEach((element) => {
            products.productTags.push({
              'value': element.ProductTagID,
              'label': element.Name,
            })
          });
        }
        if (products.productTags.length > 0) {
          responseProductTags.push(products);
        }
      });
    }

    // if (sessionProductTags != null && sessionProductTags != undefined && sessionProductTags.length > 0 && (this.queryParams['isTagsFilterExists'] === 'true' || isSaved == 'true')) {
    //   let productTagData = [];
    //   if (sessionProductTags !== undefined && sessionProductTags !== null && sessionProductTags.length > 0) {
    //     let productTag = sessionProductTags;

    //     for (let i = 0; responseProductTags.length > i; i++) {
    //       const checkData = productTag.filter(data => data.productTagsGroupID === responseProductTags[i].productTagsGroupID);
    //       if (checkData.length > 0) {
    //         if (checkData[0].value !== null) {
    //           checkData[0].value.forEach(function (item) {
    //             const isFindValue = responseProductTags[i].productTags.find(data => data.value == item)
    //             if (isFindValue) {
    //               responseProductTags[i].value.push(item);
    //               const lableVal = checkData[0].productTags.find(list => list.value == item)
    //               responseProductTags[i].label.push(lableVal.label);
    //             }
    //           })
    //         }
    //         productTagData.push(responseProductTags[i]);
    //       } else {
    //         productTagData.push(responseProductTags[i]);
    //       }
    //     }
    //   }
    //   sessionStorage.setItem('productTags', JSON.stringify(productTagData));
    //   this.productTagsDetail = JSON.parse(sessionStorage.getItem('productTags'));
    //   this.ProductTagsGroups = this.productTagsDetail;
    // } else {
    //   this.ProductTagsGroups = [];
    //   this.ProductTagsGroups = responseProductTags;
    // }

    if (sessionProductTags != null && sessionProductTags != undefined && sessionProductTags.length > 0) {
      this.productTagsDetail = JSON.parse(sessionStorage.getItem('productTags'));
      this.ProductTagsGroups = this.productTagsDetail;
    } else {
      this.ProductTagsGroups = [];
      this.ProductTagsGroups = responseProductTags;

    }
  }
}
