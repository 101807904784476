import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductService, OrdersService } from 'app/linknet-common/restful-services/restful.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../typescripts/pro/alerts';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../linknet-common/globals/global.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'shop-app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit {
  @ViewChild('orderStatus') public orderStatus;
  @ViewChild('unAuthorizedModal') public unAuthorizedModal;
  public productInfo: Object;
  public productSubOrderInfo: any;
  public orderedCurrencyCode: any;
  public orderFullDetails: any;
  public vATPercentage: any;
  public currencyCode: any;
  public logoFile: any;
  public showClaimsPage: Boolean = false;
  public orderItemState: any;
  public orderItemStates: any;
  public orderState: any;
  public isOrderHistoryPage: any;
  public translatedMessage: any;
  public printInvoiceUrl: any;
  public CID: any;
  public showRedirectButton: Boolean = false;
  public paymentTypeID: any;
  public status: Boolean = false;
  public isUnAuthorizePageParams: any;
  public isParentCustomer: Boolean = false;
  public changeDeliveryDateText: Boolean = false;  // for display text as delivery date / Earliest disp. date in HTML Page
  isVoucherKart: boolean = false;
  constructor(private _httpProductService: ProductService, private route: ActivatedRoute, private toastService: ToastService,
    public router: Router, public _httpOrdersService: OrdersService, public translate: TranslateService,
    public globalService: GlobalService, private toastr: ToastrService) {
    try {
      this.getOrderHistoryProduct();
      this.currencyCode = JSON.parse(sessionStorage.getItem('userSettings')).CurrencyCode;
    } catch (error) {
      this.toastr.error(error);
    }
  }

  ngOnInit() {
    this.getOrderDetails();
    this.isUnAuthorizePageParams = this.route.snapshot.params.isUnAuthorizedOrderPage;
    // try {
    //   this.isUnAuthorizePageParams = this.route.snapshot.params.isUnAuthorizedOrderPage;
    //   this.paymentTypeID = JSON.parse(sessionStorage.getItem('orderDetail'));
    //   this.paymentTypeID = this.paymentTypeID.OrderFullDetails.PaymentTypeID;
    //   // this.getProductDetail();
    //   this._httpProductService.getOrderStateItems(true).subscribe(
    //     (data: any[]) => {
    //       this.orderItemState = data;
    //       this.orderItemStates = this.orderItemState;
    //     })
    //   this.CID = this.router.routerState.snapshot.url;
    //   this.CID = this.CID.split('/');
    //   if (this.CID[1] === 'orderhistory') {
    //     this.getOrderDetail();
    //     this.showRedirectButton = true;
    //   }

    //   const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    //   if (webshopDetail.ChangeDeliveryDateText) {
    //     this.changeDeliveryDateText = true;
    //   }
    //   if(webshopDetail.DomainName === "voucherkart"){
    //     this.isVoucherKart = true;
    //   }else{
    //     this.isVoucherKart = false;
    //   }
    //   if(webshopDetail.ParentCustomerDomainName === webshopDetail.DomainName){
    //     this.isParentCustomer = true;
    //   }else{
    //     this.isParentCustomer = false;
    //   }
    // } catch (error) {
    //   this.toastr.error(error);
    // }
  }

  getOrderDetails(){
    const newOrderDet = sessionStorage.getItem('newOrderDetails');
    if(newOrderDet){
      this.orderFullDetails = JSON.parse(newOrderDet);
      console.log(this.orderFullDetails,"orderFullDetails");
      
    }
  }

  trackOrder(){
    let orderid = this.route.snapshot.params.OrderID;
    this.router.navigate(['/track-order'], { state: {orderid}, replaceUrl: false });
  }

  getOrderDetail() {
    try {
      const orderId = this.route.snapshot.params.OrderID;
      this._httpOrdersService.getOrdersDetails(orderId).subscribe(
        (data: any[]) => {
          const orderItemID = this.route.snapshot.params.OrderItemID;
          if (data['Suborders'].length === 0) {
            data['OrderItems'].forEach(elements => {
              if (elements.OrderItemID === Number(orderItemID)) {
                this.openOrderStatus(elements)
              }
            });
          } else {
            data['Suborders'].forEach(elements => {
              elements['OrderItems'].forEach(element => {
                if (element.OrderItemID === Number(orderItemID)) {
                  this.openOrderStatus(element)
                }
              });
            });
          }
        }, error => {
          // this.toastService.error(JSON.parse(error._body).Message);
          this.toastr.error(error.error.Message);
        })
    } catch (error) {
      this.toastr.error(error);
    }
  }

  getProductDetail() {
    try {
      this._httpProductService.getProductDetail(this.route.snapshot.params.ProductID, undefined, undefined, undefined).subscribe(
        (data: any[]) => {
          this.productInfo = data;
        })
    } catch (error) {
      this.toastr.error(error);
    }
  }

  getOrderHistoryProduct() {
    try {
      this.productSubOrderInfo = JSON.parse(sessionStorage.getItem('orderDetail'));
      this.paymentTypeID = this.productSubOrderInfo.PaymentTypeID;
      this.orderedCurrencyCode = this.productSubOrderInfo.OrderedCurrencyCode;
      // this.orderFullDetails = this.productSubOrderInfo.OrderFullDetails;
      this.vATPercentage = this.productSubOrderInfo.VATPercentage;
      this.productSubOrderInfo = this.productSubOrderInfo.SubOrder;
      for (let i = 0; i < this.productSubOrderInfo.OrderLogos.length; i++) {
        if (typeof this.productSubOrderInfo.OrderLogos[i].FileName !== 'undefined') {
          this.logoFile = this.productSubOrderInfo.OrderLogos[i].FileName.split('_');
          this.productSubOrderInfo.OrderLogos[i].logoOriginalFileName = this.logoFile[this.logoFile.length - 1];
        }
      }
      this.isOrderHistoryPage = this.route.snapshot.params.isOrderHistory;
    } catch (error) {
      // this.toastr.error(error);
    }
  }

  getExtension(filename) {
    if (typeof filename !== 'undefined') {
      return filename.split('.').pop();
    } else {
      return '';
    }
  }
  openClaims() {
    this.showClaimsPage = true;
  }

  openOrderStatus(params) {
    try {
      this.orderState = params.OrderItemStates;
      if (this.orderState) {
        this.orderItemStates.forEach(element => {
          this.orderState.forEach(state => {
            if (element.OrderItemStateID === state.OrderItemStateID) {
              element['lastupdateddate'] = state.Properties.date;
              const label = element.Options.filter(
                // tslint:disable-next-line:no-shadowed-variable
                data => ((data.StateOptionCode) === (state.Properties.StateOptionCode)));
              if (label.length !== 0) {
                element['label'] = label[0].StateOptionName;
              }
              if (state.Properties.StateOptionCode === 'ACCEPT_ORDER' || state.Properties.StateOptionCode === 'LOGO_SAMPLE_APPROVED'
                || state.Properties.StateOptionCode === 'PRODUCTION_STARTED' ||
                state.Properties.StateOptionCode === 'PRODUCTION_COMPLETED' || state.Properties.StateOptionCode === 'DISPATCHED' ||
                state.Properties.StateOptionCode === 'DELIVERED' || state.Properties.StateOptionCode === 'COMPLETED' ||
                state.StateCode === 'ORDER_RECIEVED') {
                element['checkedclassname'] = true;
              } else if (state.Properties.StateOptionCode === 'NOT_DECIDED') {
                element['timerclassname'] = true;
              } else if (state.Properties.StateOptionCode === 'NOT_PAID' || state.Properties.StateOptionCode === 'NOT_DISPATCHED' ||
                state.Properties.StateOptionCode === 'NOT_DELIVERED' || state.Properties.StateOptionCode === 'REJECT_ORDER') {
                element['removeclassname'] = true;
              } else if (state.Properties.StateOptionCode === 'PAY_BY_CREDIT_CARD' ||
                state.Properties.StateOptionCode === 'PAY_BY_INVOICE' || state.Properties.StateOptionCode === 'PAY_BY_PAYPAL') {
                element['fileclassname'] = true;
              } else if (state.Properties.StateOptionCode === 'MINIMUM_QUANTITY_REACHED') {
                element['greencircleclassname'] = true;
              } else if (state.StateCode === 'PRODUCTION_STARTED' || state.StateCode === 'PRODUCTION_COMPLETED') {
                element['greencircleclassname'] = true;
              } else {
                element['greycircleclassname'] = true;
              }
            }
          });
        });
        this.orderStatus.show();
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }

  reorder(param) {
    param.ProductName = param.ProductName.replace(/\//g, '');
    param.ProductName = param.ProductName.replace(/"/g, '').replace(/'/g, '').replace(/\(|\)/g, '')
    this.router.navigate(['/productdetail', param.ProductID, param.ProductName], { replaceUrl: false });
  }

  printInvoice() {
    try {
      const orderID = JSON.parse(sessionStorage.getItem('orderDetail'));
      const userSettings = this.globalService.getUserSettings();
      this.printInvoiceUrl = document.getElementById('invoicedownload');
      this.printInvoiceUrl.href = environment.ENVIRONMENT.baseURL + userSettings.LanguageCode
        + '/invoices/pdf/' + orderID.OrderFullDetails.OrderID;
      this.printInvoiceUrl.download = orderID.OrderFullDetails.OrderIdManual + '.pdf';
      this.printInvoiceUrl.click();
    } catch (error) {
      this.toastr.error(error);
    }
  }
  deleteUnAuthorizedOrder(params) {
    try {
      this._httpProductService.deleteUnAuthorizedOrders(params.OrderItemID).subscribe(
        _data => {
          this.router.navigate(['/orderhistory'], { replaceUrl: false });
        })
    } catch (error) {
      this.toastr.error(error);
    }
  }
  recalculateTransportation(_params) {
    try {
      const orderID = JSON.parse(sessionStorage.getItem('orderDetail'));
      this._httpProductService.recalculateTransportationOrders(orderID.OrderFullDetails.OrderID).subscribe(
        _data => {
          this.router.navigate(['/orderhistory'], { replaceUrl: false });
        })
    } catch (error) {
      this.toastr.error(error);
    }
  }
  openUnAuthorizedModel(status) {
    this.status = status
    this.unAuthorizedModal.show();
  }
  acceptUnAuthorizedOrder(productSubOrderInfo) {
    try {
      this._httpProductService.acceptUnAuthorizedOrders(productSubOrderInfo.OrderItemID).subscribe(
        _data => {
          this.router.navigate(['/orderhistory'], { replaceUrl: false });
        })
    } catch (error) {
      this.toastr.error(error);
    }
  }
  rejectUnAuthorizedOrder(productSubOrderInfo) {
    try {
      this._httpProductService.rejectUnAuthorizedOrders(productSubOrderInfo.OrderItemID).subscribe(
        _data => {
          this.router.navigate(['/orderhistory'], { replaceUrl: false });
        })
    } catch (error) {
      this.toastr.error(error);
    }
  }
  goBack() {
    this.router.navigate(['/orderhistory'], { replaceUrl: false });
  }
}
