import { Component, OnInit, SimpleChanges, OnChanges, Input,Output, EventEmitter } from '@angular/core';
import { ProductService, OrdersService ,CategoryService, CampaignService } from '../../linknet-common/restful-services/restful.service';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from '../../typescripts/pro/alerts';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { GlobalService } from 'app/linknet-common/globals/global.service';
import { ToastrService } from 'ngx-toastr';
import { CommonServicesService } from "app/linknet-common/common-services/common-services.service";
import { Subscription } from 'rxjs';

@Component({
  selector: 'shop-app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit, OnChanges { 

  isEditable : boolean = true;
  @Input() categoryProduct: any;
  @Input() productsList: any = {};
  public campaignList: boolean = false;
  public products: any;
  public categoryID: any;
  public BrandId: any;
  public sendData: any;
  public categoryName: any;
  public BrandName: any;
  public subCategory: any;
  public productBlocksID: any;
  public isProductBlock: any;
  public folderID: any;
  public perPageCount: any;
  public totalCount: any;
  public currentPage = 1;
  public totalPages: any;
  public spinnerShow = true;
  public pageIndex: any;
  public pageSize: any;
  public productParams: any;
  public hideDelete: boolean = true;
  public isHideBillBoard: boolean = true;
  public isShowFilter: boolean = true;
  public likedProducts: any;
  public isJoinLinkOrders: any;
  public categoryNameNoTranslate: any;
  public errorMessage: any;
  public productTitle: any;
  public isFilterBatchProducts: any;
  public CID: any;
  public logIndata : any;
  public tags = [];
  public dynamiTags = [];
  public colorTag: any;
  public categoryTag: any;
  public brandingTag: any;
  public brandingTitle: any;
  public brandTag: any;
  public campaignTag: any;
  public quantityTag: any;
  public priceFromTag: any;
  public deliveryDateTag: any;
  public priceToTag: any;
  public productTag: any;
  public translatedMessage: any;
  public productFiveColumnStatus: boolean = false;
  public sessionPageIndex: any;
  public sessionPageSize: any;
  public isAllowTag: boolean = false;
  public positionProductID: any;
  public isPassSubcategory: boolean = false;
  public subCategoryDisplayStatus: boolean = true;
  subCategoryTag: any;
  public isMarketingProduct: boolean;
  public isParentCustomer: boolean = false;
  public voucherCustomer :boolean = false;
  category: any;
  public webShopSettings: any;
  productTagDetails: any;
  isTagsFilterExists: boolean = false;
  public oldestProductFirstStatus: boolean = false;
  productBrandlist: any;
  brandIds: any;
  optionSubCategory: any;
  subCategoryList: any = [];
  hideTags: boolean = false;
  // CategoryID: any = [];
  getCategories : any;
  isMobile: boolean;
  tempCampaign: Subscription;
  campaignid: any;
  selectedCampaign: any;
  customerID: any;
  constructor(private commonService: CommonServicesService, private router: Router, private _httpProductService: ProductService, private route: ActivatedRoute, private toastService: ToastService, private _httpOrders: OrdersService, public titleService: Title, private translate: TranslateService, public datePipe: DatePipe, public globalService: GlobalService,
    private toastr: ToastrService, private _httpCategories: CategoryService,public campaignService: CampaignService,) {   
      if (/Android|webOS|iPhone|iPad|iPod|pocket|psp|kindle|avantgo|blazer|midori|Tablet|Palm|maemo|plucker|phone|BlackBerry|symbian|IEMobile|mobile|ZuneWP7|Windows Phone|Opera Mini/i.test(navigator.userAgent)) {
        this.isMobile = true;
    } else {
        this.isMobile = false;
    }
    this.logIndata = this.globalService.getLoggedInData();
    if(this.logIndata.DomainName === "voucherkart"){
      this.voucherCustomer = true;
    } else{
      this.voucherCustomer = false;
    }
    // console.log("log",this.logIndata)
    this.webShopSettings = this.globalService.webShopSettings();
    const webshopDetail = JSON.parse(sessionStorage.getItem("webshopDetail"));
    this.customerID = webshopDetail.CustomerID;
    // const campaign = JSON.parse(sessionStorage.getItem("campaign"));
    // if(campaign){
    //   this.campaignid = campaign.CampaignId;
    // }
    const campaignString = sessionStorage.getItem("campaign");
    if (campaignString !== "undefined") {
  const campaign = JSON.parse(campaignString);
  if (campaign && campaign.CampaignId !== undefined) {
    this.campaignid = campaign.CampaignId;
  }
}
      if (webshopDetail.ParentCustomerDomainName === webshopDetail.DomainName ) {
        this.isParentCustomer = true;
      } else {
        this.isParentCustomer = false;
      }
    route.params.subscribe(async() => {
      try {
        const page = {
          pageIndex: null,
          pageSize: null
        }
        page.pageIndex = page.pageIndex ? page.pageIndex : 1;
        page.pageSize = page.pageSize ? page.pageSize : 20;
        this.subCategory = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.SubCategory : null;
        this.categoryID = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.CategoryID : null;
        this.categoryName = this.route.snapshot.params ? this.route.snapshot.params.categoryName : null;
        this.BrandId = this.route.snapshot.params ? this.route.snapshot.params.BrandId : null;
        this.BrandName = this.route.snapshot.params ? this.route.snapshot.params.BrandName : null;
        this.CID = this.router.routerState.snapshot.url;
        
        let categoryList = JSON.parse(sessionStorage.getItem('categoryList'));
        // if(!categoryList){
        //   categoryList = await this._httpCategories.getCategories().toPromise();
        
        // }
      
        this.optionSubCategory = [];
        this.optionSubCategory.push({
          'Name': 'Select Sub-Category',
          'CategoryID': null,
          'ParentID': null
        });
        if (this.CID.indexOf('/search/') !== -1) {
          let CID = this.CID.split('/');
          this.categoryID = decodeURIComponent(CID[2]);
          this.subCategoryList = null;
          this.hideTags = false;
        }else{
          this.subCategoryList = null;
          this.categoryName =  '';
          this.hideTags = true;
        }
        if (this.categoryID !== undefined) {
          this.productFiveColumnStatus = false;
        }
        
        if(categoryList){
         
          categoryList.forEach(element => {
                if(element.SubCategories.length !== 0){
                  element.SubCategories.forEach(e=>{
                    this.optionSubCategory.push(e)
                    if(e.CategoryID === Number(this.categoryID)){
                      this.subCategoryList = Number(this.categoryID);
                      this.hideTags = false;
                    }
                  })
                }

          });

        }

        if (this.categoryID !== undefined) {
          this.productFiveColumnStatus = false;
          setTimeout(() => {
            const categoryList = JSON.parse(sessionStorage.getItem('categoryList'));
            if (categoryList !== null) {
              const subCategoryData = categoryList.filter(
                data1 => (Number(data1.CategoryID) === Number(this.categoryID)));
              // if category is Main Category
              if (subCategoryData[0] !== undefined) {
                this.categoryName = subCategoryData[0]['Name'];
                this.optionSubCategory = subCategoryData[0].SubCategories;
                this.optionSubCategory.unshift({
                  'Name': 'Select Sub-Category',
                  'CategoryID': null,
                  'ParentID': null
                });
                this.subCategoryList = subCategoryData[0].SubCategories[0].CategoryID;
              }else{
                let subData =  '';
                categoryList.forEach( (data2) => {
                    let len = data2.SubCategories.length;
                    for(let i = 0; i < len; i++){
                      if(data2.SubCategories[i].CategoryID === Number(this.categoryID)){
                        subData =  data2.SubCategories;
                        this.subCategoryList = Number(this.categoryID);
                      }
                    }
                })
                if(subData){
                this.optionSubCategory = subData;
                }
              }
            }
          }, 3000);
        }else{
          const categoryList = JSON.parse(sessionStorage.getItem('categoryList'));
          if (this.CID.indexOf('/search/') !== -1) {
            let CID = this.CID.split('/');
            this.hideTags = true;
            this.categoryID = decodeURIComponent(CID[2]);
          }else {
            this.categoryID = sessionStorage.getItem('selectedCatId');
            if (this.CID.indexOf('/search?ProductName') !== -1) {
              sessionStorage.removeItem('selectedCatId');
              this.optionSubCategory = null;
              this.categoryID = null;
            }
          }
          if(this.categoryID){
              const subCategoryData = categoryList.filter(data1 => (Number(data1.CategoryID) === Number(this.categoryID)));
              if (subCategoryData[0] !== undefined) {
                this.categoryName = subCategoryData[0]['Name'];
                if(subCategoryData[0].SubCategories[0]!== undefined){
                  this.optionSubCategory = subCategoryData[0].SubCategories;
                  this.subCategoryList = subCategoryData[0].SubCategories[0].CategoryID;
                }else{
                  this.optionSubCategory = null;
                }
              }else{
                let subData =  '';
                categoryList.forEach((data2) => {
                let len = data2.SubCategories.length;
                for(let i = 0; i < len; i++){
                    if(data2.SubCategories[i].CategoryID === Number(this.categoryID)){
                      subData =  data2.SubCategories;
                      this.subCategoryList = Number(this.categoryID);
                    }
                  }
                 })
                this.optionSubCategory = subData;
              }
              
              if(this.subCategoryList){
                this.optionSubCategory.unshift({
                  'Name': 'Select Sub-Category',
                  'CategoryID': Number(sessionStorage.getItem('selectedCatId')),
                  'ParentID': Number(sessionStorage.getItem('selectedCatId'))
                });
                if (this.CID.indexOf('/search/') !== -1) {
                  let CID = this.CID.split('/');
                  let num = Number(decodeURIComponent(CID[2]))
                  if(num !== this.subCategoryList){
                    this.subCategoryList = num;
                  }
                }
              }
          }
        }
 

        this.productFiveColumnStatus = true;
        if ((this.CID.indexOf('/search') !== -1 || this.CID.indexOf('/search?CategoryID') !== -1) &&
          this.CID.indexOf('/search?joinlinkorders') === -1) {
          this.productFiveColumnStatus = false;
        }
        this.productBlocksID = this.route.snapshot.params ? this.route.snapshot.params.productBlocksID : null;
        this.folderID = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.FolderID : null;
        this.likedProducts = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.likedProducts : null;
        this.isJoinLinkOrders = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.joinlinkorders : null;
        this.isFilterBatchProducts = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.FilterBatchProducts : null;
        if (this.isJoinLinkOrders === 'true') {
          this.categoryName = 'JOIN_ORDERS';
          this.getJoinOrderProducts();
          this.isShowFilter = false;
          this.productTitle = this.translate.get('HEADING.JOIN_ORDERS').subscribe((_res: string) => {
            if(this.isParentCustomer){
				this.titleService.setTitle('Parent Shop');
			}else{
				this.titleService.setTitle('Child Shop');
			}
          });
        } else if ((this.categoryID) && (this.categoryName)) {
          this.productTitle = this.translate.get('HEADING.SEARCH_RESULTS').subscribe((_res: string) => {
			if(this.isParentCustomer){
				this.titleService.setTitle('Parent Shop');
			}else{
				this.titleService.setTitle('Child Shop');
			}
          });
          this.checkWebshopSettings();
          this.getProducts(page, '');
          // this.categoryName = '';
          this.categoryNameNoTranslate = this.route.snapshot.params ? this.route.snapshot.params.categoryName : null;
        } 
        // else if ((this.BrandId) && (this.BrandName)) {
        //   this.productTitle = this.translate.get('HEADING.SEARCH_RESULTS').subscribe((res: string) => {
        //     this.titleService.setTitle(res);
        //   });
        //   this.checkWebshopSettings();
        //   this.getProducts(page, '');
        //   // this.categoryName = '';
        //   this.categoryNameNoTranslate = this.route.snapshot.params ? this.route.snapshot.params.BrandName : null;
        // }
         else if (this.folderID) {
          this.isHideBillBoard = true;
          this.hideDelete = false;
          this.categoryName = 'HEADING.FOLDERS';
          this.getFolderProducts(page);
          this.isShowFilter = false;
          this.productTitle = this.translate.get('PRODUCT_DETAIL.PRODUCTS_FOLDER').subscribe((_res: string) => {
        //     if(this.isParentCustomer){
				// this.titleService.setTitle('Parent Shop');
        //   }else{
        //     this.titleService.setTitle('Child Shop');
        //   }
          });
        } else if (this.likedProducts === 'true') {
          this.isHideBillBoard = true;
          this.hideDelete = false;
          this.categoryName = 'HEADING.LIKED_PRODUCTS';
          this.isShowFilter = false;
          this.getLikedProducts();
          this.productTitle = this.translate.get('HEADING.LIKED_PRODUCTS').subscribe((_res: string) => {
            if(this.isParentCustomer){
				this.titleService.setTitle('Parent Shop');
			}else{
				this.titleService.setTitle('Child Shop');
			}
          });
        } else if (this.productBlocksID != null) {
          this.categoryNameNoTranslate = this.route.snapshot.params ? this.route.snapshot.params.ProductBlockName : null;
          this.getProductBlocksOrders();
          this.isShowFilter = false;
        } else {
          this.productTitle = this.translate.get('HEADING.SEARCH_RESULTS').subscribe((_res: string) => {
           if(this.isParentCustomer){
				this.titleService.setTitle('Parent Shop');
			}else{
				this.titleService.setTitle('Child Shop');
			}
          });
          this.productParams = this.route.queryParams;
          this.categoryName = 'Select Sub-Category';
          this.checkWebshopSettings();
          this.getProducts(page, this.route.queryParams);
        }
      } catch (error) {
        //this.toastr.error(error);
      }
    });
    // this.tempCampaign = this.campaignService
    //   .getActiveCampaign()
    //   .subscribe((res) => {
    //     this.selectedCampaign = res;
    //     if (res) {
    //       this.commonService.setPoints(res.BalancePoints);
    //       this.campaignid = res.CampaignId;
    //       this.getProductBlocks(false, res.CampaignId);
    //     }
    //   });
    //   this.tempCampaign.unsubscribe();
  }

  ngOnChanges(_changes: SimpleChanges): void {
   /* if (this.CID.indexOf('/search') === -1) {
      this.optionSubCategory = null;
    }*/
    if(this.productsList){
      this.products = [];
      this.products = this.productsList["ItemsCollection"];
      /*this.perPageCount = this.productsList['PageSize'];
      this.totalCount = this.productsList['TotalItemsCount'];
      this.currentPage = this.productsList['PageIndex'];*/
      this.campaignList = true;
    }
    const isSaved = sessionStorage.getItem('isSaveTagsFilterSaved');
    if (isSaved == 'true') {
      const page = {
        pageIndex: 1,
        pageSize: 20
      }
      this.getProducts(page, '');
    }
    
  }

 ngOnInit() { 

  	}
 
  checkWebshopSettings() {
    // tslint:disable-next-line:max-line-length
    if (this.webShopSettings.ShowAdvancedSearchBranding || this.webShopSettings.ShowAdvancedSearchPrices || this.webShopSettings.ShowSupplierRating || this.webShopSettings.ShowAdvancedSearchColors || this.webShopSettings.ShowSortByFilter) {
      this.isShowFilter = true;
    } else {
      this.isShowFilter = false;
    }
  }

  getJoinOrderProducts(pageSize?) {
    let pagesize = pageSize == undefined ? this.pageSize : pageSize;
    try {
      this.categoryName = 'HEADING.JOIN_ORDERS';
      this.spinnerShow = true;
      this._httpOrders.getJoinOrdersList(this.pageIndex, pagesize, true).subscribe(
        (data: any[]) => {
          this.products = data['ItemsCollection'];
          window.scrollTo(0, 0);
          this.spinnerShow = false;
          this.perPageCount = data['PageSize'];
          this.totalCount = data['TotalItemsCount'];
          this.currentPage = data['PageIndex'];
        });
    } catch (error) {
      this.toastr.error(error);
    }
  }

  getProductBlocksOrders() {
    if(this.isParentCustomer){
      try {
        this.spinnerShow = true;
        this._httpOrders.getProductBlocksOrders(this.pageIndex, this.pageSize, this.productBlocksID).subscribe(
          (data: any[]) => {
            this.categoryName = data['Name'];
            this.perPageCount = data['PageSize'];
            this.totalCount = data['TotalItemsCount'];
            this.currentPage = data['PageIndex'];
            let productBlockNamestr = this.categoryName;
            productBlockNamestr = productBlockNamestr.toLowerCase();
            productBlockNamestr = productBlockNamestr.replace(/\b\w/g, l => l.toUpperCase());
            sessionStorage.setItem('ProductBlockName', productBlockNamestr);
            const productBlockFromSessionStorage = JSON.parse(sessionStorage.getItem('breadCrumbsList'));
            // Change value
            productBlockFromSessionStorage[1].group = productBlockNamestr;
            // Save the new item with updated value
            sessionStorage.setItem('breadCrumbsList', JSON.stringify(productBlockFromSessionStorage));
            this.products = data['Products'];
            window.scrollTo(0, 0);
            this.spinnerShow = false;
          });
      } catch (error) {
        this.toastr.error(error);
      }
    }else{
      try {
        this.spinnerShow = true;
        this._httpProductService.productBlocks(this.customerID, true, this.campaignid).subscribe(
          (data: any[]) => {
            this.categoryName = data[0]['Name'];
            this.perPageCount = data[0]['PageSize'];
            this.totalCount = data[0]['TotalItemsCount'];
            this.currentPage = data[0]['PageIndex'];
            let productBlockNamestr = this.categoryName;
            productBlockNamestr = productBlockNamestr.toLowerCase();
            productBlockNamestr = productBlockNamestr.replace(/\b\w/g, l => l.toUpperCase());
            sessionStorage.setItem('ProductBlockName', productBlockNamestr);
            const productBlockFromSessionStorage = JSON.parse(sessionStorage.getItem('breadCrumbsList'));
            // Change value
            productBlockFromSessionStorage[1].group = productBlockNamestr;
            // Save the new item with updated value
            sessionStorage.setItem('breadCrumbsList', JSON.stringify(productBlockFromSessionStorage));
            this.products = data[0]['Products'];
            window.scrollTo(0, 0);
            this.spinnerShow = false;
          });
      } catch (error) {
        this.toastr.error(error);
      }
    }
    
  }

  paginate(page, params) {
    try {
      this.spinnerShow = true;
      page.pageIndex = page.pageIndex ? page.pageIndex : 1;
      page.pageSize = page.pageSize ? page.pageSize : 20;
      if (this.isJoinLinkOrders === 'true') {
        this.pageIndex = page.pageIndex;
        this.categoryName = 'HEADING.JOIN_ORDERS';
        this.getJoinOrderProducts(page.pageSize);
      } else if (this.productBlocksID != null) {
        this.pageIndex = page.pageIndex;
        this.pageSize = page.pageSize;
        this.categoryName = this.route.snapshot.params ? this.route.snapshot.params.ProductBlockName : null;
        this.getProductBlocksOrders();
      } else if ((this.categoryID) && (this.categoryName)) {
        this.categoryName = '';
        this.categoryNameNoTranslate = this.route.snapshot.params ? this.route.snapshot.params.categoryName : null;
        this.getProducts(page, params);
      } else if ((this.BrandId) && (this.BrandName)) {
        this.BrandName = '';
        this.categoryNameNoTranslate = this.route.snapshot.params ? this.route.snapshot.params.BrandName : null;
        this.getProducts(page, params);
      } else if (this.folderID) {
        this.pageIndex = {
          'pageIndex': page.pageIndex,
          'pageSize': page.pageSize
        }
        this.categoryName = 'HEADING.FOLDERS';
        this.isHideBillBoard = true;
        this.hideDelete = false;
        this.getFolderProducts(page);
      } else if (this.likedProducts === 'true') {
        this.categoryName = 'HEADING.LIKED_PRODUCTS';
        this.isHideBillBoard = true;
        this.hideDelete = false;
        this.pageIndex = {
          'pageIndex': page.pageIndex,
          'pageSize': page.pageSize
        }
        this.getLikedProducts();
      } else {
        this.productParams = this.route.queryParams;
        this.categoryName = 'HEADING.SEARCH_RESULTS';
        this.getProducts(page, params);
      }
    } catch (error) {
      // this.toastr.error(error);
    }
  }

  getProducts(page, params) {
    try {
      this.tags = [];
      this.dynamiTags = [];
      this.productTagDetails = JSON.parse(sessionStorage.getItem('productTags'));
      // this.categoryName = '';
      if (params) {
        if (params._value.productName) {
          this.categoryName = 'HEADING.SEARCH_RESULTS';
          this.categoryNameNoTranslate = '';
          this.isAllowTag = true;
        }
      }
      page.pageIndex = page.pageIndex ? page.pageIndex : 1;
      page.pageSize = page.pageSize ? page.pageSize : 20;
      if(this.BrandId){
        this.brandIds = this.BrandId
      } else if(params && params._value.BrandId){
        this.brandIds = params._value.BrandId
      }else{
        this.brandIds = null
      }

      this.sendData = {
        'BrandId': this.brandIds,
        'CategoryID':  this.categoryID ? Number(this.categoryID) : (params._value.CategoryID ? Number(params._value.CategoryID) : null),
        'ShowPricesIncludingLogoType': params ? params._value.ShowPricesIncludingLogoType : null,
        'ProductName': params ? params._value.ProductName : null,
        'Quantity': params ? params._value.Quantity : null,
        'PriceFrom': params ? params._value.PriceFrom : null,
        'PriceTo': params ? params._value.PriceTo : null,
        'ColorID': params ? params._value.ColorID : null,
        'DeliveryDate': params ? ((params._value.DeliveryDate) ? encodeURI(params._value.DeliveryDate) : null) : null,
        'SortBy': params ? params._value.SortBy : null,
        'MinimumSupplierQualityLevel': params ? params._value.SupplierQualityLevel : null
      };
      sessionStorage.setItem('searchParamsbreadCrumbs', JSON.stringify(this.sendData));
      setTimeout(() => {
        if (this.sendData.ProductName) {
          this.translate.get('HEADING.PRODUCT_NAMES').subscribe(
            (res: string) => {
              const productTag = {
                'label': res,
                'value': this.sendData.ProductName,
                'check': 'ProductName'
              }
              this.productTag = this.sendData.ProductName;
              this.tags.push(productTag);
            });
          // this.quantityTag = this.translate.get('SETTINGS.QUANTITY');
        }

        const categoryList = JSON.parse(sessionStorage.getItem('categoryList'));
        const setSubCategoryID = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.SubCategory : null;
        if (categoryList !== null && !this.BrandId) {
          const setCategoryID = this.route.snapshot.queryParams.CategoryID ? this.route.snapshot.queryParams.CategoryID : this.sendData.CategoryID;
          const results = categoryList.filter( data => (Number(data.CategoryID) === Number(setCategoryID)));
          if (results.length !== 0) {
            this.translate.get('HEADING.CATEGORY').subscribe(
              (res: string) => {
                const categoryTag = {
                  'label': res,
                  'value': results[0].Name,
                  'check': 'CategoryID'
                }
                this.categoryTag = setCategoryID;
                this.tags.push(categoryTag);
                if (this.categoryName === 'HEADING.SEARCH_RESULTS' || this.isAllowTag) {
                  this.tags.push(categoryTag);
                }
                this.categoryName = results[0].Name;
              });
            this.categoryTag = this.translate.get('HEADING.CATEGORY');
          } else {
            categoryList.forEach(element => {
              element.SubCategories.forEach(data => {
                if (Number(data.CategoryID) === Number(this.sendData.CategoryID)) {
                  this.translate.get('HEADING.SUB_CATEGORY').subscribe(
                    (res: string) => {
                      const categoryTag = {
                        'label': res,
                        'value': data.Name,
                        'check': 'SubCategory'
                      }
                      this.subCategoryTag = this.sendData.CategoryID;
                      this.tags.push(categoryTag);
                      this.isPassSubcategory = true;
                    });
                  this.categoryName = data.Name;
                }
              });
            });
          }
          if (setSubCategoryID !== null && !this.isPassSubcategory) {
            categoryList.forEach(element => {
              element.SubCategories.forEach(data => {
                if (Number(data.CategoryID) === Number(this.sendData.CategoryID)) {
                  this.translate.get('HEADING.SUB_CATEGORY').subscribe(
                    (res: string) => {
                      const categoryTag = {
                        'label': res,
                        'value': data.Name,
                        'check': 'SubCategory'
                      }
                      this.subCategoryTag = this.sendData.CategoryID;
                      this.tags.push(categoryTag);
                    });
                  this.categoryName = data.Name;
                }
              });
            });
          }
        }

        if (this.sendData.ShowPricesIncludingLogoType) {
          switch (this.sendData.ShowPricesIncludingLogoType) {
            case '1':
              // this.brandingTitle = (this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_1COLOR'));
              this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_1COLOR').subscribe((res: string) => {
                this.brandingTitle = res;
              });
              break;
            case '2':
              // this.brandingTitle = (this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_2COLOR'));
              this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_2COLOR').subscribe((res: string) => {
                this.brandingTitle = res;
              });
              break;
            case '3':
              // this.brandingTitle = (this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_3COLOR'));
              this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_3COLOR').subscribe((res: string) => {
                this.brandingTitle = res;
              });
              break;
            case '4':
              //  this.brandingTitle = (this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_4COLOR'));
              this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_4COLOR').subscribe((res: string) => {
                this.brandingTitle = res;
              });
              break;
            case '5':
              // this.brandingTitle = (this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_EMBROIDERY'));
              this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_EMBROIDERY').subscribe((res: string) => {
                this.brandingTitle = res;
              });
              break;
            case '6':
              // this.brandingTitle = (this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_ENGRAVING'));
              this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_ENGRAVING').subscribe((res: string) => {
                this.brandingTitle = res;
              });
              break;
            case '7':
              // this.brandingTitle = (this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_EMBOSSING'));
              this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_EMBOSSING').subscribe((res: string) => {
                this.brandingTitle = res;
              });
              break;
            case '8':
              // this.brandingTitle = (this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_DEBOSSING'));
              this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_DEBOSSING').subscribe((res: string) => {
                this.brandingTitle = res;
              });
              break;
            case '9':
              // this.brandingTitle = (this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_PHOTOPRINT'));
              this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_PHOTOPRINT').subscribe((res: string) => {
                this.brandingTitle = res;
              });
              break;
            default:
              this.brandingTitle = '';
              break;
          }
          this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_TITLE2').subscribe(
            (res: string) => {
              const brandingTag = {
                'label': res,
                'value': this.brandingTitle,
                'check': 'ShowPricesIncludingLogoType'
              }
              this.brandingTag = this.sendData.ShowPricesIncludingLogoType;
              this.tags.push(brandingTag);
            });
        }

        if (this.sendData.Quantity) {
          this.translate.get('SETTINGS.QUANTITY').subscribe(
            (res: string) => {
              const quantityTag = {
                'label': res,
                'value': this.sendData.Quantity,
                'check': 'Quantity'
              }
              this.quantityTag = this.sendData.Quantity;
              this.tags.push(quantityTag);
            });
          // this.quantityTag = this.translate.get('SETTINGS.QUANTITY');
        }
        if (this.sendData.PriceFrom) {
          this.translate.get('HEADER_ONE.PRICE_FROM').subscribe(
            (res: string) => {
              const priceFromTag = {
                'label': res,
                'value': this.sendData.PriceFrom,
                'check': 'PriceFrom'
              }
              this.priceFromTag = this.sendData.PriceFrom;
              this.tags.push(priceFromTag);
            });
          // this.priceFromTag = this.translate.get('HEADER_ONE.PRICE_FROM');
        }
        if (this.sendData.PriceTo) {
          this.translate.get('HEADER_ONE.PRICE_TO').subscribe(
            (res: string) => {
              const priceToTag = {
                'label': res,
                'value': this.sendData.PriceTo,
                'check': 'PriceTo'
              }
              this.priceToTag = this.sendData.PriceTo;
              this.tags.push(priceToTag);
            });
          // this.priceToTag = this.translate.get('HEADER_ONE.PRICE_TO');
        }
        if (this.sendData.DeliveryDate) {
          this.translate.get('MESSAGECENTER.DELIVERYDATE').subscribe(
            (res: string) => {
              const deliveryDateTag = {
                'label': res,
                'value': this.datePipe.transform(this.sendData.DeliveryDate, 'dd/MM/yyyy'),
                'check': 'DeliveryDate'
              }
              this.deliveryDateTag = this.sendData.DeliveryDate;
              this.tags.push(deliveryDateTag);
            });
          // this.deliveryDateTag = this.translate.get('MESSAGECENTER.DELIVERYDATE');
        }
        // if(!this.sendData.CategoryID){
        //   if (this.sendData.BrandId) {
        //       this.productBrandlist = this.sendData.BrandId
        //       this.tags.push(this.productBrandlist);
        //   }
        // }
        const colorsList = JSON.parse(sessionStorage.getItem('colorsList'));
        if (colorsList !== null) {
          const result = colorsList.filter(
            data => (Number(data.ProductColorID) === Number(this.sendData.ColorID)));
          if (result.length !== 0) {
            this.translate.get('HEADING.COLOR').subscribe(
              (res: string) => {
                const colorTag = {
                  'label': res,
                  'value': result[0].Name,
                  'code': result[0].ColorCode,
                  'check': 'ColorID'
                }
                this.colorTag = this.sendData.ColorID
                this.tags.push(colorTag);
              });
          }
        }
        if (this.productTagDetails !== undefined && this.productTagDetails !== null && this.productTagDetails.length > 0) {
          this.productTagDetails.forEach((item) => {
            if (item.value != null && item.value.length > 0) {
              item.label.forEach((list) => {
                const RoleAndTag = {
                  'label': item.productTagsGroupName,
                  'value': list,
                  'productTagsGroupID': item.productTagsGroupID
                }
                this.dynamiTags.push(RoleAndTag);
              })
            }
          })
        }
      }, 2000);
      this.sendData.FilterBatchProducts = true;
      if (this.subCategory !== undefined && this.subCategory !== null && this.subCategory !== '') {
        this.sendData.CategoryID = this.subCategory;
      }
      // let prePages = JSON.parse(sessionStorage.getItem('prePages'));
      // prePages = prePages[(Number(prePages.length) - 1)]['group'];
      this.sessionPageIndex = JSON.parse(sessionStorage.getItem('pageIndex'));
      if (this.sessionPageIndex !== undefined) {
        if (this.sessionPageIndex !== null) {
          page.pageIndex = this.sessionPageIndex;
        }
      }
      this.sessionPageSize = JSON.parse(sessionStorage.getItem('pageSize'));
      if (this.sessionPageSize !== undefined) {
        if (this.sessionPageSize !== null) {
          page.pageSize = this.sessionPageSize;
        }
      }
      let productTagIds = [];
      if (this.productTagDetails !== undefined && this.productTagDetails !== null && this.productTagDetails.length > 0) {
        this.productTagDetails.forEach((item) => {
          if (item.value != null && item.value.length > 0) {
            item.value.forEach(data => {
              productTagIds.push(parseInt(data));
            })
          }
        });
      }
      this.sendData['ProductTagIDs'] = productTagIds;
      if(this.isParentCustomer){
        if(this.sendData.BrandId){
          this.sendData.CategoryID = null;
        }
      this._httpProductService.getProducts(page.pageIndex, page.pageSize, this.sendData).subscribe(
        (data: any[]) => {
          setTimeout(() => {
            this.spinnerShow = false;
          }, 100);
          
            if (Number(data['NumberOfUnreadEmails']) === 1) {
              this.oldestProductFirstStatus = true;
            }
            this.products = data['ItemsCollection'];
            window.scrollTo(0, 0);
            this.perPageCount = data['PageSize'];
            this.totalCount = data['TotalItemsCount'];
            this.currentPage = data['PageIndex'];
          
        },
        error => {
          this.products = [];
          this.spinnerShow = false;
          // this.errorMessage = JSON.parse(error._body).Message;
          this.toastr.error(error.error.Message);
        });
      }
      if(!this.isParentCustomer){
        let a = JSON.parse(sessionStorage.getItem('campaign'));
        this.sendData.CampaignId = a['CampaignId'];
        this.spinnerShow = true;
        this._httpProductService.getCampaignProducts(page.pageIndex, page.pageSize, this.sendData).subscribe(
          (data) => {
            sessionStorage.setItem('campaignProducts', JSON.stringify(data))
            setTimeout(() => {
              this.spinnerShow = false;
            }, 100);
            this.products = [];
            this.products = data['ItemsCollection'];
            /*if(this.products.length > 0){
               this.products = this.products.filter(products => products.MaxPrice <= this.sendData.PriceTo);
            }*/
            
            window.scrollTo(0, 0);
            this.perPageCount = data['PageSize'];
            this.totalCount = data['TotalItemsCount'];
            this.currentPage = data['PageIndex'];
        }, error => {
          this.products = [];
          this.spinnerShow = false;
          // this.errorMessage = JSON.parse(error._body).Message;
          this.toastr.error(error.error.Message);
        });
      }
    } catch (error) {
      // this.toastr.error(error);
    }
  }

  

  getFolderProducts(page) {
    try {
      const sendData = {
        'FolderID': this.folderID,
      };
      page.pageIndex = page.pageIndex ? page.pageIndex : 1;
      page.pageSize = page.pageSize ? page.pageSize : 20;
      this.sessionPageIndex = JSON.parse(sessionStorage.getItem('pageIndex'));
      if (this.sessionPageIndex !== undefined) {
        if (this.sessionPageIndex !== null) {
          page.pageIndex = this.sessionPageIndex;
        }
      }
      this.sessionPageSize = JSON.parse(sessionStorage.getItem('pageSize'));
      if (this.sessionPageSize !== undefined) {
        if (this.sessionPageSize !== null) {
          page.pageSize = this.sessionPageSize;
        }
      }
      this._httpProductService.getFolderProducts(page, sendData).subscribe(
        (data: any[]) => {
          this.categoryName = '';
          this.categoryNameNoTranslate = data['FolderTitle'];
          this.categoryName = data['FolderTitle'];
          this.products = data['ItemsCollection'];
          window.scrollTo(0, 0);
          this.spinnerShow = false;
          this.perPageCount = data['PageSize'];
          this.totalCount = data['TotalItemsCount'];
          this.currentPage = data['PageIndex'];
        });
    } catch (error) {
      // this.toastr.error(error);
    }
  }

  getLikedProducts() {
    try {
      this.sessionPageIndex = JSON.parse(sessionStorage.getItem('pageIndex'));
      if (this.sessionPageIndex !== undefined) {
        if (this.sessionPageIndex !== null) {
          this.pageIndex.pageIndex = this.sessionPageIndex;
        }
      }
      this.sessionPageSize = JSON.parse(sessionStorage.getItem('pageSize'));
      if (this.sessionPageSize !== undefined) {
        if (this.sessionPageSize !== null) {
          this.pageIndex.pageSize = this.sessionPageSize;
        }
      }
      this._httpProductService.getLikedProducts(this.pageIndex).subscribe(
        (data: any[]) => {
          this.products = data['ItemsCollection'];
          window.scrollTo(0, 0);
          this.spinnerShow = false;
          this.perPageCount = data['PageSize'];
          this.totalCount = data['TotalItemsCount'];
          this.currentPage = data['PageIndex'];
        }, error => {
          this.toastr.error(error);
        })
    } catch (error) {
      this.toastr.error(error);
    }
  }

  removeTag(params, index) {
    this.tags.splice(index, 1);
    const postParams = {
      'ProductName': this.productTag,
      'CategoryID': this.categoryTag,
      'ShowPricesIncludingLogoType': this.brandingTag,
      'Quantity': this.quantityTag,
      'PriceFrom': this.priceFromTag,
      'PriceTo': this.priceToTag,
      'ColorID': this.colorTag,
      'DeliveryDate': this.deliveryDateTag,
      'SubCategory': this.subCategoryTag,
      'isTagsFilterExists': this.isTagsFilterExists
    };
    const check = params.check;
    postParams[check] = null;
    if (params.check === 'Quantity') {
      postParams['PriceFrom'] = null;
      postParams['PriceTo'] = null;
    }
    if(this.tags.length > 0){
      this.router.navigate(['/search'], { queryParams: postParams, replaceUrl: false });
    }else{
      this.clearAll()
    }
  }

  removeDynamicTag(params, index) {
    if (this.productTagDetails.length > 0 && this.productTagDetails !== undefined && this.productTagDetails !== null) {
      this.productTagDetails.forEach((item) => {
        if (item.productTagsGroupID === params.productTagsGroupID) {
          for (let i = 0; i < item.value.length; i++) {
            if (item.label[i] == params.value) {
              item.value.splice(i, 1);
              item.label.splice(i, 1);
            }
          }
        }
        if (item.value.length > 0) {
          this.isTagsFilterExists = true;
        }

      });
    }
    sessionStorage.setItem('productTags', JSON.stringify(this.productTagDetails));
    this.dynamiTags.splice(index, 1);

    const postParams = {
      'ProductName': this.productTag,
      'CategoryID': this.categoryTag,
      'ShowPricesIncludingLogoType': this.brandingTag,
      'Quantity': this.quantityTag,
      'PriceFrom': this.priceFromTag,
      'PriceTo': this.priceToTag,
      'ColorID': this.colorTag,
      'DeliveryDate': this.deliveryDateTag,
      'SubCategory': this.subCategoryTag,
      'isTagsFilterExists': this.isTagsFilterExists,
      'removeTagStatus': true
    };

    if(this.dynamiTags.length > 0){
      this.router.navigate(['/search'], { queryParams: postParams, replaceUrl: false });
    }else{
      this.clearAll()
    }

  }

  searchProduct(item){
    this.commonService.setClearAll(true);
    if(item.CategoryID){
    sessionStorage.setItem('selectedCatId', item.ParentID);
    if(item.Name === 'Select Sub-Category'){
      item.Name = sessionStorage.getItem('selectedCatName')
    }
    const str = item.Name;
    item.Name = str.replace(/ +/g, '');
    sessionStorage.setItem('pageSize', JSON.stringify(20));
    sessionStorage.setItem('pageIndex', JSON.stringify(1));
    this.router.navigate(['/search', item.CategoryID, item.Name], { replaceUrl: false });
    }else{
      this.router.navigate(['/']);
    }
  }

  clearAll(){
    let perpage = JSON.parse(sessionStorage.getItem('prePages'));
    this.commonService.setClearAll(true);
    if(perpage){
      let e = 2
      if(perpage[perpage.length - 1].url === perpage[perpage.length - 2].url){
        e = 3
      }
      let url  = perpage[perpage.length - e];
      let url_a = url.url.split('?');
      if(url_a[0].indexOf('/search') === -1 || url_a.indexOf('/search/') === -1){
        this.router.navigate(['/']);
      }else{
        this.router.navigate([''+url_a[0]+'']);
      }
    }else{
      sessionStorage.removeItem('selectedCatId');
      this.router.navigate(['/']);
    }
  }
}
