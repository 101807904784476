import { Component, OnInit, ViewChild } from '@angular/core';
import { LoginComponent } from '../../linknet-common/login/login.component';
import { AppComponent } from '../../app.component';
import { GlobalService } from 'app/linknet-common/globals/global.service';
import { WebshopService, CampaignService } from "../../linknet-common/restful-services/restful.service";
import { ToastService } from 'app/typescripts/pro/alerts';
import { MessageService } from "app/linknet-common/restful-services/message-services/message.service";
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from "rxjs";
import { CommonServicesService } from 'app/linknet-common/common-services/common-services.service';

@Component({
  selector: 'shop-app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss'],
})

export class HeaderOneComponent implements OnInit {
  public loggedInData:any = {};
  public customerImage: any;
  public CID;
  public isParentCustomer: boolean = false;
  public isVoucherKart: boolean = false;
  public cartCount:any;
  subscription: Subscription;
  subscription2: Subscription;
  campaignName: any;
  @ViewChild("productCartModal") public productCartModal;
  orderSuccess: boolean;
  isMobile: boolean;
  public numberOfUnreadEmails:any;
  reset: boolean;
  balancePoints: any = 0;
  isSmartworks: boolean = false;
  isHrm: boolean = false;

  constructor(public _httpWebshop: WebshopService, public campaignService: CampaignService, private loginComponent: LoginComponent, public appComponent: AppComponent,
    private globalService: GlobalService, private toastService: ToastService, public router: Router, private toastr: ToastrService, 
    public _httpMessageService: MessageService, private commonService: CommonServicesService) {

      if (/Android|webOS|iPhone|iPad|iPod|pocket|psp|kindle|avantgo|blazer|midori|Tablet|Palm|maemo|plucker|phone|BlackBerry|symbian|IEMobile|mobile|ZuneWP7|Windows Phone|Opera Mini/i.test(navigator.userAgent)) {
				this.isMobile = true;
			} else {
				this.isMobile = false;
			}
    try {
      this.customerImage = this.globalService.webShopSettings().ImageURL;
      if (appComponent.loggedInData != null && Object.keys(appComponent.loggedInData).length > 0) {
        this.loggedInData = appComponent.loggedInData;
      }
    } catch (error) {
      // this.toastr.error(error);
    }

    const webshopDetail = JSON.parse(sessionStorage.getItem("webshopDetail"));
      
      if(webshopDetail.DomainName === "247hrm"){
        this.isHrm = true;
      }
      if (webshopDetail.ParentCustomerDomainName === webshopDetail.DomainName ) {
        this.isParentCustomer = true;
        if(webshopDetail.DomainName === "smartworks"){
          this.isSmartworks = true;
        }
      } else {
        this.isParentCustomer = false;
        if(webshopDetail.DomainName === "voucherkart"){
          this.isVoucherKart = true;
        }else if(webshopDetail.DomainName === "sw"){
          this.isSmartworks = true;
        }
      }

      this.campaignService.getActiveCampaign().subscribe((data)=>{
        if(data){
          this.campaignName = data['CampaignName'];  
        }
      });
      
  }

  ngOnInit() {
    this.subscription2 = this._httpMessageService.getState().subscribe((selectionFormatState) => {
      this.numberOfUnreadEmails = selectionFormatState;
    });
    this.subscription = this._httpWebshop.getCount().subscribe((c)=>{
          this.cartCount = c;
    })
    
    this.commonService.getPoints().subscribe((data)=>{
      this.balancePoints = data;
    })
    
 }


 messageCenter() {
  const loggedInData = this.globalService.getLoggedInData();
  if (loggedInData) {
    this.router.navigate(["/messages/list/inbox"], { replaceUrl: false });
  } 
}

  openLoginModal() {
    try {
      this.loginComponent.showModal();
    } catch (error) {
      // this.toastr.error(error);
    }
  }

  hideLoginModal() {
    try {
      this.loginComponent.hideModal();
    } catch (error) {
      // this.toastr.error(error);
    }
  }

  goHome() {
    sessionStorage.removeItem('selectedCatId');
    if(this.isVoucherKart){
      this.reset = true;
      setTimeout(()=>{
        this.reset = false;
      },50)
    }
    this.router.navigate(['/']);
   // this.CID = this.router.routerState.snapshot.url;
   /* if (this.CID === '/') {

    } else {
      //this.router.navigate(['/']);
      //window.sessionStorage.isLogoClicked = true;
      // window.scrollTo(0, 0)
    }*/
    // based on filter section regarding product tags.
    if (sessionStorage.getItem('productTags') !== null && sessionStorage.getItem('productTags') !== undefined && sessionStorage.getItem('productTags') !== '') {
      sessionStorage.removeItem('productTags');
      sessionStorage.removeItem('categoryList');
    }
    sessionStorage.removeItem('productTags');

    var mobileToggle = document.querySelectorAll('.navbar-collapse.collapse.show')[0] as HTMLElement;
    if(mobileToggle) {
      mobileToggle.classList.remove('show');
    } 

  }

  showProductCartModal() {
    this.productCartModal.showCartModal();
  }

  
}
