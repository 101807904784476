import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CategoryService, ProductService, CampaignService, VoucherService } from '../../../linknet-common/restful-services/restful.service';
import { GlobalService } from '../../../linknet-common/globals/global.service';
import { ToastService } from 'app/typescripts/pro/alerts';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { CompleterComponent, MDBBootstrapModulePro, MdbCompleterDirective } from 'app/typescripts/pro';


@Component({
  selector: 'shop-app-menu-left',
  templateUrl: './menu-left.component.html',
  styleUrls: ['./menu-left.component.scss'],
  exportAs: 'child'
})

export class MenuLeftComponent implements OnInit {
  @Input() ismobile: boolean;
  @ViewChild('autocomplete') autocomplete: MDBBootstrapModulePro;
  public model: any;
  public categories = [];
  public optionsSelect: Array<any>;
  public myDatePickerOptions: Array<any>;
  public productSearchList: Array<any>;
  public categorySelect = [];
  public advancedSearchParam: any;
  public colorsList: Array<any>;
  public selectedColorID: number;
  public loggedInData: any;
  public productSearch;
  public brandSearch;
  public type = '';
  getCategories: any;
  isVoucherKart: boolean = false;
  brandHide: boolean = false;
  brandList: any;
  brandData: any;
  searchLabel: any;
  brandCount;
  queryParams: any;
  splitUrl : any;
  isHrm:boolean = false;
  isSmartWorks: boolean = false;
  isMobile:boolean;
  constructor(private router: Router, private httpVoucher: VoucherService, private _httpCategories: CategoryService,
    public globalService: GlobalService, public _httpProductService: ProductService,
    private toastService: ToastService, private formBuilder: FormBuilder, private toastr: ToastrService, private campaignService: CampaignService) {
    this.optionsSelect = [];
    if (/Android|webOS|iPhone|iPad|iPod|pocket|psp|kindle|avantgo|blazer|midori|Tablet|Palm|maemo|plucker|phone|BlackBerry|symbian|IEMobile|mobile|ZuneWP7|Windows Phone|Opera Mini/i.test(navigator.userAgent)) {
      this.isMobile = true;
    }else{
     this.isMobile = false;
    }
    this.myDatePickerOptions = [];
    this.productSearchList = [];
    this.advancedSearchParam = this.formBuilder.group({
      'CategoryID': ['', [Validators.required]],
      'ShowPricesIncludingLogoType': ['', [Validators.required]],
      'Quantity': ['', [Validators.required]],
      'PriceFrom': ['', [Validators.required]],
      'PriceTo': ['', [Validators.required]],
      'DeliveryDate': ['', [Validators.required]],
    });
    this.campaignService.getActiveCampaign().subscribe(
      data => {
        if (data) {
          this.loadCategories();
        }
      }
    );
  }

  ngOnInit() {
    if(sessionStorage.getItem('loggedInData')){
      this.loggedInData = JSON.parse(sessionStorage.getItem('loggedInData'))
    }
    this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event:any) => {
       this.splitUrl = event.url.slice(0,20);
      if (event.url.indexOf('vouchers/brands') !== -1) {
       
      }else if(this.splitUrl == '/vouchers/brandname/'){
        this.brandSearch;
     }else{
       this.brandSearch = '';
       
     }
      
    });
    const webshopData = JSON.parse(sessionStorage.getItem('webshopDetail'));
    if (webshopData && webshopData.DomainName === "voucherkart") {
      this.isVoucherKart = true;
      // this.getBrandList();
    }
    if (webshopData && webshopData.DomainName === "247hrm"){
      this.isHrm = true;
    }
    if (webshopData && (webshopData.DomainName === "sw" || webshopData.DomainName === "smartworks")){
      this.isSmartWorks = true;
    }
    const categoryList = JSON.parse(sessionStorage.getItem('categoryList'));
    if (categoryList === undefined || categoryList === null) {
      this.loadCategories();
    } else {
      const cataList = JSON.parse(sessionStorage.getItem('categoryList'));
      this.categories = cataList;
    }
    this.brandSearch = '';
  }
  ngAfterViewInit(){
    if(this.isVoucherKart){
      this.getBrandList();
      let placeHolder = document.getElementsByClassName("completer-input") as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < placeHolder.length; i++) {
        placeHolder[0].setAttribute("placeholder", "Search For your Favourite Brand");
      }
    }
  }

  getBrandList() {
    this.brandList = [];
    this.httpVoucher.getVoucherBrandsSearch().subscribe((data: any) => {
      this.brandData = data;
      this.brandData.forEach(element => {
        this.brandList.push(element.brand_name)
      });
      this.searchLabel = 'Search for your Favourite Brand';
      // this.brandCount = this.brandData.length;
    })
  }

  loadCategories() {
    try {
      if(!this.isVoucherKart){
      this._httpCategories.getCategories().subscribe(
        (data: any[]) => {
          this.categorySelect = [];
          this.categories = data;
          this.categories.forEach(element => {
            const countryVal = {
              'value': element.CategoryID,
              'label': element.Name,
            };
            this.categorySelect.push(countryVal);
          });
          sessionStorage.setItem('categoryList', JSON.stringify(this.categories));
          sessionStorage.setItem('categoryOptionList', JSON.stringify(this.categorySelect));
        },
        _error => {
          //this.toastr.error('Error: ' + error.Message);
        });
      }
      this.isLoggedIn();
    } catch (error) {
      // this.toastr.error(error);
    }
  }

  isLoggedIn() {
    this.globalService.loggedIn$.subscribe(
      data => {
        this.loggedInData = data;
      },
      error => {
        this.toastr.error('Error: ' + error.Message);
      });
  }

  getBrands(data) {
    if(data){
      let brand = this.brandData.filter(e => e.brand_name.toLowerCase() === data.toLowerCase());
      let type = 'brandname';
      sessionStorage.setItem('vouchersearch', 'yes')
      if(brand.length != 0){
      this.router.navigate(['/vouchers', type, brand[0].brand_name], { replaceUrl: false })
      }
      else{
        this.router.navigate(['/vouchers', type, data], { replaceUrl: false })
      }
    }
    
   /* if(this.loggedInData){
    }else{
      this.toastr.error("Please login to access the feature")
    }*/
  }
}
