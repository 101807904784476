import { Component, OnInit, ViewChild } from "@angular/core";
import { GlobalService } from "../../../linknet-common/globals/global.service";
import { CampaignService, ProductService, WebshopService } from "app/linknet-common/restful-services/restful.service";
import { CookieService } from "ngx-cookie-service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { environment } from "environments/environment";
import { CommonServicesService } from "app/linknet-common/common-services/common-services.service";
import { fromEvent, Observable, Subscription } from "rxjs";
import {NgbAccordion, NgbAccordionConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'shop-app-voucher-checkout',
    templateUrl: './checkout-voucher.component.html',
    styleUrls: ['./checkout-voucher.component.scss']
})

export class VoucherCheckoutComponent implements OnInit{
    @ViewChild("staticTabs") public staticTabs;
    @ViewChild('acc') accordionComponent: NgbAccordion;
    @ViewChild("userModal") public userModal;
    resizeObservable$: Observable<Event>
    resizeSubscription$: Subscription;
    cartParams: any;
    deliveryEmail: string = '';
    deliveryMobile: string = '';
    filter: boolean = false;
    loaderShow: Boolean = false;
    loggedInData: any;
    orderSummary: boolean = false;
    paymentStage: boolean = false;
    orderSuccess: boolean = false;
    activeId: string = 'panel-one';
    webShopSettings: any;
    initialDelivery: any;
    shoppingCartList: any;
    isParentCustomer: boolean = false;
    isChecked: boolean = false;
    totalQuantity: number;
    agreeTermsConditions: boolean = false;
    childShopPayment: boolean = false;
    pointsArray = [];
    CID: any;
    orderData: any = {};
    emailOTP: string;
    otpSuccess: boolean = false;
    pointsSelectError: boolean;
    cartId: any;
    confirmationTab: boolean;
    itemsList: any;
    isUnAuthorized: boolean;
    getShoppingCartIDList: any;
    redirect: boolean = true;
    pre: string = 'VkOrder' ;
  orderSuccessData: any;
  orderId: any;
  finaldiscount: any;
  finalval: boolean;
  showInvoiceButton: boolean;
  campaignList: any;
  printInvoiceUrl: any;
  balancePoints: any = 0;
  isMobile: boolean = false;
  atomID: string;
  bankRefNo: string;
  status: string;
  paymentStatus: any;
  encryptedData:string = "";
  encryptedKey:string = "";
  decryptedData:string = "";
  decryptedKey:string = "";
  paymentURLS:any;
    constructor(config: NgbAccordionConfig, public router: Router, public globalService: GlobalService, public _httpWebshopService: WebshopService,
      private campaignService: CampaignService, public cookieService: CookieService,public _httpProductService: ProductService, public commonService: CommonServicesService, private toastr: ToastrService){
      
        if (window.innerWidth > 768) {
          this.isMobile = false;
        }else{
          this.isMobile = true;
          config.closeOthers = true;
        }    
      const webshopData = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if (webshopData && webshopData.DomainName === "voucherkart") {
      
      }else{
        this.router.navigate(['/'])
      }
        this.loggedInData = this.globalService.getLoggedInData();
        this.webShopSettings = this.globalService.webShopSettings();
        this.deliveryEmail = this.loggedInData.DeliveryEmail;
        this.deliveryMobile = this.loggedInData.DeliveryPhoneNumber;
        let userBrowserID = "";
        const cookieExists: Boolean = this.cookieService.check("shoppingCardTokenId");
        if (!cookieExists) {
            let domainName = this._httpWebshopService.getSubdomain(window.location.hostname);
            domainName = domainName ? domainName : 'voucherkart';
            const randomString = this._httpWebshopService.randomGeneration(6);
            this.cookieService.set("shoppingCardTokenId", domainName + randomString);
            userBrowserID = domainName + randomString;
        } else {
            userBrowserID = this.cookieService.get("shoppingCardTokenId");
        }
        if (this.loggedInData !== null) {
            
            this.cartParams = {
              UserID: this.loggedInData.UserID,
              CustomerID: this.webShopSettings.CustomerID,
              CurrencyID: 20,
              UserBrowserID: userBrowserID,
              InitialDeliveryCountry: 'IN',
              DeliveryAddressType: this.initialDelivery,
              IsVoucher: true,
              ReturnOnlyVisibleItems: true
            };
          } else {
            this.cartParams = {
              UserID: null,
              CustomerID: this.webShopSettings.CustomerID,
              CurrencyID: 20,
              UserBrowserID: userBrowserID,
              InitialDeliveryCountry: this.webShopSettings.Country,
              DeliveryAddressType: this.initialDelivery,
              IsVoucher: true,
              ReturnOnlyVisibleItems: true
              // Custommessage: "Custom Message",   
            };
          }
    }

    ngOnInit() {
        this.getShoppingCartData();
        this.resizeObservable$ = fromEvent(window, 'resize')
        this.resizeSubscription$ = this.resizeObservable$.subscribe( _evt => {
            if (window.innerWidth > 768) {
              this.isMobile = false;
            }else{
              this.isMobile = true;
            }
        })
    
        this.CID = this.router.routerState.snapshot.url;
        if (this.CID.indexOf("/voucher-orderSuccess") !== -1) {
          try {
            this.campaignService.getCampaignForUser().subscribe((data: any) => {
              if (data && data.length !== 0) {
                this.campaignList = data;
                let a = JSON.parse(sessionStorage.getItem('campaign'));
                for (let d = 0; d < this.campaignList.length; d++) {
                  if (this.campaignList[d].CampaignId === a['CampaignId']) {
                    this.commonService.setPoints(this.campaignList[d].BalancePoints);
                    if(this.campaignList[d].BalancePoints){
                      this.balancePoints = this.campaignList[d].BalancePoints;
                    }
                    this.campaignService.setActiveCampaign(this.campaignList[d]);
                    sessionStorage.setItem('campaign', JSON.stringify(this.campaignList[d]));
                  }
                }
              }else{
                sessionStorage.removeItem('campaign');
                this.commonService.setPoints(0);
              }
            })
  
            this.CID = this.CID.split("/");
            this.orderId = this.CID[2];
            this._httpWebshopService.getOrderSuccess(this.orderId).subscribe((data1: any[]) => {
             
              
              this.orderSuccessData = data1;
  
              this.finaldiscount = data1["DealCodeAmount"];
              if(this.status === 'Y'){
                this.proceed('Y');
              }
              if(this.finaldiscount){
                this.finalval = true;
              }
              else{
                this.finalval = false;
              }
           
              if (this.orderSuccessData.OrderItems && this.orderSuccessData.OrderItems.length > 0) {
                for (let j = 0; j < this.orderSuccessData.OrderItems.length; j++) {
                  this.showInvoiceButton = true;
                  this.checkProduct(this.orderSuccessData.OrderItems[j].ProductID, j)
                }
              }
              if (this.orderSuccessData.Suborders && this.orderSuccessData.Suborders.length > 0) {
                for (let j = 0; j < this.orderSuccessData.Suborders.length; j++) {
                  if (this.orderSuccessData.Suborders[j].isTransportationCalculated === false) {
                    this.showInvoiceButton = true;
                  }
                }
              }
            }, (error) => {
              this.toastr.error("Error: " + error);
            });
          } catch (error) {
            this.toastr.error(error);
          }
          this.orderSuccess = true;
          this.confirmationTab = true;
          if(!this.isMobile){
            setTimeout(()=>{
              this.staticTabs.setActiveTab(4);
            },200)  
          }else{
            this.activePanel('panel-four');
          }
        }
        else if (this.CID.indexOf("/checkout-voucher") !== -1) {
          let fullurl = window.location.href;
          this.decryptedData = this.getParameterByName('EncData',fullurl);
          this.decryptedKey = this.getParameterByName('Enckey',fullurl);
          const params = {
            Data: this.decryptedData,
            Key: this.decryptedKey
          }
          this.loaderShow = true;
          this._httpWebshopService.decryptPaymentURL(params).subscribe({
            next: (data:any) => {
              this.loaderShow = false;
              if(data){
                this.loaderShow = false;
              }
              this.paymentURLS = data.Data;
              let url1;
              let atom;
              let url2;
          if(this.paymentURLS){
            //  url1 = this.getParameterByName('Status', this.paymentURLS)
            //  url2 = this.getParameterByName('Message', this.paymentURLS)
            //  atom = this.getParameterByName('AtomTrans', this.paymentURLS);
             let status = this.getParameterByName('Status', this.paymentURLS)
              let message = this.getParameterByName('Message', this.paymentURLS);
              let bankRefNo = this.getParameterByName('BankTrans', this.paymentURLS);
              let atomID = this.getParameterByName('AtomTrans', this.paymentURLS);
              this.status = status;
              if(atomID){
                this.atomID = atomID;
              }
              if(bankRefNo){
                this.bankRefNo = bankRefNo;
              }
                let orderData = JSON.parse(sessionStorage.getItem('order'));
                if (status === 'Y' && orderData) {
                  this.confirmationTab = true;
                  this.orderData = orderData;
                  if(!this.isMobile){
                    setTimeout(()=>{
                      this.staticTabs.setActiveTab(4);
                    },200)
                    
                  }else{
                    this.activePanel('panel-four');
                  }
                  
                  this.totalQuantity = orderData.totalQuantity;
                  this.cartId = sessionStorage.getItem('orderid');
                  this.orderSuccess = true;
                  this.loaderShow = true;
                  if(sessionStorage.getItem('pre') === "VkRedeem"){
                    this.childShopPayment = true;
                  }
                  this.createOrder();
                } else {
                  this.confirmationTab = false;
                  if(!this.isMobile){
                    setTimeout(()=>{
                      this.staticTabs.setActiveTab(1);
                    },200)
                    
                  }else{
                    this.activePanel('panel-one');
                  }
                }

                setTimeout(()=>{
                  if(this.status && this.status === 'N'){
                    if(!this.atomID || !this.bankRefNo){
                      this.proceed('PG')
                    }
                    else if(message && message === "CHALLAN GENERATED SUCCESSFULY"){
                      this.proceed('PNR')
                    }else{
                      this.proceed('PF')
                    }
                  }
                }, 2000)
          }
          
    
         
            },error : err => {
              this.loaderShow = false;
              console.log(err.error.Message);
            }
          })
        
       
        }
    }

    checkProduct(productID, j) {
      this._httpProductService.getProductDetail(productID, 'en', '', '').subscribe((data: any) => {
        let category = data.ProductCategories[0].Name.toLowerCase();
        if (category.includes('digital')) {
          this.orderSuccessData.OrderItems[j].delMessage = 'Your digital voucher code will be delivered to your email or mobile number within 24 to 48 hours depending on the processing time taken by the selected brand.';
        }
      })
    }

    proceed(status){
      this.paymentStatus = status;
      this.userModal.show();
    }

    redeem(){
      if(this.isChecked){
        
      }
    }

    nextStep(i){
      let panel = 'panel-one';
        if(i === 2){
          this.commonService.getPoints().subscribe((data)=>{
            this.balancePoints = data;
          })
          this.orderSummary = true;
          panel = 'panel-two';
          window.scrollTo({
            left: 0,
            top: 0,
            behavior: "smooth"
          })
        }
        if(i === 3){
          this.paymentStage = true;
          this.orderData.DeliveryPhoneNumber = this.deliveryMobile;
          this.orderData.DeliveryEmail = this.deliveryEmail;
          this.orderData.CustomerID = this.shoppingCartList.CustomerID;
          this.orderData.CurrencyID = this.shoppingCartList.CurrencyID;
          this.orderData.UserBrowserID = this.shoppingCartList.UserBrowserID;
          this.orderData.DeliveryAddress = this.loggedInData.DeliveryAddress ? this.loggedInData.DeliveryAddress : "Chennai";
          this.orderData.DeliveryCity = this.loggedInData.DeliveryCity ? this.loggedInData.DeliveryCity : "Chennai";
          this.orderData.DeliveryCountry = this.loggedInData.DeliveryCountry;
          this.orderData.totalQuantity = this.totalQuantity;
          this.orderData.UserID = this.loggedInData.UserID;
          this.getEmailOTP();
          panel = 'panel-three';
          window.scrollTo({
            left: 0,
            top: 0,
            behavior: "smooth"
          })
        }
        if(!this.isMobile){
          setTimeout(()=>{
            this.staticTabs.setActiveTab(i);
          },200)
          
        }else{
          this.activePanel(panel);
        }
    }

    getEmailOTP(){
      const data = "Email=" + this.loggedInData.Email + "&UserName=" + this.loggedInData.UserName + "&language_id=" + this.loggedInData.LanguageID + "&DomainName="+ this.webShopSettings.DomainName + "&CustomerTheme=" + this.loggedInData.CustomerTheme;
      this._httpWebshopService.triggerEmailOTP(data).subscribe();
      if(sessionStorage.getItem('campaign') && sessionStorage.getItem('campaign') !== 'undefined'){
      let a = JSON.parse(sessionStorage.getItem('campaign'))
      this.pointsArray.push(a['RewardId']);
      }
    }

    getShoppingCartData(){
        
        this._httpWebshopService.getShoppingCart(this.cartParams).subscribe((data: any) =>{
          
            this.shoppingCartList = data;  
            this.itemsList = data["Items"];
            if(data["Items"][0]){
            this.cartId = data["Items"][0].ShoppingCartID;
            }

           
         
            this.totalQuantity = 0;
            for (let i = 0; i < this.itemsList.length; i++) {
              this.totalQuantity += this.itemsList[i].OrderedQuantity;
            }
  
            /** new-code => To diplay Product Total Cost with Transportation, Handling Cost if exists */
            for (let j = 0; j < this.shoppingCartList["Items"].length; j++) {
              // tslint:disable-next-line:max-line-length
              this.shoppingCartList["Items"][j]["Price"]["totalCostIncludingTransportationHandlingCost"] = Number(this.shoppingCartList["Items"][j]["Price"]["TotalWithoutTransportationCost"]);
              // tslint:disable-next-line:max-line-length
  
              if (this.shoppingCartList["Items"][j]["Price"]["SuborderID"] !== undefined && this.shoppingCartList["Items"][j]["Price"]["SuborderID"] !== null) {
                if (this.shoppingCartList["Items"][j]["Price"]["SuborderTransportationBasicCost"] !== undefined && this.shoppingCartList["Items"][j]["Price"]["SuborderTransportationBasicCost"] !== null) {
                  if (Number(this.shoppingCartList["Items"][j]["Price"]["SuborderTransportationBasicCost"]) > 0) {
                    this.shoppingCartList["Items"][j]["Price"]["totalCostIncludingTransportationHandlingCost"] += Number(this.shoppingCartList["Items"][j]["Price"]["SuborderTransportationBasicCost"]);
                  }
                }
                if (this.shoppingCartList["Items"][j]["Price"]["SuborderTransportationHandlingCost"] !== undefined && this.shoppingCartList["Items"][j]["Price"]["SuborderTransportationHandlingCost"] !== null) {
                  if (Number(this.shoppingCartList["Items"][j]["Price"]["SuborderTransportationHandlingCost"]) > 0) {
                    this.shoppingCartList["Items"][j]["Price"]["totalCostIncludingTransportationHandlingCost"] += Number(this.shoppingCartList["Items"][j]["Price"]["SuborderTransportationHandlingCost"]);
                  }
                }
              } else {
                if (this.shoppingCartList["Items"][j]["Price"]["TransportationCost"] !== undefined && this.shoppingCartList["Items"][j]["Price"]["TransportationCost"] !== null) {
                  if (Number(this.shoppingCartList["Items"][j]["Price"]["TransportationCost"]) > 0) {
                    this.shoppingCartList["Items"][j]["Price"]["totalCostIncludingTransportationHandlingCost"] += Number(this.shoppingCartList["Items"][j]["Price"]["TransportationCost"]);
                  }
                }
              }
            }

          this.orderData["ShoppingCartItems"] = [];
          for (let j = 0; j < this.shoppingCartList.Items.length; j++) {
            if (this.shoppingCartList.Items[j].isChecked === true && this.shoppingCartList.Items[j].isVisible) {
              if (this.shoppingCartList.Items[j].Price.isTransportationCalculated === false) {
                this.isUnAuthorized = true;
              }
              this.orderData.ShoppingCartItems.push({
                shoppingCartItemID: this.shoppingCartList.Items[j].ShoppingCartItemID,
                shippingMethod: this.shoppingCartList.ShippingMethod,
              });
            }
          }
          },
          (error) => {
            this.toastr.error("Alert: " + error.error.Message);
            this.loaderShow = false;
          }
        );
    }

    inrFormat(val: number) {
        return Number(val).toLocaleString("en-IN");
    }

    validateOTP() {
      this.loaderShow = true;
      const data = "Email=" + this.loggedInData.Email + "&OTP=" + this.emailOTP;
      this._httpWebshopService.validateEmailOTP(data).subscribe(
        (data: any) => {
          if (data) {
            this.otpSuccess = true;
          }
          if(this.isChecked){
            let a = this.balancePoints - this.shoppingCartList.GrandTotal
            if(this.balancePoints > this.shoppingCartList.GrandTotal){
              this.commonService.setPoints(a)
            }else{
              this.commonService.setPoints(0)
            }
            this.proceedOrder();
          }else{
            this.pre = "Order";
            this.makePayment();
          }
        },
        (_error) => {
          this.toastr.error("Error: Please enter the correct OTP");
          this.loaderShow = false;
        }
      );
    }
    
    proceedOrder() {
      this.loaderShow = true;
      this._httpWebshopService.payemntCheck().subscribe(data =>{
        this.loaderShow = false;
        if(data){
          this.makePayment();
        }else{
          if (!this.pointsArray) {
            this.pointsSelectError = true;
            return false;
          } else {
            this.createOrder();
          }
        }
      })
    }

    makePayment() {
      if(!this.isChecked){
        this.orderData.DeliveryPhoneNumber = this.deliveryMobile;
          this.orderData.DeliveryEmail = this.deliveryEmail;
          this.orderData.CustomerID = this.shoppingCartList.CustomerID;
          this.orderData.CurrencyID = this.shoppingCartList.CurrencyID;
          this.orderData.UserBrowserID = this.shoppingCartList.UserBrowserID;
          this.orderData.DeliveryAddress = this.loggedInData.DeliveryAddress ? this.loggedInData.DeliveryAddress : "Chennai";
          this.orderData.DeliveryCity = this.loggedInData.DeliveryCity ? this.loggedInData.DeliveryCity : "Chennai";
          this.orderData.DeliveryCountry = this.loggedInData.DeliveryCountry;
          this.orderData.totalQuantity = this.totalQuantity;
          this.orderData.UserID = this.loggedInData.UserID;
      }else{
        this.pre = "VkRedeem";
      }
      this.orderData.RedeemReward = this.isChecked;
      sessionStorage.setItem('order', JSON.stringify(this.orderData));
      sessionStorage.setItem('orderid', this.cartId);
      sessionStorage.setItem('pre', this.pre);
      const domainName = "voucherkart";
      const customerName = this.loggedInData.FirstName ? this.loggedInData.FirstName : "";
      this._httpWebshopService.webTinyUrl().subscribe(data =>{
        const urls = `Amount=${this.cartId}&ProductInfo=iphone&FirstName=${customerName}&Email=${this.loggedInData.Email}&Phone=${this.loggedInData.PhoneNumber}&udf1=Y&udf2=${this.pre}&udf3=${domainName}&udf4=${data}&udf5=${data}&udf6=${this.loggedInData.UserID}`;
        const params = {
          data:urls
        }
        this.loaderShow = true;
        this._httpWebshopService.encryptPaymentURL(params).subscribe({
          next: (data:any) => {
            this.loaderShow = false;
            if(data){
              console.log(data,"encryption value")
              this.encryptedData = data.Data;
              this.encryptedKey = data.Key;
              const pay = `${environment.ENVIRONMENT.pbase}PayU/Index?Data=${this.encryptedData}&Key=${this.encryptedKey}`
              window.location.href = pay;         
            }
          },error : e => {
            this.loaderShow = false;
            console.log(e.error.Message)
          }
        })
        
  });

      // this._httpWebshopService.webTinyUrl().subscribe(data =>{
      //   const paymentURL = environment.ENVIRONMENT.pbase +  'AtomPayment/AtomPaymentRequest?OrderId=' + this.cartId + '&customerID='+this.loggedInData.UserID+'&IsRewardKart=Y&Domain=voucherkart&Pre='+this.pre+'&ReturnURL='+data+'&Email='+this.loggedInData.Email+'&Mobile='+this.loggedInData.PhoneNumber;
      //   window.location.href = paymentURL;   
      // });
    }

    createOrder(){
      let a;
      if(sessionStorage.getItem('campaign') && sessionStorage.getItem('campaign') !== 'undefined'){
        a = JSON.parse(sessionStorage.getItem('campaign'))
      this.pointsArray.push(a['RewardId']);
      }
      this.loaderShow = true;
      this.orderData.DeliveryRecipient = this.orderData.DeliveryContactPerson;
      this.orderData.PaymentTypeID = 4;
      this.orderData.IsChildShop = true;
      if(a){
        this.orderData.RewardIds = a['RewardId'];
      }
      this.orderData.IsVoucher = true;
      this.orderData.ChildShopIsPayment = this.childShopPayment;
      this.orderData.CampaignId = 177;
      this.orderData.ShoppingCartItems[0].shippingMethod = 'Courier';
      this.orderData.ShippingMethod = this.orderData.ShoppingCartItems[0].shippingMethod;
      if(this.isChecked){
        this.orderData.RedeemReward  = true;
      }else{
        this.orderData.RedeemReward  = false;
      }
  
      this._httpWebshopService.orderCreate(this.orderData).subscribe((data: any[]) => {
        //  console.log('orderid',data)
        this.loaderShow = false;
        this.orderSubmit(data["OrderID"]);
        this.deleteShoppingCartItemNew(data["OrderID"]);
        this.router.navigate(["/voucher-orderSuccess/" + data["OrderID"] + "/0"], {
          replaceUrl: false,
        });
      },
        (_error) => {
          this.loaderShow = false;
        }
      );
    }

    getParameterByName(name, url) {
      //if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&|]" + name + "(=([^&#|]*)|&|#|$)", 'i'),
      results = regex.exec(url);
      if (!results)
        return null;
      if (!results[2])
        return '';
      return results[2].replace(/\+/g, " ");
    }

    orderSubmit(id) {
      this._httpWebshopService.orderSubmit(id).subscribe();
    }

    deleteShoppingCartItemNew(id) {
      if(!this.itemsList || this.itemsList.length === 0){
        this.itemsList = JSON.parse(sessionStorage.getItem('shoppingCartDatas'));
      }
      this.getShoppingCartIDList = [];
      for (let i = 0; i < this.itemsList.length; i++) {
        /** new-code => Proceed to checkout only product which completed all steps  */
        if (this.itemsList[i].isChecked) {
          this.getShoppingCartIDList.push(this.itemsList[i].ShoppingCartItemID); // old-code
        }
      }
      const webShopSettings = this.globalService.webShopSettings();
      const loggedInData = this.globalService.getLoggedInData();
      if (loggedInData !== null) {
        this.cartParams = {
          UserID: loggedInData.UserID,
          CustomerID: webShopSettings.CustomerID,
          CurrencyID: 20,
          DeleteShoppingCartItemIDs: this.getShoppingCartIDList,
        };
      } else {
        this.cartParams = {
          UserID: null,
          CustomerID: webShopSettings.CustomerID,
          CurrencyID: 20,
          DeleteShoppingCartItemIDs: this.getShoppingCartIDList,
        };
      }
      try {
        this.loaderShow = true;
        // console.log(this.cartParams);
        this._httpWebshopService.deleteShoppingCartItem(this.cartParams).subscribe(
          (data) => {
            // console.log("test")
            // console.log(_data);
            const shoppingCartitemData = [];
            let k = 0;
            for (let i = 0; i < data["Items"].length; i++) {
              if (data["Items"][i].isVisible) {
                shoppingCartitemData[k] = data["Items"][i];
                k++;
              }
            }
            sessionStorage.setItem("shoppingCartCount", shoppingCartitemData.length.toString());
            this._httpWebshopService.setCount(shoppingCartitemData.length);
            this.loaderShow = false;
            this.router.navigate(["/voucher-orderSuccess/" + id + "/0"], {
              replaceUrl: false,
            });

          },
          (error) => {
            this.loaderShow = false;
            this.toastr.error(error);
          }
        );
      } catch (error) {
        this.toastr.error(error);
      }
    }

    createInvoice(params) {   
 
      try {
        const userSettings = this.globalService.getUserSettings();
        this.printInvoiceUrl = document.getElementById("invoicedownload");
        this.printInvoiceUrl.href = environment.ENVIRONMENT.baseURL + userSettings.LanguageCode + "/invoices/pdf/" + params.OrderID + (params.OrderConfirmationOnlyForISPs ? "?forceProformaInvoiceTitle=true" : "");        
        this.printInvoiceUrl.click();  
      } catch (error) {       
        this.toastr.error(error);
      }
    }
    activePanel(name){
      this.activeId = '';
      setTimeout(()=>{
        this.activeId = name;
        window.scroll({ 
          top: 0, 
          left: 0, 
          behavior: 'smooth' 
        });
      },50)
    }

    finishModal(){
      this.userModal.hide();
    }
}