import { Component, OnInit, ViewChild, SimpleChanges, Input, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CampaignService, ProductService, WebshopService } from "app/linknet-common/restful-services/restful.service";
import { NewOrderComponent } from "app/webshop/product-detail/new-order/new-order.component";
import { ToastService } from "app/typescripts/pro/alerts";
import { Title, Meta } from "@angular/platform-browser";
import { Location, DatePipe } from "@angular/common";
import { GlobalService } from "app/linknet-common/globals/global.service";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { TranslateService } from "@ngx-translate/core";
import { MenuRightComponent } from "../header-one/menu-right/menu-right.component";
import { NgxImageGalleryComponent, GALLERY_IMAGE } from "ngx-image-gallery";
import * as moment from "moment";
import { INgxMyDpOptions } from "ngx-mydatepicker";
import { CommonServicesService } from "app/linknet-common/common-services/common-services.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "shop-app-product-detail",
  templateUrl: "./product-detail.component.html",
  styleUrls: ["./product-detail.component.scss"],
  providers: [NewOrderComponent, MenuRightComponent],
})
export class ProductDetailComponent implements OnInit {
  public isParentCustomer: Boolean = false;
  webshopData: any;
  public productDetail: any = {};
  public newOrderValues: any = {};
  public productionLineList: any;
  public hideJoinOrders: Boolean = false;
  public stepCount: number;
  public currQty: any;
  public colorsList: Array<any>;
  public orderQuantity: number;
  public orderQuantityChangeStatus: Boolean = false;
  public orderQuantityParent = 0;
  public FileName: string;
  public dynamicProductRangePricesParent = 0;
  public expressOrderStatus: Boolean = false;
  public selectColorId: any = null;
  public responseData: any;
  public expressOrderDate: any;
  public cookieValue: any;
  public shoppingCartPostParams: any;
  public browserShoppingToken: any = 0;
  public loginUserId: any = 0;
  public currency: any;
  public postParams = {};
  @ViewChild("SampleOrder") public sampleOrder;
  @ViewChild("noProductModal") public noProductModal;
  @ViewChild("stockModal") public stockModal;
  @ViewChild("productStockModal") public productStockModal;
  @ViewChild("noBrandingConfirmation") public noBrandingConfirmation;
  @Input() productId: any;
  public sampleOrderWithoutLogo: any;
  public sampleOrderWithLogo: any;
  public productMaxInputValue: any;
  public selectedIds = [];
  public showDetail: Boolean = false;
  testroute: string;
  public getPopupHeight: any;
  public lang: any;
  public case1: any;
  public case2: any;
  public isShowinquirybutton: any;
  public showBrandingMethod: any;
  public orderWithoutBrandingConfirmed: Boolean = false;
  public placeOrderData: any;
  public placeOrderIsJoinOrder: any;
  public balancePoints: any;

  //new design =>  Shopping Cart Property Variable declarations
  sumOrderedQuantity: any;
  transportationhandlingcosts: any;
  currentLogoIndex: any;
  dropDownDisable = false;
  addOrUpdateShoppingCartStatus = true;
  tabCompletedCount: number;
  initialDateSplit = [];
  initialDate = [];
  steps = [];
  checked = {};
  orderLogoColors: string[];
  userLogos: any;
  productSizesindex = -1;
  productSizes: any;
  // responseData: any;
  initialDeliveryCountry: string;
  shoppingCartData: any;
  pantoneCodes = [];
  browserToken: string;
  shoppingCartItemId: any;
  productSizeCurrentQty = 0;
  tabCount = 1;
  lastTab = "initial";
  currentTab = "initial";
  oldShoppingCartItem: any;
  public noOfDays: any;
  public days: any;
  public daysString: any;
  public earliestDeliveryDate: any;
  public earliestDeliveryDateForNumOfDaysTillProductStart: any;
  public comment;
  public cartParams: any;
  public shoppingCartList: any;
  public itemsList: any;
  public cartCount;
  public joinOrderCounts: any;
  public getShoppingCartIDList: any;
  public checkoutDataList = [];
  public translatedMessage: any;
  public checkoutStatus: Boolean = false;
  public firstTabSplitPopupOpened: Boolean = false;
  public isQuantityValid: Boolean = true;
  // @ViewChild('stockModal') public stockModal;
  @ViewChild("shoppingCartStockModal") public shoppingCartStockModal;
  @ViewChild("logoListModal") public logoListModal;
  @ViewChild("staticTabs") public staticTabs;
  @ViewChild("futureStockModal") public futureStockModal;
  @ViewChild("ProductCartModal") public productCartModal;
  @ViewChild("inquiryModal") public inquiryModal;
  @ViewChild("MenuRightComponent") public _child: MenuRightComponent;
  @ViewChild(NgxImageGalleryComponent)
  ngxPosImageGallery: NgxImageGalleryComponent;

  /** new-code */
  @ViewChild("NewOrderModalConfirmation") public newOrderModalConfirmation;
  @ViewChild("CheckoutModalConfirmation") public checkoutModalConfirmation;
  /** new-code */

  // gallery images
  public posImages: GALLERY_IMAGE[] = [];
  userId: any;
  public customOptions = { allowedContentTypes: ["application/pdf", "application/postscript", "application/postscript"], };
  customerId: any;
  public myOptions: INgxMyDpOptions = { dateFormat: "dd-mm-yyyy" };
  public deliveryDate: any;
  public shoppingCartDeliveryDate: any;
  public errorStatus: Boolean = false;
  public loggedInData: any;
  public productBranding: Boolean = false;
  batchProductColorCode: any;
  batchProductColorName: any;
  case3: Boolean = false;
  productDeliveryDate: any;
  isShowNoFutureStock: boolean;
  finaldeliverydateformats: Date;
  finaldeliverydate: any;
  showfuturestockwithoutstock: boolean;
  restquantity: number;
  finaldeliverydateformat: string;
  userproductiondates: string;
  stepName: any;
  batchSizesFutureStock: any;
  productCurrentQty: any;
  public calculatedFutureStock: any;
  param: { DeliveryDate: any; OrderQty: any };
  finalFutureDeliveryDate: Date;
  finalStockDeliveryDate: Date;
  finalStockValue: any = 0;
  finalFutureValue: any = 0;
  pageName: string;
  restQuantity: any;
  oldProductColorArray: any;
  oldProductSizes: any;
  public isDoubleLogoOnSamePosition: Boolean = false;

  // Language Translate Variables
  public noFutureStockQty: any;
  public noFutureStockQtyText: any;
  public noFutureStock: any;
  public noFutureStockText: any;
  public loginCancel: any;
  public loginCancelText: any;
  public inquiry: any;
  public inquiryText: any;

  public futureStockZero: any;
  public futureStockZeroText: any;
  public futureStock3: any;
  public futureStock3Text: any;
  public futureStock31: any;
  public futureStock31Text: any;
  public futureTransportationFee: any;
  public futureTransportationFeeText: any;
  public stockBalance: any;
  public stockBalanceText: any;
  public restQty: any;
  public restQtyText: any;
  public proceed: any;
  public proceedText: any;
  public stock2Calculated: any;
  public stock2CalculatedText: any;
  public fullQty: any;
  public fullQtyText: any;
  //end of new design =>  Shopping Cart Property Variable declarations

  /** new-code - New Varible declartions */
  public tabsDisable: Boolean = false;
  public shoppingCartItemIdStatus: Boolean = false;
  public orderDate: any = "";
  public displayCommonPriceValue = 0;
  public displayJoinOrderCommonPriceValue = 0;
  public productPriceInclBranding = 0;
  public joinOrderProductPriceInclBranding = 0;
  public displayCommonCurrency: any;
  public resetNewOrderStatus: Boolean = false;
  public resetJoinOrderStatus: Boolean = false;
  public spinnerShow = false;
  public checkOrderType: any;
  public orderName: any;
  public proceedNewJoinOrderStatus: Boolean = true;
  public newJoinOrderSubmitData: any;
  public oneTabStatus: Boolean = false; // product have only one tab(Start Tab)
  public currentTabName: any = "starttab";
  public tabNames = []; // to know list of tab names for particular visited product in PDP
  public commonPriceSectionAddToCartButtonStatus: Boolean = false;
  public joinOrderProductStatus: Boolean = false;
  public autoCallShoppingCartAPIStatus: Boolean = false;
  public newOrderButtonClick: Boolean = false;
  public joinOrderButtonClick: Boolean = false;
  public joinOrderShoppingCartData: any;
  public callProceedShoppingCartAddItemStatus: Boolean = false;
  @Input() colorId: any;
  public LogoTypesCodes = [];
  public ShowPricesIncludingLogoType: any = 0;
  printPriceCalculation: any[];
  minimumOrderQty = 0;
  public addAnotherBrandingClickStatus: Boolean = false;
  public loaderHideClass: Boolean = false;
  @Output() callProductDetailAPI = new EventEmitter();
  public changeDeliveryDateText: Boolean = false; // for display text as delivery date / Earliest disp. date in HTML Page
  public wsaStatus: Boolean = false;
  fixedLogoProductPrintPrices = [];
  isSmartWorks: boolean = false;
  princepipes : boolean = false;
  constructor(
    private router: Router,
    private _httpWebshopService: WebshopService,
    public campaignService: CampaignService,
    public globalService: GlobalService,
    public route: ActivatedRoute,
    public _httpProductService: ProductService,
    public newOrderComponent: NewOrderComponent,
    public toastService: ToastService,
    public titleService: Title,
    public meta: Meta,
    private location: Location,
    private cookieService: CookieService,
    public translate: TranslateService,
    public commonServicesService: CommonServicesService,
    public datepipe: DatePipe,
    public menuRightComponent: MenuRightComponent,
    private toastr: ToastrService
  ) {
    meta.addTags([
      { name: "title", content: "Bharani SEO" },
      { name: "keywords", content: "Bharani SEO" },
      { name: "description", content: "Bharani SEO" },
    ]);
    this.orderQuantityChangeStatus = true;

    //new designs constructors work
    this.calculatedFutureStock = [];
    this.loggedInData = JSON.parse(sessionStorage.getItem("loggedInData"));
    this.steps["initial"] = 1;
    const webShopSettings = this.globalService.webShopSettings();
    const userData = this.globalService.getLoggedInData();
    if (userData === null || userData === undefined) {
      this.userId = null;
      this.initialDeliveryCountry = "DK";
    } else {
      this.userId = userData.UserID;
      this.initialDeliveryCountry = userData.DeliveryCountry;
    }
    this.shoppingCartItemId = this.route.snapshot.params ? this.route.snapshot.params.shoppingCartItemId : null;
    this.customerId = webShopSettings.CustomerID;
    this.browserToken = this.cookieService.get("shoppingCardTokenId");
    this.pantoneCodes = this._httpWebshopService.pantoneCodes;
    const userSettings = this.globalService.getUserSettings();
    const postParams = {
      CustomerID: this.customerId,
      UserID: this.userId,
      UserBrowserID: this.browserToken,
      CurrencyID: userSettings.CurrencyID,
      InitialDeliveryCountry: this.initialDeliveryCountry,
      ShoppingCartItemID: this.shoppingCartItemId,
    };

    if (this.shoppingCartItemId !== null && this.shoppingCartItemId !== undefined && this.shoppingCartItemId !== "") {
      this.shoppingCartItemIdStatus = true;
      this.spinnerShow = true;
      this._httpWebshopService.getShoppingCartItems(postParams).subscribe((data) => {
        this.spinnerShow = false;
        this.responseData = data;
        sessionStorage.setItem("cartData", JSON.stringify(this.responseData));
        //console.log('response data show', this.responseData)
        if(data["Items"].length === 0){
          return false;
        }
        this.earliestDeliveryDate = data["Items"][0].EarliestDeliveryDate;
        /** new-code */
        if (!data["isJoinOrder"]) {
          this.displayCommonPrice(data["Items"], data["CurrencyCode"]);
        } else {
          this.joinOrderShoppingCartData = data;
          this.displayJoinOrderCommonPrice(data["Items"], data["CurrencyCode"]);
        }
        this.orderDate = data["Items"][0].DeliveryDate; // assign value to new order / Join Order Date variable
        this.expressOrderStatus = data["Items"][0].isExpressOrder; // assign value to Express order Checkbox
        /** new-code */

        this.earliestDeliveryDateForNumOfDaysTillProductStart = data["Items"][0].EarliestDeliveryDateForNumOfDaysTillProductStart;
        this.calculateDays(data["Items"][0].EarliestDeliveryDateForNumOfDaysTillProductStart, data["Items"][0].DeliveryDate);
        this.shoppingCartData = data["Items"][0];
        this.sumOrderedQuantity = Number(this.shoppingCartData.OrderedQuantity) + Number(this.shoppingCartData.ProductionLineOrderedQuantity);

        for (let j = 0; j < this.shoppingCartData.ProductProperties.length; j++) {
          if (this.shoppingCartData.ProductProperties[j].SelectedOptionID > 0) {
            this.shoppingCartData.ProductProperties[j].SelectedOptionID = this.shoppingCartData.ProductProperties[j].SelectedOptionID + "";
          }
        }

        this.deliveryDate = this.datepipe.transform(this.shoppingCartData.DeliveryDate, "dd-MM-yyyy");
        this.shoppingCartDeliveryDate = this.datepipe.transform(this.shoppingCartData.DeliveryDate, "yyyy-MM-dd");
        if (this.shoppingCartData.isSampleWithoutLogo || this.shoppingCartData.isSampleWithLogo) {
          this.initialDate = this.shoppingCartData.DeliveryDate.split("-");
        } else {
          this.initialDate = this.shoppingCartData.EarliestDeliveryDate.split("-");
        }
        this.initialDateSplit = this.initialDate[2].split("T");
        this.initialDateSplit[0] = this.initialDateSplit[0] - 1;
        this.myOptions = {
          dateFormat: "dd-mm-yyyy",
          disableUntil: {
            year: this.initialDate[0],
            month: this.initialDate[1],
            day: this.initialDateSplit[0],
          },
        };
        // tslint:disable-next-line:max-line-length
        //  if (this.shoppingCartData.Logos.length > 0 && !this.shoppingCartData.isSampleWithoutLogo && this.shoppingCartData.isFixedLogo !== true) {
        if (this.shoppingCartData.Logos.length > 0 && !this.shoppingCartData.isSampleWithoutLogo) {
          this.steps["logo"] = this.tabCount = this.tabCount + 1;
          this.lastTab = "logo";
          /** new-code => to know list of tab names for particular visited product in PDP */
          this.tabNames.push("logo");
        }
        if (this.shoppingCartData.ProductColors.length === 1) {
          this.shoppingCartData.ProductColorID = this.shoppingCartData.ProductColors[0].ProductColorID;
        }
        if (this.shoppingCartData.ProductColors.length >= 1) {
          this.steps["color"] = this.tabCount = this.tabCount + 1;
          this.lastTab = "color";
          /** new-code => to know list of tab names for particular visited product in PDP */
          this.tabNames.push("color");
        }
        if (this.shoppingCartData.ProductSizes.length > 0) {
          this.steps["productSize"] = this.tabCount = this.tabCount + 1;
          this.lastTab = "productSize";
          /** new-code => to know list of tab names for particular visited product in PDP */
          this.tabNames.push("productSize");

          /** new-code => to add sum of qty of different sizes */
          if (this.shoppingCartData.ProductColorID !== null && this.shoppingCartData.ProductColorID !== undefined && this.shoppingCartData.ProductColorID !== "") {
            // tslint:disable-next-line:max-line-length
            const sizes = this.shoppingCartData.ProductColors.filter((ppp) => ppp.ProductColorID == this.shoppingCartData.ProductColorID)[0];
            for (let i = 0; i < sizes.AvailableSizes.length; i++) {
              if (sizes.AvailableSizes[i].OrderedQuantity !== undefined) {
                if (sizes.AvailableSizes[i].OrderedQuantity > 0) {
                  this.productSizeCurrentQty = Number(this.productSizeCurrentQty) + Number(sizes.AvailableSizes[i].OrderedQuantity);
                }
              }
            }
          }
          /** new-code => to add sum of qty of different sizes */
        }

        /** new-code => assign selected color id to selectColorId variable */
        if (this.shoppingCartData.ProductColorID !== undefined && this.shoppingCartData.ProductColorID !== null && this.shoppingCartData.ProductColorID > 0) {
          this.selectColorId = this.shoppingCartData.ProductColorID;
        }
        /** new-code => assign selected color id to selectColorId variable */

        if ((this.shoppingCartData.AdditionalCosts && this.shoppingCartData.AdditionalCosts.length > 0) || (this.shoppingCartData.Price && this.shoppingCartData.Price.GovernmentalCosts && this.shoppingCartData.Price.GovernmentalCosts.length > 0 && this.userId !== null)) {
          this.steps["additionalCost"] = this.tabCount = this.tabCount + 1;
          this.lastTab = "additionalCost";
          /** new-code => to know list of tab names for particular visited product in PDP */
          this.tabNames.push("additionalCost");
        }
        if (this.shoppingCartData.ProductProperties.length > 0) {
          this.steps["productProperty"] = this.tabCount = this.tabCount + 1;
          this.lastTab = "productProperty";
          /** new-code => to know list of tab names for particular visited product in PDP */
          this.tabNames.push("productProperty");
        }

        if (this.tabCount === 1) {
          this.tabNames = [];
          this.shoppingCartData.isChecked = true;
          /** new-code => For Checkout Disable */
          this.oneTabStatus = false;
          /** new-code => For Checkout Disable */
        }

        // for (let j = 0; j < this.shoppingCartData.ProductPrintPrices.length; j++) {
        //   this.shoppingCartData.ProductPrintPrices[j].Description = this.shoppingCartData.ProductPrintPrices[j].Description
        //     + ' ' + this.shoppingCartData.ProductPrintPrices[j].LogoTypeName;
        // }

        if (this.shoppingCartData.FixedUnits) {
          this.shoppingCartData.units = this.shoppingCartData.OrderedQuantity / this.shoppingCartData.PiecesPerUnit;
        }
        this.shoppingCartData.isInitialStepCompleted = true;
        this.checked = { true: "check-", false: "", null: "" };
        for (let j = 0; j < this.shoppingCartData.ProductColors.length; j++) {
          if (this.shoppingCartData.ProductColorID === this.shoppingCartData.ProductColors[j].ProductColorID || this.shoppingCartData.ProductColorID === undefined) {
            if (this.shoppingCartData.ProductColors[j].AvailableSizes.length > 0) {
              this.productSizes = this.shoppingCartData.ProductColors[j].AvailableSizes;
              this.productSizesindex = j;
            }
          }
        }
        for (let j = 0; j < this.shoppingCartData.AdditionalCosts.length; j++) {
          this.shoppingCartData.isAdditionalCostsStepCompleted = true;
          if (!this.shoppingCartData.AdditionalCosts[j].isOptionalForBuyer) {
            this.shoppingCartData.AdditionalCosts[j].isSelected = true;
          }
        }
        if (this.shoppingCartData.AdditionalCosts && this.shoppingCartData.AdditionalCosts.length === 0 && this.shoppingCartData.Price && this.shoppingCartData.Price.GovernmentalCosts && this.shoppingCartData.Price.GovernmentalCosts.length > 0) {
          this.shoppingCartData.isAdditionalCostsStepCompleted = true;
        }
        if (this.shoppingCartData.isSampleWithoutLogo === true) {
          this.shoppingCartData.isProceedWithoutLogo = true;
        }
        this.logoCompleted(false, "", false);
        if (this.shoppingCartData.isDisabled && this.tabCount !== 1) {
          this.shoppingCartData.isColorStepCompleted = true;
        }
        if (this.shoppingCartData.isDisabled && window.sessionStorage.showWidget === "true") {
          this.productCartModal.showProductCartModal();
          window.sessionStorage.showWidget = false;
        }

        this.translate.get("PRODUCT_DETAIL.RECOMMENDED").subscribe((res: string) => {
          // tslint:disable-next-line:max-line-length
          this.shoppingCartData.ProductLogoPositions.forEach((element) => {
            if (element.isDefault)
              element.RecommendedText = " (" + res + ")";
          });
          this.shoppingCartData.ProductLogoTypes.forEach((element) => {
            if (element.isDefault)
              element.RecommendedText = " (" + res + ")";
          });
          this.shoppingCartData.ProductLogoMaxAreas.forEach((element) => {
            if (element.isDefault)
              element.RecommendedText = " (" + res + ")";
          });
        });

        for (let i = 0; i < this.shoppingCartData.Logos.length; i++) {
          this.shoppingCartData.Logos[i].ProductPrintPrices = this.shoppingCartData.ProductPrintPrices;
          this.shoppingCartData.Logos[i].ProductLogoPositions = this.shoppingCartData.ProductLogoPositions;
          this.shoppingCartData.Logos[i].ProductLogoTypes = this.shoppingCartData.ProductLogoTypes;
          this.shoppingCartData.Logos[i].ProductLogoMaxAreas = this.shoppingCartData.ProductLogoMaxAreas;

          if (this.shoppingCartData.DefaultLogoPositionID != undefined && this.shoppingCartData.Logos[i].PrintPriceID == undefined) {
            //this.shoppingCartData.Logos[i].LogoPositionID = this.shoppingCartData.DefaultLogoPositionID;
            this.shoppingCartData.Logos[i].LogoPositionID = "";
          } else if (this.shoppingCartData.Logos[i].PrintPriceID == undefined || this.shoppingCartData.Logos[i].LogoPositionID == undefined) {
            this.shoppingCartData.Logos[i].LogoPositionID = "";
          }

          if (this.shoppingCartData.DefaultLogoTypeID != undefined && this.shoppingCartData.Logos[i].PrintPriceID == undefined) {
            //this.shoppingCartData.Logos[i].LogoTypeID  = this.shoppingCartData.DefaultLogoTypeID;
            this.shoppingCartData.Logos[i].LogoTypeID = "";
          } else if (this.shoppingCartData.Logos[i].PrintPriceID == undefined) {
            this.shoppingCartData.Logos[i].LogoTypeID = "";
          }

          if (this.shoppingCartData.DefaultLogoMaxAreaID != undefined && this.shoppingCartData.Logos[i].PrintPriceID == undefined) {
            //this.shoppingCartData.Logos[i].LogoMaxAreaID  = this.shoppingCartData.DefaultLogoMaxAreaID;
            this.shoppingCartData.Logos[i].LogoMaxAreaID = "";
          } else if (this.shoppingCartData.Logos[i].PrintPriceID == undefined || this.shoppingCartData.Logos[i].LogoMaxAreaID == undefined) {
            this.shoppingCartData.Logos[i].LogoMaxAreaID = "";
          }

          this.applyLogoFilters(this.shoppingCartData.Logos[i]);
        }

        /** new-code => Prefill Join order section(checkbox & Date), if order is based on Join Order */
        if (this.shoppingCartData.ProductionLineID !== null && this.shoppingCartData.ProductionLineID !== "") {
          this.resetNewOrderStatus = true;
          this.expressOrderStatus = false;
          this.resetJoinOrderStatus = true;
        }
        //  this.getTransportationhandlingcosts(this.shoppingCartData.OrderedQuantity);
      });
    } else {
      this.tabsDisable = true;
      this.shoppingCartItemIdStatus = false;
    }
  }

  ngOnInit() {
    /** Code to check Whether Loggedin user is Active/InActive */
    const loggedInData = this.globalService.getLoggedInData();
    const webshopDetails = JSON.parse(sessionStorage.getItem("webshopDetail"));
    this.webshopData = webshopDetails;
    if (this.webshopData.DomainName === "sw" || this.webshopData.DomainName === "smartworks"){
      this.isSmartWorks = true;
    }
    if (this.webshopData.DomainName === "princepipes"){
      this.princepipes = true;
    }
    if (this.webshopData.ParentCustomerDomainName === this.webshopData.DomainName) {
      this.isParentCustomer = true;
    } else {
      this.isParentCustomer = false;
    }
    if (loggedInData !== null) {
      this._httpWebshopService.getUserActiveStatus({ UserID: loggedInData.UserID, }).subscribe((_data) => {
        //console.log("Response data : ", data);
      }, (error) => {
        if (error.status === 500) {
          try {
            // Remove saved data from sessionStorage
            sessionStorage.removeItem("loggedInData");
            sessionStorage.removeItem("categoryList");
            sessionStorage.removeItem("colorsList");
            sessionStorage.removeItem("categoryOptionList");
            // based on filter section regarding product tags.
            if (sessionStorage.getItem("productTags") !== null && sessionStorage.getItem("productTags") !== undefined && sessionStorage.getItem("productTags") !== "") {
              sessionStorage.removeItem("productTags");
            }
            // Remove all saved data from sessionStorage
            const webshopDetail = sessionStorage.getItem("webshopDetail");
            sessionStorage.setItem("webshopDetail", webshopDetail);
            const data = JSON.parse(sessionStorage.getItem("webshopDetail"));
            this.loggedInData = {};
            this.globalService.loggedin(null);
            // this.router.navigate([''], { preserveQueryParams: false, replaceUrl: true });
            this.globalService.overwriteSession("userSettings", "LanguageCode", data.DefaultLanguageCode);
            this.globalService.overwriteSession("userSettings", "LanguageID", data.DefaultLanguageID);
            this.globalService.overwriteSession("userSettings", "CurrencyCode", data.DefaultCurrencyCode);
            this.globalService.overwriteSession("userSettings", "CurrencyID", data.DefaultCurrencyID);
            //  location.reload();
            setTimeout(() => {
              sessionStorage.removeItem("webshopDetail");
              const storageData = {
                CustomerID: data.CustomerID,
              };
              sessionStorage.setItem("webshopDetail", JSON.stringify(storageData));
              sessionStorage.removeItem("themeReApplyStatus");
            }, 100);
            const postParams = {
              userdeactive: true,
            };
            setTimeout(() => {
              this.router.navigate(["/"], { queryParams: postParams, replaceUrl: false, });
              setTimeout(() => {
                location.reload();
              }, 100);
            }, 100);
          } catch (error) {
            this.toastr.error(error);
          }
        } else {
          this.toastr.error(error.error.Message);
        }
      });
    }

    const webshopDetail = JSON.parse(sessionStorage.getItem("webshopDetail"));
    if (webshopDetail.ChangeDeliveryDateText) {
      this.changeDeliveryDateText = true;
    }
    if (Number(webshopDetail.CustomerID) === 45) {
      this.wsaStatus = true;
    }

    // add class in body tag
    const bodytag = document.getElementsByTagName("body")[0];
    bodytag.classList.add("productModal");

    const userSettings = this.globalService.getUserSettings();
    this.currency = userSettings.CurrencyCode;
    this.stepCount = 1;
    let productID = this.route.snapshot.params ? this.route.snapshot.params.productID : null;

    /** new-code => Preselect Color ID */
    this.selectColorId = this.colorId !== null && this.colorId !== "" ? this.colorId : null;
    /** new-code => Preselect Color ID */

    /** new-code */
    let shoppingCartItemId = "";
    if (this.route.snapshot.params) {
      if (this.route.snapshot.params.shoppingCartItemId !== null && this.route.snapshot.params.shoppingCartItemId !== undefined && this.route.snapshot.params.shoppingCartItemId !== "") {
        shoppingCartItemId = this.route.snapshot.params ? this.route.snapshot.params.shoppingCartItemId : "";
      }
    }
    /** new-code */

    /** new-code */
    if (this.shoppingCartItemId === null || this.shoppingCartItemId === undefined || this.shoppingCartItemId === "") {
      if (sessionStorage.getItem("newOrderData") !== undefined && sessionStorage.getItem("newOrderData") !== null) {
        sessionStorage.removeItem("newOrderData");
        sessionStorage.removeItem("newOrderId");
      }
      if (sessionStorage.getItem("joinOrderData") !== undefined && sessionStorage.getItem("joinOrderData") !== null) {
        sessionStorage.removeItem("joinOrderData");
        sessionStorage.removeItem("joinOrderId");
      }
    }

    if (this.shoppingCartItemId !== null || this.shoppingCartItemId !== undefined || this.shoppingCartItemId !== "") {
      if (sessionStorage.getItem("joinOrderData") !== undefined && sessionStorage.getItem("joinOrderData") !== null) {
        this.joinOrderShoppingCartData = JSON.parse(sessionStorage.getItem("joinOrderData"));
        //console.log("this.joinOrderShoppingCartData JOIN ORDER PRICE SECTION: ", this.joinOrderShoppingCartData);
        this.displayJoinOrderCommonPrice(this.joinOrderShoppingCartData["Item"], this.joinOrderShoppingCartData["CurrencyCode"]);
      }
    }
    /** new-code */

    if (productID === undefined) {
      productID = this.productId;
      this.openProductDetailModel(productID, shoppingCartItemId);
    } else {
      this.lang = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.lang : null;
      this.showDetail = true;
      if (this.lang !== undefined) {
        if (this.translate.getDefaultLang() !== this.lang) {
          try {
            let languageID = 1;
            if (this.lang === "dk") {
              languageID = 2;
            }
            // this.router.navigate(['/']);
            this.translate.use(this.lang);
            this.globalService.overwriteSession("userSettings", "LanguageCode", this.lang);
            this.globalService.overwriteSession("userSettings", "LanguageID", languageID);
            this.openProductDetailModel(productID, shoppingCartItemId);
          } catch (error) {
            // this.toastService.error(error);
          }
        } else {
          this.openProductDetailModel(productID, shoppingCartItemId);
        }
      } else {
        this.openProductDetailModel(productID, shoppingCartItemId);
      }
    }
    this.getScreenheight();
  }

  ngOnChanges(_changes: SimpleChanges): void {
    //console.log("Changes PDP : ", changes);
  }

  //new design pasted 3rd times   // this function is not needed in productDetail Page
  changeDeliveryDate(event) {
    const date = event.formatted.split("-");
    this.shoppingCartDeliveryDate = date[2] + "-" + date[1] + "-" + date[0];
    this.calculateDays(this.earliestDeliveryDateForNumOfDaysTillProductStart, event.jsdate);
    this.shoppingCartData.DeliveryDate = this.shoppingCartDeliveryDate;
  }

  addMoreLogo(addAnotherBrandingClickStatus = true) {
    this.addAnotherBrandingClickStatus = addAnotherBrandingClickStatus;
    this.shoppingCartData.Logos.push({
      PrintPriceID: undefined,
      OrderLogoColors: this.orderLogoColors,
      isEmailLogoLater: false,
    });
    this.logoCompleted(false, "", true);
  }

  resetLogoIndex(index) {
    this.shoppingCartData.Logos[index] = {
      PrintPriceID: undefined,
      OrderLogoColors: this.orderLogoColors,
      isEmailLogoLater: false
    };
    this.logoCompleted(false, "", true);
    //console.log('this.shoppingCartData.Logos : ', this.shoppingCartData.Logos);
  }

  calculateDays(date1, date2) {
    this.daysString = "";
    /** To Calculate Days Diff */
    const earliestDate = new Date(date1);
    const deliveryDates = new Date(date2);
    const daysDiff = Math.floor((Date.UTC(deliveryDates.getFullYear(), deliveryDates.getMonth(), deliveryDates.getDate()) - Date.UTC(earliestDate.getFullYear(), earliestDate.getMonth(), earliestDate.getDate())) / (1000 * 60 * 60 * 24));
    // this.days = (this.translate.get('HEADING.TODAY'));
    // this.days = this.days.value;
    this.translate.get("HEADING.TODAY").subscribe((res: string) => {
      this.days = res;
    });
    this.days = this.days;
    if (daysDiff === 1) {
      this.days = daysDiff;
      // this.daysString = (this.translate.get('HEADING.DAY'));
      // this.daysString = this.daysString.value;
      this.translate.get("HEADING.DAY").subscribe((res: string) => {
        this.daysString = res;
      });
      this.daysString = this.daysString;
      this.days = this.days;
    }
    if (daysDiff > 0 && daysDiff !== 1) {
      this.days = daysDiff;
      // this.daysString = (this.translate.get('HEADING.DAYS'));
      // this.daysString = this.daysString.value;
      this.translate.get("HEADING.DAYS").subscribe((res: string) => {
        this.daysString = res;
      });
      this.daysString = this.daysString;
    }
  }

  qtyValidation(qty) {
    this.errorStatus = this.commonServicesService.qtyValidation(this.shoppingCartData, qty);
  }

  stepValidation(left, _right, value) {
    this.shoppingCartData[value] = true;
    if (value === "isInitialStepCompleted") {
      this.shoppingCartData.isInitialStepCompleted = false;
      if (left > 0) {
        this.shoppingCartData.isInitialStepCompleted = true;
        // tslint:disable-next-line:radix
        this.sumOrderedQuantity = parseInt(left) + parseInt(this.shoppingCartData.ProductionLineOrderedQuantity);
      }
      if (this.shoppingCartData.FixedUnits === true) {
        left = left * this.shoppingCartData.PiecesPerUnit;
      }
      //  this.getTransportationhandlingcosts(left);
      if (this.shoppingCartData.MinimumOrder > left) {
        this.shoppingCartData.isInitialStepCompleted = false;
      }

      if (this.shoppingCartData.StockBalance != undefined && this.shoppingCartData.ProductColors != undefined && this.shoppingCartData.ProductColors.length != 0 && !this.shoppingCartData.isSampleWithoutLogo && !this.shoppingCartData.isSampleWithLogo) {
        this.shoppingCartData.ProductColorID = undefined;
        this.shoppingCartData.isColorStepCompleted = false;
        if (this.shoppingCartData.ProductColors[0].AvailableSizes != undefined && this.shoppingCartData.ProductColors[0].AvailableSizes.length != 0) {
          this.shoppingCartData.isSizesStepCompleted = false;
        }
      }
    }
  }

  getFileExtenstion(fileName) {
    return this.commonServicesService.getFileExtenstion(fileName);
  }

  propertiesCompleted() {
    this.shoppingCartData.isProductPropertiesStepCompleted = false;
    let i = 0;
    let count = 0; // new-code
    for (let j = 0; j < this.shoppingCartData.ProductProperties.length; j++) {
      if (this.shoppingCartData.ProductProperties[j].SelectedOptionID > 0) {
        count += 1;
        i = i + 1;
      } else {
        i = i - 1;
      }
    }
    i = Math.abs(i);
    if (i === this.shoppingCartData.ProductProperties.length) {
      this.shoppingCartData.isProductPropertiesStepCompleted = true;
    }
    if (count === 0) {
      this.shoppingCartData.isProductPropertiesStepCompleted = false;
    }

    /** new-code */
    if (this.lastTab === "productProperty") {
      // this.tabCompletedCount = this.tabCompletedCount + ;
      this.addOrUpdateShoppingCart("productProperty", false, false, "");
    }
    /** new-code */
  }

  logoCompleted($event, changeValue, updateShoppingCartStatus = false) {
    if (changeValue === "PrintPriceID") {
      this.isDoubleLogoOnSamePosition = false;
      this.dropDownDisable = false;
      for (let j = 0; j < this.shoppingCartData.ProductPrintPrices.length; j++) {
        if ($event === this.shoppingCartData.ProductPrintPrices[j].ProductPrintPriceID) {
          // Check if there is another logo with same LogoPostionID. If there is, do not allow adding one more logo on that same position
          if (this.shoppingCartData.ProductPrintPrices[j].LogoPositionID !== undefined && this.shoppingCartData.Logos.length > 1) {
            for (let i = 0; i < this.shoppingCartData.Logos.length; i++) {
              if (i !== this.currentLogoIndex) {
                // tslint:disable-next-line:max-line-length
                const test = this.shoppingCartData.ProductPrintPrices.filter((ppp) => ppp.ProductPrintPriceID == this.shoppingCartData.Logos[i].PrintPriceID);
                if (test.length > 0 && test[0].LogoPositionID == this.shoppingCartData.ProductPrintPrices[j].LogoPositionID) {
                  this.isDoubleLogoOnSamePosition = true;
                  this.shoppingCartData.Logos[this.currentLogoIndex].PrintPriceID = 0;

                  this.shoppingCartData.Logos[i]["LogoMaxAreaDescription"] = this.shoppingCartData.ProductPrintPrices[j].LogoMaxAreaDescription;
                  this.shoppingCartData.Logos[i]["LogoMaxAreaID"] = this.shoppingCartData.ProductPrintPrices[j].LogoMaxAreaID;
                  this.shoppingCartData.Logos[i]["LogoPositionDescription"] = this.shoppingCartData.ProductPrintPrices[j].LogoPositionDescription;
                  this.shoppingCartData.Logos[i]["LogoPositionID"] = this.shoppingCartData.ProductPrintPrices[j].LogoPositionID;
                  this.shoppingCartData.Logos[i]["LogoTypeID"] = this.shoppingCartData.ProductPrintPrices[j].LogoTypeID;
                  this.shoppingCartData.Logos[i]["LogoTypeName"] = this.shoppingCartData.ProductPrintPrices[j].LogoTypeName;
                  this.shoppingCartData.Logos[i]["NumberOfColors"] = this.shoppingCartData.ProductPrintPrices[j].NumberOfColors;
                  this.shoppingCartData.Logos[i]["PrintPriceDescription"] = this.shoppingCartData.ProductPrintPrices[j].Description;
                  this.shoppingCartData.Logos[i]["PrintPriceID"] = this.shoppingCartData.ProductPrintPrices[j].ProductPrintPriceID;
                  /** new-code => To overwrite branding price if user select from same Logo Branding Position */

                  this.shoppingCartData.Logos.splice(this.currentLogoIndex, 1);
                  this.logoCompleted(false, "", true);
                  return;
                }
              }
            }
          }
          this.shoppingCartData.Logos[this.currentLogoIndex].NumberOfColors = this.shoppingCartData.ProductPrintPrices[j].NumberOfColors;
          this.shoppingCartData.Logos[this.currentLogoIndex].DisableLogoColors = this.shoppingCartData.ProductPrintPrices[j].DisableLogoColors;
        }
      }
    }

    if ($event === true && changeValue === "isProceedWithoutLogo") {
      this.shoppingCartData.isProceedWithoutLogo = $event;
      this.shoppingCartData.isLogosStepCompleted = true;
    } else if ($event === false && changeValue === "isProceedWithoutLogo") {
      this.shoppingCartData.isProceedWithoutLogo = $event;
      this.shoppingCartData.isLogosStepCompleted = false;
    } else {
      this.shoppingCartData.isLogosStepCompleted = false;
      for (let i = 0; i < this.shoppingCartData.Logos.length; i++) {
        if ($event === true && changeValue === "isEmailLogoLater" && this.shoppingCartData.Logos[i].PrintPriceID > 0) {
          this.shoppingCartData.isLogosStepCompleted = true;
        } else if (this.shoppingCartData.Logos[i].PrintPriceID > 0 && !this.shoppingCartData.isFixedLogo) {
          this.shoppingCartData.isLogosStepCompleted = true;
        } else {
          this.shoppingCartData.isLogosStepCompleted = false;
          break;
        }
      }
    }

    if (this.shoppingCartData.isProceedWithoutLogo === true && changeValue === "") {
      this.shoppingCartData.isLogosStepCompleted = true;
    }
    /** new-code => Based on Logo is Fixed by default isLogosStepCompleted is true */
    if (this.shoppingCartData.isFixedLogo) {
      this.shoppingCartData.isLogosStepCompleted = true;
    }
    /** new-code => Based on Logo is Fixed by default isLogosStepCompleted is true */

    if (updateShoppingCartStatus) {
      this.addOrUpdateShoppingCart("checkout", false, false, "");
    }

    if (this.logoListModal !== undefined) {
      this.logoListModal.hide();
    }
    this.getTabCompletedCount();
  }

  setLogoData(Logo) {
    /*console.log("Set Logo Data ", Logo);
    console.log(this.isDoubleLogoOnSamePosition);*/
    if (!this.isDoubleLogoOnSamePosition) {
      Logo.OrderLogoColors = [];
      Logo.dropDownDisable = false;
    }
    //console.log("Set Logo Data Completed");
  }

  deleteLogo(i) {
    if (this.shoppingCartData.Logos.length > 1) {
      this.shoppingCartData.Logos.splice(i, 1);
      this.logoCompleted(false, "", true);
    } else {
      this.shoppingCartData.Logos = [];
      this.shoppingCartData.Logos.push({
        PrintPriceID: undefined,
        OrderLogoColors: this.orderLogoColors,
        isEmailLogoLater: false,
      });
      this.logoCompleted(false, "", true);
    }
  }

  applyLogoFilters(Logo) {
    var printPricesFiltered = Logo.ProductPrintPrices;

    if (Logo.LogoTypeID != "") {
      printPricesFiltered = printPricesFiltered.filter((ppp) => ppp.LogoTypeID == Logo.LogoTypeID);
    }
    if (Logo.LogoPositionID != "") {
      printPricesFiltered = printPricesFiltered.filter((ppp) => ppp.LogoPositionID == Logo.LogoPositionID || ppp.LogoPositionID == undefined);
    }
    if (Logo.LogoMaxAreaID != "") {
      printPricesFiltered = printPricesFiltered.filter((ppp) => ppp.LogoMaxAreaID == Logo.LogoMaxAreaID || ppp.LogoMaxAreaID == undefined);
    }
    if (Logo.PrintPriceID != "") {
      printPricesFiltered = printPricesFiltered.filter((ppp) => ppp.PrintPriceID == Logo.PrintPriceID || ppp.PrintPriceID == undefined);
    }

    //console.log("CHECK APPLy LOGO FILTERS : ", Logo);

    Logo.printPrices = printPricesFiltered;

    // Put filters back to original state (with all items in them)
    Logo.ProductLogoPositionsFiltered = [];
    Logo.ProductLogoPositions.forEach((element) => {
      Logo.ProductLogoPositionsFiltered.push(element);
    });
    Logo.ProductLogoTypesFiltered = [];
    Logo.ProductLogoTypes.forEach((element) => {
      Logo.ProductLogoTypesFiltered.push(element);
    });
    Logo.ProductLogoMaxAreasFiltered = [];
    Logo.ProductLogoMaxAreas.forEach((element) => {
      Logo.ProductLogoMaxAreasFiltered.push(element);
    });

    // Disable Logo Positions which would result in 0 print prices
    Logo.ProductLogoPositionsFiltered.forEach((element) => {
      if (Logo.ProductPrintPrices.filter((ppp) => (ppp.LogoPositionID == element.LogoPositionID || ppp.LogoPositionID == undefined) && (Logo.LogoTypeID == "" || ppp.LogoTypeID == Logo.LogoTypeID) && (Logo.LogoMaxAreaID == "" || ppp.LogoMaxAreaID == Logo.LogoMaxAreaID || ppp.LogoMaxAreaID == undefined)).length == 0) {
        element.isDisabled = true;
      }
      else { element.isDisabled = false; }
    });
    // Enabled Logo Positions should be shown before disabled ones (default Logo Position should be first among enabled or disabled ones)
    Logo.ProductLogoPositionsFiltered.sort(function (x, y) {
      return x.isDisabled === y.isDisabled ? x.isDefault ? -2 : 0 : x.isDisabled ? 1 : -1;
    });

    // Disable Logo Types which would result in 0 print prices
    Logo.ProductLogoTypesFiltered.forEach((element) => {
      if (Logo.ProductPrintPrices.filter((ppp) => ppp.LogoTypeID == element.LogoTypeID && (Logo.LogoPositionID == "" || ppp.LogoPositionID == Logo.LogoPositionID || ppp.LogoPositionID == undefined) && (Logo.LogoMaxAreaID == "" || ppp.LogoMaxAreaID == Logo.LogoMaxAreaID || ppp.LogoMaxAreaID == undefined)).length == 0) {
        element.isDisabled = true;
      }
      else { element.isDisabled = false; }
    });
    // Enabled Logo Types should be shown before disabled ones (default Logo Type should be first among enabled or disabled ones)
    Logo.ProductLogoTypesFiltered.sort(function (x, y) {
      return x.isDisabled === y.isDisabled ? x.isDefault ? -2 : 0 : x.isDisabled ? 1 : -1;
    });

    // Disable Logo Areas which would result in 0 print prices
    Logo.ProductLogoMaxAreasFiltered.forEach((element) => {
      if (Logo.ProductPrintPrices.filter((ppp) => (ppp.LogoMaxAreaID == element.LogoMaxAreaID || ppp.LogoMaxAreaID == undefined) && (Logo.LogoPositionID == "" || ppp.LogoPositionID == Logo.LogoPositionID || ppp.LogoPositionID == undefined) && (Logo.LogoTypeID == "" || ppp.LogoTypeID == Logo.LogoTypeID)).length == 0) {
        element.isDisabled = true;
      }
      else { element.isDisabled = false; }
    });
    // Enabled Logo Areas should be shown before disabled ones
    Logo.ProductLogoMaxAreasFiltered.sort(function (x, y) {
      return x.isDisabled === y.isDisabled ? 0 : x.isDisabled ? 1 : -1;
    });

    if (Logo.LogoPositionID != "") {
      var LogoImage = [];
      //console.log("Here Shopping cart Data : ", this.shoppingCartData);
      if (this.shoppingCartData.ProductImagesForLogoPositions !== undefined && this.shoppingCartData.ProductImagesForLogoPositions.length > 0) {
        LogoImage = this.shoppingCartData.ProductImagesForLogoPositions.filter((image) => image.LogoPositionID == Logo.LogoPositionID);
      }
      if (this.shoppingCartData.ProductImages !== undefined) {
        if (LogoImage.length == 0)
          LogoImage = this.shoppingCartData.ProductImages.filter((image) => image.LogoPositionID == Logo.LogoPositionID);
      }
      if (LogoImage.length > 0) {
        var colorImgFound = false;
        if (this.shoppingCartData.ProductColorID != undefined) {
          var ColorLogoImage = LogoImage.filter((image) => image.ProductColorID == this.shoppingCartData.ProductColorID);
          if (ColorLogoImage.length > 0) {
            colorImgFound = true;
            Logo.LogoPositionImageURL = ColorLogoImage[0].ImageURL;
          }
        }
        if (!colorImgFound) {
          Logo.LogoPositionImageURL = LogoImage[0].ImageURL;
        }
      } else {
        Logo.LogoPositionImageURL = null;
      }
    } else {
      Logo.LogoPositionImageURL = null;
    }
  }

  selectCheapestBranding(Logo, logoIndex) {
    if (Logo.ProductPrintPrices.length > 0) {
      var printPricesSorted = [];
      Logo.ProductPrintPrices.forEach((element1) => {
        printPricesSorted.push(element1);
      });

      printPricesSorted = printPricesSorted.sort(
        (n1, n2) => n1.Price - n2.Price
      );
      var minPPP = printPricesSorted[0];

      Logo.printPrices = printPricesSorted.filter(
        (ppp) => ppp.ProductPrintPriceID == minPPP.ProductPrintPriceID
      );

      // Select cheapest logo
      Logo.PrintPriceID = minPPP.ProductPrintPriceID;
      this.currentLogoIndex = logoIndex;
      this.logoCompleted(minPPP.ProductPrintPriceID, "PrintPriceID", true);

      if (!this.isDoubleLogoOnSamePosition) {
        Logo.OrderLogoColors = [];
        Logo.dropDownDisable = false;

        // Put filters back to original state (with all items in them)
        Logo.ProductLogoPositionsFiltered = [];
        Logo.ProductLogoPositions.forEach((element) => {
          Logo.ProductLogoPositionsFiltered.push(element);
        });
        Logo.ProductLogoTypesFiltered = [];
        Logo.ProductLogoTypes.forEach((element) => {
          Logo.ProductLogoTypesFiltered.push(element);
        });
        Logo.ProductLogoMaxAreasFiltered = [];
        Logo.ProductLogoMaxAreas.forEach((element) => {
          Logo.ProductLogoMaxAreasFiltered.push(element);
        });

        // Enable all items in filters
        Logo.ProductLogoPositionsFiltered.forEach((element) => {
          element.isDisabled = false;
        });
        Logo.ProductLogoPositionsFiltered.sort(function (x) {
          return x.isDefault ? -1 : 0;
        });
        Logo.ProductLogoTypesFiltered.forEach((element) => {
          element.isDisabled = false;
        });
        Logo.ProductLogoTypesFiltered.sort(function (x) {
          return x.isDefault ? -1 : 0;
        });
        Logo.ProductLogoMaxAreasFiltered.forEach((element) => {
          element.isDisabled = false;
        });

        // Show positional image of cheapest logo (if any)
        if (minPPP.LogoPositionID != undefined) {
          var LogoImage = this.shoppingCartData.ProductImagesForLogoPositions.filter((image) => image.LogoPositionID == minPPP.LogoPositionID);
          if (LogoImage.length == 0)
            LogoImage = this.shoppingCartData.ProductImages.filter((image) => image.LogoPositionID == minPPP.LogoPositionID);
          if (LogoImage.length > 0) {
            var colorImgFound = false;
            if (this.shoppingCartData.ProductColorID != undefined) {
              var ColorLogoImage = LogoImage.filter((image) => image.ProductColorID == this.shoppingCartData.ProductColorID);
              if (ColorLogoImage.length > 0) {
                colorImgFound = true;
                setTimeout(() => {
                  this.shoppingCartData.Logos[logoIndex].LogoPositionImageURL = ColorLogoImage[0].ImageURL;
                }, 1000);
              }
            }
            if (!colorImgFound) {
              setTimeout(() => {
                this.shoppingCartData.Logos[logoIndex].LogoPositionImageURL = LogoImage[0].ImageURL;
              }, 1000);
            }
          } else {
            this.shoppingCartData.Logos[logoIndex].LogoPositionImageURL = null;
          }
        }
      }
    }
  }

  openPosImage(imageURL) {
    this.posImages = [];
    const imageList = {
      url: imageURL,
      extUrl: imageURL,
      thumbnailUrl: imageURL,
    };
    this.posImages.push(imageList);
    setTimeout(() => {
      this.ngxPosImageGallery.open();
    }, 1);
  }

  getTransportationhandlingcosts(orderedQuantity) {
    if (this.shoppingCartData.isInternalStockProduct && this.productDetail !== undefined && !this.productDetail.isTransportationHandlingCostFree) {
      this._httpProductService.getTransportationhandlingcosts({ productID: this.shoppingCartData.ProductID, quantity: orderedQuantity }).subscribe((data) => {
        this.transportationhandlingcosts = data;
      });
    }
  }

  /** For Show Color Section */
  selectedColor(color, k, tabName, lowStockPopupStatus = false) {
    /*console.log("Color Name : ", color);

    console.log("tabName Value : " + tabName);
    console.log("K value : " + k);*/
    /** new-code => shop-app-product-batch-colors */
    if (color === null && k === 0) {
      if (this.shoppingCartData !== "") {
        if (this.shoppingCartData.ProductColors !== "") {
          if (this.selectColorId !== null) {
            let colorIndex = this.shoppingCartData.ProductColors.findIndex((data) =>
              Number(data.ProductColorID) === Number(this.selectColorId)
            );
            /** new-code => based on product parent color id */
            if (colorIndex < 0) {
              colorIndex = this.shoppingCartData.ProductColors.findIndex((data) => Number(data.ParentColorID) === Number(this.selectColorId));
              if (colorIndex >= 0) {
                this.selectColorId = this.shoppingCartData.ProductColors[colorIndex].ParentColorID;
                this.shoppingCartData.ProductColors[colorIndex].isSelected = true;
              }
            }
            //console.log("this.shoppingCartData.ProductColors : ", this.shoppingCartData.ProductColors);
            /** new-code => based on product parent color id */
            color = this.shoppingCartData.ProductColors[colorIndex];
            k = colorIndex;
          }
        }
      }
    }
    /** new-code => shop-app-product-batch-colors */
    //console.log("k Value : " + k);
    //console.log("lowStockPopupStatus : ", lowStockPopupStatus);
    if ((this.shoppingCartItemId !== "" && this.shoppingCartItemId !== undefined && this.shoppingCartItemId !== null && this.shoppingCartData.isVisible) || (lowStockPopupStatus && this.shoppingCartItemId !== "" && this.shoppingCartItemId !== undefined && this.shoppingCartItemId !== null)) {
      this.shoppingCartData.isColorStepCompleted = false;
      if (color.AvailableSizes !== undefined && color.AvailableSizes.length !== 0) {
        this.shoppingCartData.isSizesStepCompleted = false;
      }
      // tslint:disable-next-line:max-line-length
      if (this.shoppingCartData.ProductColors[k].StockBalance + this.shoppingCartData.ProductColors[k].TotalFutureStockQuantity < Number(this.shoppingCartData.OrderedQuantity) && this.shoppingCartData.StockBalance !== undefined && !this.shoppingCartData.isSampleWithLogo && !this.shoppingCartData.isSampleWithoutLogo) {
        //console.log("Inside first condition");
        if (Number(this.shoppingCartData.ProductColors[k].TotalFutureStockQuantity) === 0) {
          //console.log("Inside case 1 condition");
          this.case1 = true;
          this.case2 = false;
          this.case3 = false;
          this.selectColorId = 0;
        } else {
          this.case1 = false;
          this.case2 = false;
          this.case3 = false;
        }
        if (this.productDetail.isInternalStockProduct === true) {
          this.isShowinquirybutton = true;
        } else {
          this.isShowinquirybutton = false;
        }
        this.stockModalTranslate();
        this.shoppingCartStockModal.show();
        /** new-code */
        if (this.selectColorId !== undefined && this.selectColorId !== null && this.selectColorId > 0) {
          this.shoppingCartData.isColorStepCompleted = true;
        }
        /** new-code */
      } else {
        this.shoppingCartData.ProductColorID = color.ProductColorID;
        this.selectColorId = color.ProductColorID;
        if (this.lastTab === "color") {
          this.shoppingCartData.isChecked = true;
        }
        this.productSizesindex = k;
        if (color.AvailableSizes.ProductID > 0) {
          this.shoppingCartData.ProductID = color.ProductID;
        }
        this.productSizeCurrentQty = 0;
        this.currentTab = tabName;

        if (this.shoppingCartData.ProductColors[k].StockBalance < Number(this.shoppingCartData.OrderedQuantity) && this.shoppingCartData.StockBalance !== undefined && !this.shoppingCartData.isSampleWithLogo && !this.shoppingCartData.isSampleWithoutLogo) {
          this.getcalculatedArray(true, true, "color");
        } else {
          if (this.lastTab === this.currentTab) {
            this.shoppingCartData.isColorStepCompleted = true;
            this.addOrUpdateShoppingCart("", false, false, "");
          }
          this.stepValidation(this.shoppingCartData.OrderedQuantity, color.StockBalance, "isColorStepCompleted");
        }
      }
    }
  }

  /** For Show Branding Price Section */
  selectedBranding(logoData) {
    //console.log("selectedBranding : ", logoData);
    setTimeout(() => {
      if (this.shoppingCartItemId !== "" && this.shoppingCartItemId !== undefined && this.shoppingCartItemId !== null) {
        const logoLength = this.shoppingCartData.Logos.length;
        /** for logo add */
        if (logoData.brandSelectStatus) {
          if (this.shoppingCartData.Logos.length === 1 && this.shoppingCartData.Logos[0]["PrintPriceID"] === undefined) {
            this.currentLogoIndex = 0;
          } else {
            this.currentLogoIndex = logoLength;
            let addMoreLogoStatus = true;
            for (let i = 0; i < this.shoppingCartData.Logos.length; i++) {
              if (this.shoppingCartData.Logos[i]["PrintPriceID"] === undefined) {
                addMoreLogoStatus = false;
                this.currentLogoIndex = this.currentLogoIndex - 1;
              }
            }
            if (addMoreLogoStatus) {
              this.addMoreLogo(false);
            }
          }
          setTimeout(() => {
            this.shoppingCartData.Logos[this.currentLogoIndex]["PrintPriceID"] = logoData.PrintPriceID;
            this.shoppingCartData.Logos[this.currentLogoIndex]["LogoTypeID"] = logoData.LogoTypeID;
            this.shoppingCartData.Logos[this.currentLogoIndex]["LogoTypeName"] = logoData.LogoTypeName;
            this.shoppingCartData.Logos[this.currentLogoIndex]["PrintPriceDescription"] = logoData.PrintPriceDescription;
            this.shoppingCartData.Logos[this.currentLogoIndex]["LogoPositionID"] = logoData.LogoPositionID;
            this.shoppingCartData.Logos[this.currentLogoIndex]["LogoPositionDescription"] = logoData.LogoPositionDescription;
            this.shoppingCartData.Logos[this.currentLogoIndex]["LogoMaxAreaID"] = logoData.LogoMaxAreaID;
            this.shoppingCartData.Logos[this.currentLogoIndex]["LogoMaxAreaDescription"] = logoData.LogoMaxAreaDescription;
            this.shoppingCartData.Logos[this.currentLogoIndex]["isEmailLogoLater"] = logoData.isEmailLogoLater;
            this.shoppingCartData.Logos[this.currentLogoIndex]["DisableLogoColors"] = logoData.DisableLogoColors;
            this.shoppingCartData.Logos[this.currentLogoIndex]["OrderLogoColors"] = logoData.OrderLogoColors;
            this.shoppingCartData.Logos[this.currentLogoIndex]["NumberOfColors"] = logoData.NumberOfColors;
            this.setLogoData(logoData);
            this.logoCompleted(logoData.PrintPriceID, "PrintPriceID", true);
          }, 100);
        } else {
          /** for logo Delete */
          //console.log(this.shoppingCartData.Logos);
          if (this.shoppingCartData.Logos[0]["PrintPriceID"] !== undefined) {
            for (let i = 0; i < this.shoppingCartData.Logos.length; i++) {
              if (this.shoppingCartData.Logos[i].PrintPriceID === logoData.PrintPriceID) {
                //  if (i !== 0) {
                this.deleteLogo(i);
                //  }
              }
            }
          }
        }
      } else {
        // alert("Yes Else");
      }
    }, 50);
  }

  joinOrderButtonClickFunc($event) {
    this.joinOrderButtonClick = true;
    this.autoCallShoppingCartAPIStatus = false;
    $event.joinOrderButtonClickStatus = true;
    this.shoppingCartAddItem($event, 1, "joinorder", false);
  }

  newOrderButtonClickFunc($event) {
    this.newOrderButtonClick = true;
    this.autoCallShoppingCartAPIStatus = false;
    $event.newOrderButtonClickStatus = true;
    this.shoppingCartAddItem($event, 1, "neworder", false);
  }

  // tslint:disable-next-line:max-line-length
  addOrUpdateShoppingCart(step, isFutureStock, isMultipleCall, _status, tabname = "", checkoutPageNavigationStatus = false, proceedNext = true) {

    if (!this.isParentCustomer) {
      let a = this.commonServicesService.getPoints().subscribe((data) => {
        this.balancePoints = data;
      });

      a.unsubscribe();
      if (this.balancePoints <= 0) {
        this.toastr.error("You don't have points to select a product");
        return false;
      }
    }

    this.shoppingCartData.OrderedQuantity = this.orderQuantityParent;
    if (this.FileName !== undefined && this.FileName !== null) {
      this.shoppingCartData.FileName = this.FileName;
    }
    if (this.shoppingCartData.FileName == "") {
      this.shoppingCartData.FileNameCompletePath = "";
    }
    if (this.productDetail.FixedUnits) {
      this.shoppingCartData.OrderedQuantity = this.productDetail.PiecesPerUnit * this.orderQuantityParent;
      if (this.joinOrderProductStatus) {
        this.shoppingCartData.OrderedQuantity = this.productDetail.PiecesPerUnit * Number(this.orderQuantityParent);
      }
    }
    /*console.log("addOrUpdateShoppingCart : currentTabName : " + tabname);
    console.log("addOrUpdateShoppingCart : step : " + step);
    console.log("addOrUpdateShoppingCart : _status : " + _status);*/
    // code is for Add to Cart showing in Common Price Section
    if (tabname !== "") {
      this.currentTabName = tabname;
    }
    if ((step === "initial" || step === "checkout" || step === "productDetail" || step === "home") && !this.isQuantityValid) {
      return;
    }
    if (_status === "checkcolor") {
      if (this.shoppingCartData.ProductColors.length === 1) {
        this.selectedColor(this.shoppingCartData.ProductColors[0], 0, "color");
      } else if (!this.shoppingCartData.isDisabled && this.shoppingCartData.ProductColorID !== undefined && this.shoppingCartData.StockBalance !== undefined && !this.shoppingCartData.isSampleWithLogo && !this.shoppingCartData.isSampleWithoutLogo) {
        this.getcalculatedArray(true, true, "color");
      }
    }

    if (_status === "property" && this.shoppingCartData.ProductProperties && this.shoppingCartData.ProductProperties.length === 1 && this.shoppingCartData.ProductProperties[0].ProductOptions && this.shoppingCartData.ProductProperties[0].ProductOptions.length > 0) {
      // this.propertiesCompleted();   // old-code
      if (this.shoppingCartData.ProductProperties[0].SelectedOptionID === undefined) {
        // this.shoppingCartData.ProductProperties[0].SelectedOptionID =
        //   this.shoppingCartData.ProductProperties[0].ProductOptions[0].ProductOptionID;
        // this.propertiesCompleted();  // new-code
        this.shoppingCartData.ProductProperties[0].SelectedOptionID = "";
        this.propertiesCompleted();
      }
    }

    if (_status === "checkcost" && this.shoppingCartData.AdditionalCosts && this.shoppingCartData.AdditionalCosts.length === 1) {
      if (!this.shoppingCartData.AdditionalCosts[0].isOptionalForBuyer) {
        this.shoppingCartData.AdditionalCosts[0].isSelected = true;
        if (this.shoppingCartData.AdditionalCosts[0].AdditionalCostOptions && this.shoppingCartData.AdditionalCosts[0].AdditionalCostOptions.length > 0) {
          this.shoppingCartData.AdditionalCosts[0].SelectedOptionID = this.shoppingCartData.AdditionalCosts[0].AdditionalCostOptions[0].ProductAdditionalCostOptionID;
        }
      }
    }

    if (_status === "checksize" && this.shoppingCartData.ProductColors && this.shoppingCartData.ProductColors.length === 0 && this.shoppingCartData.ProductSizes && this.shoppingCartData.ProductSizes.length === 1) {
      this.shoppingCartData.ProductSizes[0].OrderedQuantity = this.shoppingCartData.OrderedQuantity;
    }

    if (_status === "checksize" && this.shoppingCartData.ProductColors && this.shoppingCartData.ProductColors.length > 0 && this.shoppingCartData.ProductColors.AvailableSizes && this.shoppingCartData.ProductColors.AvailableSizes.length === 1) {
      this.shoppingCartData.ProductSizes[0].AvailableSizes[0].OrderedQuantity = this.shoppingCartData.OrderedQuantity;
    }
    this.getTabCompletedCount();

    delete this.responseData.Items;
    if (this.shoppingCartDeliveryDate !== undefined) {
      this.shoppingCartData.DeliveryDate = this.shoppingCartDeliveryDate;
    }
    /** new-code */
    if (new Date(this.shoppingCartData.DeliveryDate) === new Date(this.shoppingCartDeliveryDate) || this.shoppingCartDeliveryDate === undefined || this.shoppingCartDeliveryDate === null || this.shoppingCartDeliveryDate === "") {
      //  this.shoppingCartData.DeliveryDate = this.shoppingCartDeliveryDate;
    }

    if (this.expressOrderStatus !== null) {
      this.shoppingCartData.isExpressOrder = this.expressOrderStatus;
    }
    /** new-code */
    //console.log("Lorem this.shoppingCartData : ", this.shoppingCartData);
    this.responseData.Item = this.shoppingCartData;

    /** new-code => For tab Issue Fix code */
    if (this.responseData["Item"]["Item"] !== undefined && this.responseData["Item"]["Item"] !== null) {
      this.spinnerShow = false;
      delete this.responseData["Item"]["Item"];
      if (this.shoppingCartData.Item !== undefined && this.shoppingCartData.Item !== null) {
        delete this.shoppingCartData.Item;
      }
      return false;
    }
    /** new-code => For tab Issue Fix code */

    for (let i; i < this.responseData.Item.Logos.length; i++) {
      for (let j; j < this.responseData.Item.Logos[i].OrderLogoColors.length; j++) {
        this.responseData.Item.Logos[i].OrderLogoColors[j] = { color: this.responseData.Item.Logos[i].OrderLogoColors[j] };
      }
    }

    if (this.shoppingCartData.ShoppingCartItemID === "") {
      //  this.responseData['Item'].ShoppingCartItemID = this.shoppingCartData.RelatedItemID;
    }
    if (this.responseData["Item"].RelatedItemID !== undefined) {
    } else {
      this.responseData["Item"].RelatedItemID = "";
    }
    if (isFutureStock) {
      if (new Date(this.param.DeliveryDate) < new Date(this.shoppingCartDeliveryDate)) {
        this.shoppingCartData.DeliveryDate = this.shoppingCartDeliveryDate;
        this.responseData["Item"].DeliveryDate = this.shoppingCartDeliveryDate;
      } else {
        this.shoppingCartData.DeliveryDate = this.param.DeliveryDate;
        this.shoppingCartDeliveryDate = this.param.DeliveryDate;
      }
      //console.log('this.param', this.param)
      this.responseData["Item"].OrderedQuantity = this.param.OrderQty;
      this.responseData["Item"].isDisabled = true;
      this.responseData["Item"].RelatedItemID = "";
      if (isMultipleCall) {
        this.responseData["Item"].RelatedItemID = this.responseData["Item"].ShoppingCartItemID;
        this.responseData["Item"].ShoppingCartItemID = "";
        const userSettings = this.globalService.getLoggedInData();
        if (userSettings !== null) {
          this.responseData["Item"].UserID = userSettings.UserID;
        } else {
          this.responseData["Item"].UserBrowserID = this.responseData.UserBrowserID;
        }
      }
      this.futureStockModal.hide();
    }
    if (isMultipleCall) {
      if (this.responseData["Item"].ProductColors && this.responseData["Item"].ProductColors.length !== 0) {
        this.responseData["Item"].ProductColors.forEach((elements) => {
          elements.AvailableSizes.forEach((element) => {
            if (element.SelectedDeliveryDate !== undefined) {
              if (isMultipleCall) {
                if (element.StockBalance !== 0) {
                  const set = Math.abs(Number(element.OrderedQuantity) - element.StockBalance);
                  //console.log(set)
                  element.OrderedQuantity = set;
                } else {
                  element.OrderedQuantity = element.OrderedQuantity;
                }
              } else {
                if (element.StockBalance !== 0) {
                  element.OrderedQuantity = element.StockBalance;
                } else {
                  element.OrderedQuantity = 0;
                }
              }
            } else {
              if (!isMultipleCall && element.OrderedQuantity !== undefined) {
                element.OrderedQuantity = element.OrderedQuantity;
              } else if (isMultipleCall && element.OrderedQuantity !== undefined) {
                element.OrderedQuantity = 0;
              }
            }
          });
        });
      }
      if (this.responseData["Item"].ProductColors && this.responseData["Item"].ProductColors.length === 0 && this.responseData["Item"].ProductSizes && this.responseData["Item"].ProductSizes.length !== 0) {
        this.responseData["Item"].ProductSizes.forEach((element) => {
          if (element.SelectedDeliveryDate !== undefined) {
            if (isMultipleCall) {
              if (element.StockBalance !== 0) {
                const set = Math.abs(Number(element.OrderedQuantity) - element.StockBalance);
                element.OrderedQuantity = set;
              } else {
                element.OrderedQuantity = element.OrderedQuantity;
              }
            } else {
              if (element.StockBalance !== 0) {
                element.OrderedQuantity = element.StockBalance;
              } else {
                element.OrderedQuantity = 0;
              }
            }
          } else {
            if (!isMultipleCall && element.OrderedQuantity !== undefined) {
              element.OrderedQuantity = element.OrderedQuantity;
            } else if (isMultipleCall && element.OrderedQuantity !== undefined) {
              element.OrderedQuantity = 0;
            }
          }
        });
      }
      //    this.menuRightComponent.ngOnInit();
      //   this.router.navigate([this.router.url.split('?')[0]], { preserveQueryParams: true, replaceUrl: false });
    }

    const shoppingCartDatas = {};
    shoppingCartDatas["Items"] = [];
    shoppingCartDatas["Items"].push(this.responseData.Item);
    if (this.commonServicesService.readyForCheckout(shoppingCartDatas, false)) {
      this.responseData.Item.isChecked = sessionStorage.getItem("newOrderData") !== undefined && sessionStorage.getItem("newOrderData") !== null ? false : true;
      this.responseData.Item.isVisible = sessionStorage.getItem("newOrderData") !== undefined && sessionStorage.getItem("newOrderData") !== null ? false : true; // new-code for invisible of shopping cart items
    }
    if (this.shoppingCartData.isProceedWithoutLogo && step === "checkout") {
      // this.responseData.Item.Logos = [];
      this.responseData.Item.Logos = [{ isEmailLogoLater: false, OrderLogoColors: [] }];
    }
    if (this.addOrUpdateShoppingCartStatus) {
      this.spinnerShow = true;

      /** new-code => To show/hide Product in Shopping Cart Item */
      let productVisibleStatus = true;
      if ((sessionStorage.getItem("joinOrderData") !== null || sessionStorage.getItem("newOrderData") !== null) && !this.newOrderButtonClick && !this.joinOrderButtonClick) {
        productVisibleStatus = false;
      }
      this.responseData.Item.isVisible = productVisibleStatus;
      this.responseData.Item.isChecked = productVisibleStatus;
      /** new-code => To show/hide Product in Shopping Cart Item */

      // alert(this.newOrderButtonClick);
      // this.spinnerShow = false;
      // return false;
      //console.log("Shopping cart data : ", this.shoppingCartData);
      //console.log("responseData cart data : ", this.responseData);
      if (this.joinOrderButtonClick) {
        let responseData1 = this.responseData;
        this.joinOrderProductStatus = true;
        const sessionJoinOrderData = JSON.parse(sessionStorage.getItem("joinOrderData"));
        if (sessionJoinOrderData !== null && sessionJoinOrderData !== undefined) {
          let totalWithoutVAT = sessionJoinOrderData.TotalWithoutVAT;
          let GrandTotal = sessionJoinOrderData.GrandTotal;
          let DeliveryDate = sessionJoinOrderData["Item"].DeliveryDate;
          let joinOrderShoppingCartItemID = sessionJoinOrderData["Item"].ShoppingCartItemID;
          let priceDeliveryDate = sessionJoinOrderData["Item"].Price.DeliveryDate;
          let priceProductionLineID = sessionJoinOrderData["Item"].Price.ProductionLineID;
          let priceProductPrice = sessionJoinOrderData["Item"].Price.ProductPrice;
          let priceShoppingCartItemID = sessionJoinOrderData["Item"].Price.ShoppingCartItemID;
          let priceTotalProductPrice = sessionJoinOrderData["Item"].Price.TotalProductPrice;
          let priceTotalWithoutTransportationCost = sessionJoinOrderData["Item"].Price.TotalWithoutTransportationCost;
          let ProductionLineID = sessionJoinOrderData["Item"].ProductionLineID;
          let productionLineOrderedQuantity = sessionJoinOrderData["Item"].ProductionLineOrderedQuantity;
          let productPrices = sessionJoinOrderData["Item"].ProductPrices;
          let productPrintPrices = sessionJoinOrderData["Item"].ProductPrintPrices;
          let totalAdditionalCostsPrice = sessionJoinOrderData["Item"].Price.TotalAdditionalCostsPrice;
          let totalGovernmentalCostsPrice = sessionJoinOrderData["Item"].Price.TotalGovernmentalCostsPrice;
          let totalLogosPrice = sessionJoinOrderData["Item"].Price.TotalLogosPrice;
          let totalProductPrice = sessionJoinOrderData["Item"].Price.TotalProductPrice;
          let totalQuantity = sessionJoinOrderData["Item"].Price.TotalQuantity;
          let totalWithoutTransportationCost = sessionJoinOrderData["Item"].Price.TotalWithoutTransportationCost;

          let priceLogo = sessionJoinOrderData["Item"].Price.Logos;
          let logoData = sessionJoinOrderData["Item"].Logos;

          responseData1["TotalWithoutVAT"] = totalWithoutVAT;
          responseData1["GrandTotal"] = totalWithoutVAT;
          responseData1["ShoppingCartItemID"] = joinOrderShoppingCartItemID;
          responseData1["Item"].DeliveryDate = priceDeliveryDate;
          responseData1["Item"].ShoppingCartItemID = joinOrderShoppingCartItemID;
          responseData1["Item"].Price.DeliveryDate = priceDeliveryDate;
          responseData1["Item"].Price.ProductionLineID = priceProductionLineID;
          responseData1["Item"].Price.ProductPrice = priceProductPrice;
          responseData1["Item"].Price.ShoppingCartItemID = priceShoppingCartItemID;
          // responseData1['Item'].Price.TotalProductPrice = priceTotalProductPrice;
          // responseData1['Item'].Price.TotalWithoutTransportationCost = priceTotalWithoutTransportationCost;
          responseData1["Item"].ProductionLineID = ProductionLineID;
          responseData1["Item"].ProductionLineOrderedQuantity = productionLineOrderedQuantity;
          responseData1["Item"].ProductPrices = productPrices;
          responseData1["Item"].ProductPrintPrices = productPrintPrices;
          responseData1["Item"].OrderedQuantity = Number(responseData1["Item"].OrderedQuantity);
          responseData1["Item"].Price.TotalAdditionalCostsPrice = totalAdditionalCostsPrice;
          responseData1["Item"].Price.TotalGovernmentalCostsPrice = totalGovernmentalCostsPrice;
          responseData1["Item"].Price.TotalLogosPrice = totalLogosPrice;
          responseData1["Item"].Price.TotalProductPrice = totalProductPrice;
          responseData1["Item"].Price.TotalQuantity = totalQuantity;
          responseData1["Item"].Price.TotalWithoutTransportationCost = totalWithoutTransportationCost;
          responseData1["Item"].Price.OrderedQuantity =  responseData1["Item"].OrderedQuantity;
          responseData1["Item"].Price.TotalQuantity =  responseData1["Item"].OrderedQuantity;
          responseData1["Item"].isJoinOrder = true;
          responseData1["Item"].isFixedLogo = sessionJoinOrderData["Item"].isFixedLogo;
          if (sessionJoinOrderData["Item"].isFixedLogo === true) {
            responseData1["Item"].isLogosStepCompleted = true;
          }
          responseData1["Item"].Price.Logos = priceLogo;
          responseData1["Item"].Logos = logoData;

          /** Overwrite values to JoinOrder Data Based on changes in new order Data(Shopping Cart Item)(ex.,Size tab Changes) */
        }
        this.responseData = responseData1;
        //console.log("this.responseData JOIN ORDER INSIDE : ", this.responseData);
      }

      if (this.responseData["Item"]["isJoinOrder"]) {
        this.responseData["Item"]["DeliveryDate"] = this.responseData["Item"]["Price"]["DeliveryDate"];
      }

      //console.log("this.responseData.Item.Logos : ", this.responseData.Item.Logos);
      //console.log("New Order in Request : ", this.responseData);
      if (step === null) {
        this.spinnerShow = false;
        return false;
      }
      this._httpWebshopService.addShoppingCart(this.responseData).subscribe((data) => {
        //console.log(this.responseData)

        /** Replace Shoppingcart data printprice values based on Join order API response for
         * Fixed Logo products
         */
        // tslint:disable-next-line:max-line-length
        if (!this.newOrderButtonClick && !this.joinOrderButtonClick && !this.responseData.Item.isVisible) {
          if (this.joinOrderShoppingCartData !== undefined && this.joinOrderShoppingCartData !== null && this.joinOrderShoppingCartData !== "") {
            //console.log(this.joinOrderShoppingCartData);
            if (this.joinOrderShoppingCartData.Item.ProductPrintPrices !== undefined && this.joinOrderShoppingCartData.Item.ProductPrintPrices !== null) {
              if (this.joinOrderShoppingCartData.Item.isFixedLogo) {
                // console.log("Inside Fixed Logo Condition 2356");
                if (data["Item"]["ProductPrintPrices"] !== undefined && data["Item"]["ProductPrintPrices"] !== null) {
                  data["Item"]["ProductPrintPrices"] = this.joinOrderShoppingCartData.Item.ProductPrintPrices;
                }
              }
            }
          }
        }

        this.shoppingCartData = data["Item"];

        sessionStorage.setItem("cartData", JSON.stringify(data));

        if (this.responseData.Item.isVisible && (this.newOrderButtonClick || this.joinOrderButtonClick)) {
          this.fixedLogoProductPrintPrices = [];
          if (sessionStorage.getItem("newOrderData") !== undefined && sessionStorage.getItem("newOrderData") !== null) {
            this.translate.get("PRODUCT_DETAIL.PRODUCT_SAVE_SHOPPING_CART").subscribe((res: string) => {
              this.translatedMessage = res;
            });
            this.toastr.success(this.translatedMessage);
            step = "loadStartTab"; // after procudt add into cart item. tab shoulb be still start tab
          }
        }

        /** new-code */
        if (!data["Item"]["isJoinOrder"]) {
          this.displayCommonPrice(data["Item"], data["CurrencyCode"]);
        } else {
          this.joinOrderShoppingCartData = data;
          this.displayJoinOrderCommonPrice(data["Item"], data["CurrencyCode"]);
        }
        if (this.joinOrderButtonClick) {
          if (sessionStorage.getItem("joinOrderData") !== undefined && sessionStorage.getItem("joinOrderData") !== null) {
            sessionStorage.removeItem("joinOrderData");
          }
          if (sessionStorage.getItem("joinOrderId") !== undefined && sessionStorage.getItem("joinOrderId") !== null) {
            sessionStorage.removeItem("joinOrderId");
          }
          if (sessionStorage.getItem("newOrderData") !== undefined && sessionStorage.getItem("newOrderData") !== null) {
            sessionStorage.removeItem("newOrderData");
          }
          if (sessionStorage.getItem("newOrderId") !== undefined && sessionStorage.getItem("newOrderId") !== null) {
            sessionStorage.removeItem("newOrderId");
          }
          window.history.replaceState(" ", "", "/productdetail/" + data["Item"].ProductID + "/" + data["Item"].ProductName + "/" + data["Item"].ShoppingCartItemID);
          // window.location.reload();
        }
        /** new-code */

        //console.log("Resposne addShoppingCart Data : ", data);
        /** new-code => If product have join orders */
        // if (!this.responseData.isVisible) {
        if (!this.responseData.Item.isVisible) {
          let responseData1 = this.responseData;
          const sessionJoinOrderData = JSON.parse(sessionStorage.getItem("joinOrderData"));

          if (sessionJoinOrderData !== null && sessionJoinOrderData !== undefined) {
            let totalWithoutVAT = sessionJoinOrderData.TotalWithoutVAT;
            let GrandTotal = sessionJoinOrderData.GrandTotal;
            let DeliveryDate = sessionJoinOrderData["Item"].DeliveryDate;
            let joinOrderShoppingCartItemID = sessionJoinOrderData["Item"].ShoppingCartItemID;
            let priceDeliveryDate = sessionJoinOrderData["Item"].Price.DeliveryDate;
            let priceProductionLineID = sessionJoinOrderData["Item"].Price.ProductionLineID;
            let priceProductPrice = sessionJoinOrderData["Item"].Price.ProductPrice;
            let priceShoppingCartItemID = sessionJoinOrderData["Item"].Price.ShoppingCartItemID;
            let priceTotalProductPrice = sessionJoinOrderData["Item"].Price.TotalProductPrice;
            let priceTotalWithoutTransportationCost = sessionJoinOrderData["Item"].Price.TotalWithoutTransportationCost;
            let ProductionLineID = sessionJoinOrderData["Item"].ProductionLineID;
            let productionLineOrderedQuantity = sessionJoinOrderData["Item"].ProductionLineOrderedQuantity;
            let productPrices = sessionJoinOrderData["Item"].ProductPrices;
            let productPrintPrices = sessionJoinOrderData["Item"].ProductPrintPrices;
            let totalAdditionalCostsPrice = sessionJoinOrderData["Item"].Price.TotalAdditionalCostsPrice;
            let totalGovernmentalCostsPrice = sessionJoinOrderData["Item"].Price.TotalGovernmentalCostsPrice;
            let totalLogosPrice = sessionJoinOrderData["Item"].Price.TotalLogosPrice;
            let totalProductPrice = sessionJoinOrderData["Item"].Price.TotalProductPrice;
            let totalQuantity = sessionJoinOrderData["Item"].Price.TotalQuantity;
            let totalWithoutTransportationCost = sessionJoinOrderData["Item"].Price.TotalWithoutTransportationCost;

            responseData1["Item"]["isJoinOrder"] = true;
            responseData1["TotalWithoutVAT"] = totalWithoutVAT;
            responseData1["GrandTotal"] = totalWithoutVAT;
            responseData1["ShoppingCartItemID"] = joinOrderShoppingCartItemID;
            responseData1["Item"].DeliveryDate = DeliveryDate;
            responseData1["Item"].ShoppingCartItemID = joinOrderShoppingCartItemID;
            responseData1["Item"].Price.DeliveryDate = priceDeliveryDate;
            responseData1["Item"].Price.ProductionLineID = priceProductionLineID;
            responseData1["Item"].Price.ProductPrice = priceProductPrice;
            responseData1["Item"].Price.ShoppingCartItemID = priceShoppingCartItemID;
            // responseData1['Item'].Price.TotalProductPrice = priceTotalProductPrice;
            // responseData1['Item'].Price.TotalWithoutTransportationCost = priceTotalWithoutTransportationCost;
            responseData1["Item"].ProductionLineID = ProductionLineID;
            responseData1["Item"].ProductionLineOrderedQuantity = productionLineOrderedQuantity;
            responseData1["Item"].ProductPrices = productPrices;
            // responseData1['Item'].ProductPrintPrices = productPrintPrices;
            // responseData1['Item'].ProductPrintPrices = this.responseData['Item'].ProductPrintPrices;
            responseData1["Item"].OrderedQuantity = Number(responseData1["Item"].OrderedQuantity);
            responseData1["Item"].Price.TotalAdditionalCostsPrice = totalAdditionalCostsPrice;
            responseData1["Item"].Price.TotalGovernmentalCostsPrice = totalGovernmentalCostsPrice;
            responseData1["Item"].Price.TotalLogosPrice = totalLogosPrice;
            responseData1["Item"].Price.TotalProductPrice = totalProductPrice;
            responseData1["Item"].Price.TotalQuantity = totalQuantity;
            responseData1["Item"].Price.TotalWithoutTransportationCost = totalWithoutTransportationCost;
            responseData1["Item"].Logos = data["Item"].Logos;
            if (this.addAnotherBrandingClickStatus) {
              this.shoppingCartData.Logos.push({
                PrintPriceID: undefined,
                OrderLogoColors: this.orderLogoColors,
                isEmailLogoLater: false,
              });
              this.addAnotherBrandingClickStatus = false;
            }
            // responseData1['Item'].Price.Logos = data['Item'].Price.Logos;
            /** Overwrite values to JoinOrder Data Based on changes in new order Data(Shopping Cart Item)(ex.,Size tab Changes) */
            let logoscheck = responseData1["Item"].Logos;
            let logosPriceCheck = responseData1["Item"].Price.Logos;

            for (let h = 0; h < data["Item"].Logos.length; h++) {
              if (data["Item"].Logos[h].PrintPriceID !== undefined && data["Item"].Logos[h].PrintPriceID !== null) {
                const check = data["Item"].Logos[h];
                const checkExists = sessionJoinOrderData["Item"].ProductPrintPrices.filter((data) =>
                  data.LogoTypeID === check.LogoTypeID &&
                  data.NumberOfColors === check.NumberOfColors &&
                  data.LogoPositionID === check.LogoPositionID &&
                  data.LogoMaxAreaID === check.LogoMaxAreaID
                );
                if (checkExists.length > 0) {
                  responseData1["Item"].Logos[h].PrintPriceID = checkExists[0].ProductPrintPriceID;
                  //console.log("CHECK EXISTS : ", checkExists[0]);
                }
              }
            }

            /** non-replaceble properties in Join Order Section */
            this.spinnerShow = true;
            this._httpWebshopService.addShoppingCart(responseData1).subscribe((data2) => {
              //console.log(responseData1)
              this.spinnerShow = false;
              this.joinOrderShoppingCartData = data2;

              sessionStorage.setItem("cartData", JSON.stringify(this.joinOrderShoppingCartData));

              /** Replace Shoppingcart data printprice values based on Join order API response for
               * Fixed Logo products
               */
              // tslint:disable-next-line:max-line-length
              if (this.joinOrderShoppingCartData.Item.ProductPrintPrices !== undefined && this.joinOrderShoppingCartData.Item.ProductPrintPrices !== null) {
                this.fixedLogoProductPrintPrices = [];
                if (this.joinOrderShoppingCartData.Item.isFixedLogo) {
                  //console.log("Inside Fixed Logo Condition 123");
                  if (this.shoppingCartData.ProductPrintPrices !== undefined && this.shoppingCartData.ProductPrintPrices !== null) {
                    this.shoppingCartData.ProductPrintPrices = this.joinOrderShoppingCartData.Item.ProductPrintPrices;
                    this.fixedLogoProductPrintPrices = this.joinOrderShoppingCartData.Item.ProductPrintPrices;
                  }
                }
              }
              // data2['Item'].Logos = logoscheck;
              // data2['Item'].Price.Logos = logosPriceCheck;
              //console.log("Join Order Update Response : ", data2);
              this.displayJoinOrderCommonPrice(data2["Item"], data2["CurrencyCode"]);
              sessionStorage.setItem("joinOrderId", data2["Item"].ShoppingCartItemID);
              sessionStorage.setItem("joinOrderData", JSON.stringify(data2));
            });
          }
        }
        //  }

        if (this.responseData.Item.isVisible) {
          if (this.joinOrderProductStatus && this.joinOrderButtonClick) {
            this.displayJoinOrderCommonPrice(this.joinOrderShoppingCartData["Item"], this.joinOrderShoppingCartData["CurrencyCode"]);
            this.displayCommonPriceValue = 0;
          }
          if (this.newOrderButtonClick) {
            this.joinOrderShoppingCartData = ""; // reset shopping cart join order data for this product once click Add to cart button
            this.displayJoinOrderCommonPriceValue = 0;
            this.displayCommonPrice(data["Item"], data["CurrencyCode"]);
          }
          if (sessionStorage.getItem("joinOrderData") !== undefined && sessionStorage.getItem("joinOrderData") !== null) {
            sessionStorage.removeItem("joinOrderData");
          }
          if (sessionStorage.getItem("joinOrderId") !== undefined && sessionStorage.getItem("joinOrderId") !== null) {
            sessionStorage.removeItem("joinOrderId");
          }
          if (sessionStorage.getItem("newOrderData") !== undefined && sessionStorage.getItem("newOrderData") !== null) {
            sessionStorage.removeItem("newOrderData");
          }
          if (sessionStorage.getItem("newOrderId") !== undefined && sessionStorage.getItem("newOrderId") !== null) {
            sessionStorage.removeItem("newOrderId");
          }
        }

        /** new-code => If product have join orders */

        this.spinnerShow = false;
        this.tabsDisable = false;
        /* console.log("Request Data");
        console.log(this.responseData.Item);

        console.log("this.responseData");
        console.log(data['Item']);*/
        /** new-code => Issue Fix for when click "Add More Logo in Logo Tab, show one more Logo Section" */
        //  if (this.newOrderButtonClick && this.joinOrderButtonClick) {
        for (let k = 0; k < this.responseData.Item.Logos.length; k++) {
          if (this.responseData.Item.Logos[k].PrintPriceID === undefined) {
            if (this.addAnotherBrandingClickStatus) {
              this.shoppingCartData.Logos.push(this.responseData.Item.Logos[k]);
              this.addAnotherBrandingClickStatus = false;
            }
          }
        }
        // }
        if (sessionStorage.getItem("newOrderData") !== undefined && sessionStorage.getItem("newOrderData") !== null) {
          const newOrderLogoData = JSON.parse(sessionStorage.getItem("newOrderData"));
          for (let h = 0; h < data["Item"].Logos.length; h++) {
            if (data["Item"].Logos[h].PrintPriceID !== undefined && data["Item"].Logos[h].PrintPriceID !== null) {
              const check = data["Item"].Logos[h];
              //console.log("newOrderLogoData['Item'] : ", newOrderLogoData['Item']);
              const checkExists = newOrderLogoData["Item"].ProductPrintPrices.filter((data) =>
                data.LogoTypeID === check.LogoTypeID &&
                data.NumberOfColors === check.NumberOfColors &&
                data.LogoPositionID === check.LogoPositionID &&
                data.LogoMaxAreaID === check.LogoMaxAreaID
              );
              if (checkExists.length > 0) {
                this.responseData["Item"].Logos[h].PrintPriceID = checkExists[0].ProductPrintPriceID;
              }
            }
          }
        }
        // this.shoppingCartData.Logos = this.responseData.Item.Logos;
        /** new-code => Issue Fix for when click "Add More Logo in Logo Tab, show one more Logo Section" */

        /** new-code => Issue Fix for Size, when enter qty after This API Response it's reassign, to avoid this we use this code" */
        this.shoppingCartData.ProductSizes = this.responseData.Item.ProductSizes;
        for (let k = 0; k < this.responseData.Item.ProductSizes.length; k++) {
          if (this.responseData.Item.ProductSizes[k].ProductID === this.shoppingCartData.ProductSizes[k].ProductID) {
            // this.responseData.Item.ProductSizes[k].OrderedQuantity = this.responseData.Item.ProductSizes[k].OrderedQuantity;
            this.shoppingCartData.ProductSizes[k].OrderedQuantity = this.responseData.Item.ProductSizes[k].OrderedQuantity;
          }
        }
        //console.log("shopping cart data after size loop");
        //console.log(this.shoppingCartData);
        /** new-code => Issue Fix for Size, when enter qty after This API Response it's reassign, to avoid this we use this code */

        /** new-code => color preselect child color id based on if parent color id Exists */
        if (this.selectColorId > 0) {
          if (this.shoppingCartData.ProductColors.length > 0 && this.shoppingCartData.ProductColorID > 0) {
            let colorIndex = this.shoppingCartData.ProductColors.findIndex((data) => Number(data.ProductColorID) === Number(this.selectColorId));
            if (colorIndex < 0) {
              colorIndex = this.shoppingCartData.ProductColors.findIndex((data) => Number(data.ParentColorID) === Number(this.selectColorId));
            }
            this.shoppingCartData.ProductColorID = this.shoppingCartData.ProductColors[colorIndex].ProductColorID;
          }
        }
        /** new-code => color preselect child color id based on if parent color id Exists */

        /** new-code => To set Color index based on user select color => give alert message if stock balance is Smaller than Enter QTY */
        //console.log("this.shoppingCartData.ProductColorID : ", this.shoppingCartData.ProductColorID);
        if (this.shoppingCartData.ProductColorID !== undefined) {
          for (let j = 0; j < this.shoppingCartData.ProductColors.length; j++) {
            if (this.shoppingCartData.ProductColorID === this.shoppingCartData.ProductColors[j].ProductColorID) {
              //  this.selectedColor(this.shoppingCartData.ProductColors[j], j, 'color');
            }
          }
        }
        /** new-code => To set Color index based on user select color => give alert message if stock balance is Smaller than Enter QTY */

        // this.displayCommonPrice(data['Item'], data['CurrencyCode']);
        this.orderDate = this.shoppingCartData.DeliveryDate; // assign value to new order / Join Order Date variable
        this.expressOrderStatus = this.shoppingCartData.isExpressOrder; // assign value to Express order Checkbox
        this.tabNames = [];
        if (this.shoppingCartData.Logos.length > 0 && !this.shoppingCartData.isSampleWithoutLogo && this.shoppingCartData.isFixedLogo !== true) {
          /** new-code => to know list of tab names for particular visited product in PDP */
          this.tabNames.push("logo");
        }
        if (this.shoppingCartData.ProductColors.length >= 1) {
          /** new-code => to know list of tab names for particular visited product in PDP */
          this.tabNames.push("color");
        }
        if (this.shoppingCartData.ProductSizes.length > 0) {
          /** new-code => to know list of tab names for particular visited product in PDP */
          this.tabNames.push("productSize");
        }
        if ((this.shoppingCartData.AdditionalCosts && this.shoppingCartData.AdditionalCosts.length > 0) || (this.shoppingCartData.Price && this.shoppingCartData.Price.GovernmentalCosts && this.shoppingCartData.Price.GovernmentalCosts.length > 0 && this.userId !== null)) {
          /** new-code => to know list of tab names for particular visited product in PDP */
          this.tabNames.push("additionalCost");
        }
        if (this.shoppingCartData.ProductProperties.length > 0) {
          /** new-code => to know list of tab names for particular visited product in PDP */
          this.tabNames.push("productProperty");
        }

        /** new-code => Proceed to next tab based on Current Tab Data Completed when click Add to Cart Button in
         * Common Price Section
         */
        if (this.commonPriceSectionAddToCartButtonStatus) {
          if (this.currentTabName === "logo") {
            if (this.shoppingCartData.isLogosStepCompleted) {
              proceedNext = true;
            }
          }
          if (this.currentTabName === "color") {
            if (this.shoppingCartData.isColorStepCompleted) {
              proceedNext = true;
            }
          }

          if (this.currentTabName === "productSize" || this.currentTabName === "additionalCost" || this.currentTabName === "productProperty") {
            if (this.currentTabName === "productSize" && this.shoppingCartData.isSizesStepCompleted) {
              proceedNext = true;
            } else if (this.currentTabName === "additionalCost" && this.shoppingCartData.isAdditionalCostsStepCompleted) {
              proceedNext = true;
            } else {
              if (this.currentTabName === "productProperty" && this.shoppingCartData.isProductPropertiesStepCompleted) {
                proceedNext = true;
              }
            }
          }
        } else {
          if (this.currentTabName === "additionalCost" && this.shoppingCartData.isAdditionalCostsStepCompleted) {
            proceedNext = false;
          }
        }
        /** new-code => Proceed to next tab based on Current Tab Data Completed when click Add to Cart Button in
         * Common Price Section
         */
        /** new-code */

        /** new-code => code to move next tab when it is in Starttab */
        if ((this.shoppingCartItemId !== null || this.shoppingCartItemId !== undefined || this.shoppingCartItemId !== "") && (this.newOrderButtonClick || this.joinOrderButtonClick)) {
          proceedNext = true;

          /** new-code => code to remove locaStorage of Join/New order data which product clicked ADD TO CART Button */
          if (this.newOrderButtonClick || this.joinOrderButtonClick) {
            if (sessionStorage.getItem("joinOrderData") !== undefined && sessionStorage.getItem("joinOrderData") !== null) {
              sessionStorage.removeItem("joinOrderData");
            }
            if (sessionStorage.getItem("joinOrderId") !== undefined && sessionStorage.getItem("joinOrderId") !== null) {
              sessionStorage.removeItem("joinOrderId");
            }
            if (sessionStorage.getItem("newOrderData") !== undefined && sessionStorage.getItem("newOrderData") !== null) {
              sessionStorage.removeItem("newOrderData");
            }
            if (sessionStorage.getItem("newOrderId") !== undefined && sessionStorage.getItem("newOrderId") !== null) {
              sessionStorage.removeItem("newOrderId");
            }

            /** new-code => Set ShoppingCartCount && ShoppingCartDatas when Click "Add to Cart Button in New/Join Order Section" */
            /** new-code => check that shopping cart id is already added into cart item */
            const sessionShoppingCartData = JSON.parse(sessionStorage.getItem("shoppingCartDatas"));
            let shoppingCartCountAddStatus = true;
            if (Number(sessionShoppingCartData.length) > 0) {
              for (let k = 0; k < sessionShoppingCartData.length; k++) {
                if (Number(sessionShoppingCartData[k].ShoppingCartItemID) === Number(this.shoppingCartItemId)) {
                  shoppingCartCountAddStatus = false;
                }
              }
            }

            /** new-code => check that shopping cart id is already added into cart item */
            if (shoppingCartCountAddStatus) {
              const shoppingCartCount = Number(sessionStorage.getItem("shoppingCartCount"));
              sessionStorage.setItem("shoppingCartCount", shoppingCartCount.toString());
              this._httpWebshopService.setCount(sessionStorage.getItem("shoppingCartCount"));

              const shoppingCartDatas = JSON.parse(sessionStorage.getItem("shoppingCartDatas"));
              this.responseData.ProductID = this.responseData.Item.ProductID;
              shoppingCartDatas.push(this.responseData.Item);
              //console.log('shoppingCartDatas', shoppingCartDatas)
              //console.log(this.responseData.Item)
              sessionStorage.setItem("shoppingCartDatas", JSON.stringify(shoppingCartDatas));
            }
            /** new-code => Set ShoppingCartCount && ShoppingCartDatas when Click "Add to Cart Button in New/Join Order Section" */
          }

          if (this.newOrderButtonClick) {
            this.joinOrderShoppingCartData = ""; // reset shopping cart join order data for this product once click Add to cart button
            this.displayJoinOrderCommonPriceValue = 0;
          }
          if (this.joinOrderButtonClick) {
            this.displayJoinOrderCommonPrice(data["Item"], data["CurrencyCode"]);
            this.displayCommonPriceValue = 0;
          }
          this.joinOrderButtonClick = false;
          this.newOrderButtonClick = false;
          //console.log("Last line");
          /** new-code => code to remove locaStorage of Join/New order data which product clicked ADD TO CART Button */
        }
        /** new-code => code to move next tab when it is in Starttab */
        window.history.replaceState(" ", "", "/productdetail/" + data["Item"].ProductID + "/" + data["Item"].ProductName + "/" + data["Item"].ShoppingCartItemID);
        this.shoppingCartData.ShoppingCartItemID = data["Item"].ShoppingCartItemID;

        // update print prices and logos (in case quantity has changed)
        this.shoppingCartData.ProductPrintPrices = data["Item"].ProductPrintPrices;

        // for (let j = 0; j < this.shoppingCartData.ProductPrintPrices.length; j++) {
        //   this.shoppingCartData.ProductPrintPrices[j].Description = this.shoppingCartData.ProductPrintPrices[j].Description
        //     + ' ' + this.shoppingCartData.ProductPrintPrices[j].LogoTypeName;
        // }

        for (let j = 0; j < this.shoppingCartData.Logos.length; j++) {
          if (this.shoppingCartData.Logos[j].PrintPriceID !== undefined) {
            this.shoppingCartData.Logos[j] = data["Item"].Logos[j];
          }
          this.shoppingCartData.Logos[j].ProductPrintPrices = this.shoppingCartData.ProductPrintPrices;
          this.shoppingCartData.Logos[j].ProductLogoPositions = this.shoppingCartData.ProductLogoPositions;
          this.shoppingCartData.Logos[j].ProductLogoTypes = this.shoppingCartData.ProductLogoTypes;
          this.shoppingCartData.Logos[j].ProductLogoMaxAreas = this.shoppingCartData.ProductLogoMaxAreas;

          if (this.shoppingCartData.DefaultLogoPositionID !== undefined && this.shoppingCartData.Logos[j].PrintPriceID === undefined) {
            // this.shoppingCartData.Logos[j].LogoPositionID = this.shoppingCartData.DefaultLogoPositionID;
            this.shoppingCartData.Logos[j].LogoPositionID = "";
          } else if (this.shoppingCartData.Logos[j].PrintPriceID === undefined || this.shoppingCartData.Logos[j].LogoPositionID === undefined) {
            this.shoppingCartData.Logos[j].LogoPositionID = "";
          }

          if (this.shoppingCartData.DefaultLogoTypeID !== undefined && this.shoppingCartData.Logos[j].PrintPriceID === undefined) {
            // this.shoppingCartData.Logos[j].LogoTypeID  = this.shoppingCartData.DefaultLogoTypeID;
            this.shoppingCartData.Logos[j].LogoTypeID = "";
          } else if (this.shoppingCartData.Logos[j].PrintPriceID === undefined) {
            this.shoppingCartData.Logos[j].LogoTypeID = "";
          }

          if (this.shoppingCartData.DefaultLogoMaxAreaID !== undefined && this.shoppingCartData.Logos[j].PrintPriceID === undefined) {
            // this.shoppingCartData.Logos[j].LogoMaxAreaID  = this.shoppingCartData.DefaultLogoMaxAreaID;
            this.shoppingCartData.Logos[j].LogoMaxAreaID = "";
          } else if (this.shoppingCartData.Logos[j].PrintPriceID === undefined || this.shoppingCartData.Logos[j].LogoMaxAreaID === undefined) {
            this.shoppingCartData.Logos[j].LogoMaxAreaID = "";
          }
          this.applyLogoFilters(this.shoppingCartData.Logos[j]);
          // this.showPositionImage(this.shoppingCartData.Logos[j].PrintPriceID, this.shoppingCartData.Logos[j], j);
        }

        /** new-code => for additional cost tab */
        for (let j = 0; j < this.shoppingCartData.AdditionalCosts.length; j++) {
          this.shoppingCartData.isAdditionalCostsStepCompleted = true;
          if (!this.shoppingCartData.AdditionalCosts[j].isOptionalForBuyer) {
            this.shoppingCartData.AdditionalCosts[j].isSelected = true;
          }
        }
        if ((this.shoppingCartData.AdditionalCosts && this.shoppingCartData.AdditionalCosts.length) === 0 && (this.shoppingCartData.Price && this.shoppingCartData.Price.GovernmentalCosts && this.shoppingCartData.Price.GovernmentalCosts.length) > 0) {
          this.shoppingCartData.isAdditionalCostsStepCompleted = true;
        }
        /** new-code => for additional cost tab */

        /** new-code => for Properties tab */
        if (this.shoppingCartData.ProductProperties && this.shoppingCartData.ProductProperties.length > 0) {
          for (let h = 0; h < this.shoppingCartData.ProductProperties.length; h++) {
            if (this.shoppingCartData.ProductProperties[h].SelectedOptionID === undefined) {
              this.shoppingCartData.ProductProperties[h].SelectedOptionID = "";
            }
          }
        }
        /** new-code => for Properties tab */

        this.responseData["Item"].ProductProperties = data["Item"].ProductProperties;
        // this.shoppingCartData.ProductProperties =  data.Item.ProductProperties;
        if (step === "checkout" && checkoutPageNavigationStatus && this.commonServicesService.readyForCheckout(shoppingCartDatas, true)) {
          // call getShoppingCart function code
          try {
            let userBrowserID = "";
            const cookieExists: Boolean = this.cookieService.check("shoppingCardTokenId");
            if (!cookieExists) {
              let domainName = this._httpWebshopService.getSubdomain(window.location.hostname);
              domainName = domainName ? domainName : 'voucherkart';
              const randomString = this._httpWebshopService.randomGeneration(6);
              this.cookieService.set("shoppingCardTokenId", domainName + randomString);
              userBrowserID = domainName + randomString;
            } else {
              userBrowserID = this.cookieService.get("shoppingCardTokenId");
            }
            const userSettings = this.globalService.getUserSettings();
            const webShopSettings = this.globalService.webShopSettings();
            const loggedInData = this.globalService.getLoggedInData();
            if (loggedInData !== null) {
              this.cartParams = {
                UserID: loggedInData.UserID,
                CustomerID: webShopSettings.CustomerID,
                CurrencyID: userSettings.CurrencyID,
                UserBrowserID: userBrowserID,
                InitialDeliveryCountry: loggedInData.Country,
              };
            } else {
              this.cartParams = {
                UserID: null,
                CustomerID: webShopSettings.CustomerID,
                CurrencyID: userSettings.CurrencyID,
                UserBrowserID: userBrowserID,
                InitialDeliveryCountry: userSettings.CountryCode,
              };
            }
            this.spinnerShow = true;
            this._httpWebshopService.getShoppingCart(this.cartParams).subscribe((data1) => {
              this.spinnerShow = false;
              this.shoppingCartList = data1;
              this.itemsList = data1["Items"];
              sessionStorage.setItem("cartData", JSON.stringify(this.shoppingCartList));

              /** new-code => Calculation Common Price */
              //  this.displayCommonPrice(data1['Items'], data1['CurrencyCode']);
              /** new-code => Calculation Common Price */

              for (let i = 0; i < this.itemsList.length; i++) {
                for (let j = 0; j < this.itemsList[i].ProductColors.length; j++) {
                  if (this.itemsList[i].ProductColorID === this.itemsList[i].ProductColors[j].ProductColorID) {
                    this.itemsList[i].colorIndex = j;
                  }
                }
              }

              /** new-code => Restirct to display invisible: false items of shopping cart */
              const shoppingCartitemData = [];
              let k = 0;
              for (let i = 0; i < data["Items"].length; i++) {
                if (data["Items"][i].isVisible) {
                  shoppingCartitemData[k] = data["Items"][i];
                  k++;
                }
              }
              /** new-code => Restirct to display invisible: false items of shopping cart */
              // this._httpWebshopService.changeMessage(data1['Items'].length)
              this._httpWebshopService.changeMessage(shoppingCartitemData.length);
              this._httpWebshopService.currentMessage.subscribe(
                (message) => (this.cartCount = message)
              );
              this.cartCount = shoppingCartitemData.length;

              /** new-code */
              sessionStorage.setItem("shoppingCartDatas", JSON.stringify(shoppingCartitemData));
              /** new-code */

              sessionStorage.setItem("shoppingCartCount", this.cartCount.toString());
              setTimeout(() => {
                if (sessionStorage.getItem("joinOrderCounts") !== "undefined" && sessionStorage.getItem("joinOrderCounts") !== null && sessionStorage.getItem("joinOrderCounts") !== "") {
                  this.joinOrderCounts = JSON.parse(sessionStorage.getItem("joinOrderCounts"));
                }
              }, 5000);

              if (this.itemsList !== undefined) {
                let goCheckout = true;
                let checkedCount = 0;
                this.getShoppingCartIDList = [];
                this.checkoutDataList = [];
                let showShoppingCartWidgetStatus = false;
                for (let i = 0; i < this.itemsList.length; i++) {
                  if (this.itemsList[i].isChecked) {
                    this.checkoutStatus = true;
                    this.getShoppingCartIDList.push(this.itemsList[i].ShoppingCartItemID);
                    checkedCount = checkedCount + 1;
                    this.tabCount = 1;
                    if (this.itemsList[i].ProductColors && this.itemsList[i].ProductColors.length >= 1) {
                      this.tabCount = this.tabCount + 1;
                    }
                    if (this.itemsList[i].ProductSizes && this.itemsList[i].ProductSizes.length > 0) {
                      this.tabCount = this.tabCount + 1;
                    }
                    if (this.itemsList[i].AdditionalCosts && this.itemsList[i].AdditionalCosts.length > 0) {
                      this.tabCount = this.tabCount + 1;
                    } else if (this.itemsList[i].Price !== undefined) {
                      if (this.itemsList[i].Price.GovernmentalCosts && this.itemsList[i].Price.GovernmentalCosts.length > 0) {
                        // this.tabCount = this.tabCount + 1;
                      }
                    }
                    if (this.itemsList[i].ProductProperties && this.itemsList[i].ProductProperties.length > 0) {
                      this.tabCount = this.tabCount + 1;
                    }
                    // if (this.itemsList[i].Logos && this.itemsList[i].Logos.length > 0 && !this.itemsList[i].isFixedLogo) {
                    //   this.tabCount = this.tabCount + 1;
                    // }
                    if (this.itemsList[i].Logos && this.itemsList[i].Logos.length > 0 && this.itemsList[i].Logos[0].PrintPriceID !== undefined && !this.itemsList[i].isSampleWithLogo && !this.itemsList[i].isSampleWithoutLogo) {
                      //console.log("tabCount Logos : ");
                      this.tabCount = this.tabCount + 1;
                    }

                    if (this.itemsList[i].Logos && this.itemsList[i].Logos.length > 0 && this.itemsList[i].isProceedWithoutLogo) {
                      this.tabCount = this.tabCount + 1;
                    }

                    /** new-code => For Checkout Disable */
                    this.oneTabStatus = false;
                    /** new-code => For Checkout Disable */
                    // completed Step Count
                    this.tabCompletedCount = 1;
                    if (this.itemsList[i].isColorStepCompleted) {
                      this.tabCompletedCount = this.tabCompletedCount + 1;
                    }
                    if (this.itemsList[i].isSizesStepCompleted) {
                      this.tabCompletedCount = this.tabCompletedCount + 1;
                    }
                    if (this.itemsList[i].isAdditionalCostsStepCompleted && this.itemsList[i].AdditionalCosts.length > 0) {
                      this.tabCompletedCount = this.tabCompletedCount + 1;
                    }
                    if (this.itemsList[i].isProductPropertiesStepCompleted) {
                      this.tabCompletedCount = this.tabCompletedCount + 1;
                    }
                    if (this.itemsList[i].isLogosStepCompleted) {
                      this.tabCompletedCount = this.tabCompletedCount + 1;
                    }

                    if (this.tabCompletedCount !== this.tabCount) {
                      goCheckout = false;
                      // const errorValue = this.translate.get('CHECKOUT.FILL_ALL_FIELDS');
                      // this.toastService.error(this.itemsList[i].ProductName + errorValue['value']);
                      this.translate.get("CHECKOUT.FILL_ALL_FIELDS").subscribe((res: string) => {
                        this.toastr.error(this.itemsList[i].ProductName + res);
                        this.itemsList[i].errorMessage = this.itemsList[i].ProductName + res;
                      });
                      showShoppingCartWidgetStatus = true;
                    }
                    if (this.tabCompletedCount === this.tabCount && !this.itemsList[i].isValid) {
                      // const errorValue = this.translate.get('CHECKOUT.IS_NOT_VALID');
                      // this.toastService.error(this.itemsList[i].ProductName + errorValue['value']);
                      this.translate.get("CHECKOUT.IS_NOT_VALID").subscribe((res: string) => {
                        this.toastr.error(this.itemsList[i].ProductName + res);
                        this.itemsList[i].errorMessage = this.itemsList[i].ProductName + res;
                      });
                      showShoppingCartWidgetStatus = true;
                      // this.itemsList[i].errorMessage = this.itemsList[i].ProductName + errorValue['value'];
                    }
                  }
                  this.checkoutDataList.push(this.itemsList[i]);
                }
                if (showShoppingCartWidgetStatus) {
                  this.productCartModal.showProductCartWidgetModal(this.itemsList);
                }

                if (Number(checkedCount) === 0) {
                  // this.translatedMessage = (this.translate.get('HEADER_ONE.PLEASE_SELECT_ONE'));
                  // this.toastService.error(this.translatedMessage.value);
                  this.translate.get("HEADER_ONE.PLEASE_SELECT_ONE").subscribe((res: string) => {
                    this.toastr.error(res);
                  });
                } else if (goCheckout && checkedCount > 0) {
                  this.shoppingCartList.Items = this.checkoutDataList;
                  this.spinnerShow = true;
                  this._httpWebshopService.addShoppingCarts(this.shoppingCartList).subscribe((_data) => {
                    this.spinnerShow = false;
                    this.router.navigate(["/checkout"], { replaceUrl: false, });
                    try {
                      this.productCartModal.hide();
                    } catch (error) { }
                  });
                }
              }
            },
              (error) => {
                this.toastr.error("Error: " + error);
              }
            );
          } catch (error) {
            this.toastr.error(error);
          }

          //  this.router.navigate(['/checkout'],
          // { replaceUrl: false });
        } else if (step === "productDetail") {
          this.shoppingCartData.ProductName = this.shoppingCartData.ProductName.replace(/\//g, "");
          this.shoppingCartData.ProductName = this.shoppingCartData.ProductName.replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");
          this.router.navigate(["/productdetail", this.shoppingCartData.ProductID, this.shoppingCartData.ProductName], { replaceUrl: false });
        } else if (step === "home") {
          this.router.navigate(["/"], { replaceUrl: false });
        } else if (step === "loadStartTab" && proceedNext) {
          this.loadStartTab(step);
          this.commonPriceSectionAddToCartButtonStatus = false;
        } else if (step !== null && step !== "checkout" && proceedNext) {
          this.proceedNext(step);
          this.commonPriceSectionAddToCartButtonStatus = false;
        }
        if (isMultipleCall) {
          // const set = document.getElementById('fullHeightModalRight')
          // set.classList.add('displayShoppingcart');
          // this.menuRightComponent.ngOnInit()
          this.productCartModal.showProductCartModal();
        }
        if (step) {
          this.toastr.success("Product has been updated to your shopping cart");
        }
      });
    }
    this.addOrUpdateShoppingCartStatus = true;
    //console.log(this.shoppingCartData)
  }

  showLogoListModel(l) {
    this.logoListModal.show();
    this.getUserLogo();
    this.currentLogoIndex = l;
    this.addOrUpdateShoppingCart(null, false, false, "");
  }

  /** new-code - For Function of Add to Cart button in Common Price Display Section */
  commonPriceAddtoCart() {
    this.commonPriceSectionAddToCartButtonStatus = true;
    if (this.currentTabName === "logo") {
      this.addOrUpdateShoppingCart("logo", false, false, "", "logo", false, false);
    } else if (this.currentTabName === "color") {
      this.addOrUpdateShoppingCart("color", false, false, "", "color", false, false);
    } else {
      if (!this.shoppingCartData.isSampleWithLogo && !this.shoppingCartData.isSampleWithoutLogo) {
        if (this.currentTabName === "productSize") {
          this.getcalculatedArray(true, false, "productSize");
        } else if (this.currentTabName === "additionalCost") {
          this.addOrUpdateShoppingCart("additionalCost", false, false, "", "additionalCost", false, false);
        } else {
          this.addOrUpdateShoppingCart("productProperty", false, false, "", "productProperty", false, false);
        }
      }
      if (this.shoppingCartData.isSampleWithLogo || this.shoppingCartData.isSampleWithoutLogo) {
        if (this.currentTabName === "productSize" || this.currentTabName === "additionalCost" || this.currentTabName === "productProperty") {
          this.addOrUpdateShoppingCart("initial", false, false, "", this.currentTabName, false, false);
        } else {
          //console.log("Else");
        }
      }
    }
  }
  /** new-code - For Function of Add to Cart button in Common Price Display Section */

  getUserLogo() {
    try {
      this._httpWebshopService.getUserLogo().subscribe((data) => {
        this.userLogos = data;
      }, (error) => {
        // this.toastService.error('Error: ' + error._body);
        this.toastr.error("Error: " + error.error.Message);
      }
      );
    } catch (error) {
      this.toastr.error(error);
    }
  }
  setUploadedImageData(ev) {
    try {
      //console.log("setUploadedImageData ev : ", ev);
      this.shoppingCartData.Logos[ev[2]].FileName = ev[1];

      /** new-code => to append file original name to request */
      if (ev[3] !== undefined && ev[3] !== null && ev[3] !== "") {
        this.shoppingCartData.Logos[ev[2]].FileNameOriginal = ev[3];
      }
      /** new-code => to append file original name to request */
      this.shoppingCartData.isLogosStepCompleted = true;
      this.addOrUpdateShoppingCart('checkout', false, false, '')
      this.shoppingCartData.isLogosStepCompleted = true;
      this.getTabCompletedCount();
    } catch (error) {
      this.toastr.error(error);
    }
  }

  /** new-code => Get File name from child component */
  GetChildData(data) {
    this.FileName = data;
  }

  showLogo() {
    /** new-code => to assign currentTabName as Logo */
    this.currentTabName = "logo";
    if (this.shoppingCartData.Logos.length === 0 && this.shoppingCartData.ProductPrintPrices > 0) {
      this.addMoreLogo(false);
    } else {
      this.addOrUpdateShoppingCart(null, false, false, "", "logo");
      // addOrUpdateShoppingCart('productProperty', false, false, '')
    }
  }
  proceedNext(step) {
    this.deliveryDate = this.datepipe.transform(this.shoppingCartData.DeliveryDate, "dd-MM-yyyy");
    this.stepName = step;
    if (this.steps[step]) {
      try {
        this.staticTabs.setActiveTab(this.steps[step] + 1);
      } catch (error) { }
    }
    this.getTabCompletedCount();
  }

  loadStartTab(step = "initial") {
    step = "initial";
    this.deliveryDate = this.datepipe.transform(this.shoppingCartData.DeliveryDate, "dd-MM-yyyy");
    this.stepName = step;
    if (this.steps[step]) {
      try {
        this.staticTabs.setActiveTab(this.steps[step]);
      } catch (error) { }
    }
    this.getTabCompletedCount();
  }

  homePageRediction() {
    this.router.navigate(["/"]);
  }
  getTabCompletedCount() {
    this.tabCompletedCount = this.commonServicesService.getTabCompletedCount(this.shoppingCartData);
  }
  colorsChange(color) {
    color.dropDownDisable = this.commonServicesService.colorsChange(color);
    this.pantoneCodes = this._httpWebshopService.pantoneCodes;
    if (color.dropDownDisable) {
      // this.pantoneCodes = [];
    }
    // this.logoCompleted(false, '', true);
  }

  // for Number Only
  onlyNumberKey(event) {
    // return (event.charCode === 8 || event.charCode === 0) ? null : event.charCode >= 48 && event.charCode <= 57;
    const pattern = new RegExp("^[0-9\b]+$");
    const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!pattern.test(key) && event.charCode !== 0) {
      event.preventDefault();
      return false;
    }
  }

  setMiniumOrder(event, sb, status) {
    this.isQuantityValid = true;
    if (this.shoppingCartData.MinimumOrder > Number(event)) {
      this.shoppingCartData["OrderedQuantity"] = this.shoppingCartData.MinimumOrder;
      this.stepValidation(Number(this.shoppingCartData.MinimumOrder) / (this.shoppingCartData.FixedUnits ? this.shoppingCartData.PiecesPerUnit : 1), sb, status);
      if (this.shoppingCartData.FixedUnits) {
        this.shoppingCartData.units = Number(this.shoppingCartData.MinimumOrder / this.shoppingCartData.PiecesPerUnit);
      }
    } else {
      if (!this.shoppingCartData.isSampleWithLogo && !this.shoppingCartData.isSampleWithoutLogo && this.shoppingCartData.StockBalance !== undefined) {
        var enteredQuantity = Number(event);
        var totalFutureStockQuantity = 0;
        if (this.productDetail.ProductFutureStock !== undefined && this.productDetail.ProductFutureStock.length > 0) {
          this.productDetail.ProductFutureStock.forEach((element) => {
            totalFutureStockQuantity += element.Value;
          });
        }

        if (this.shoppingCartData.StockBalance + totalFutureStockQuantity < enteredQuantity) {
          if (totalFutureStockQuantity === 0) {
            this.case1 = true;
            this.case2 = false;
            this.case3 = false;
            this.selectColorId = 0;
          } else {
            this.case1 = false;
            this.case2 = false;
            this.case3 = false;
          }

          if (this.shoppingCartData.isInternalStockProduct === true) {
            this.isShowinquirybutton = true;
          } else {
            this.isShowinquirybutton = false;
          }

          this.isQuantityValid = false;
          setTimeout(() => {
            this.isQuantityValid = true;
          }, 100);

          this.shoppingCartData["OrderedQuantity"] = this.shoppingCartData.MinimumOrder;
          this.stepValidation(Number(this.shoppingCartData.MinimumOrder) / (this.shoppingCartData.FixedUnits ? this.shoppingCartData.PiecesPerUnit : 1), sb, status);
          if (this.shoppingCartData.FixedUnits) {
            this.shoppingCartData.units = Number(this.shoppingCartData.MinimumOrder / this.shoppingCartData.PiecesPerUnit);
          }
          // Show pop-up that entered quantity is not possible
          this.stockModalTranslate();
          this.shoppingCartStockModal.show();
        } else if (this.shoppingCartData.StockBalance < enteredQuantity && (this.shoppingCartData.ProductColors === undefined || this.shoppingCartData.ProductColors.length === 0) && (this.shoppingCartData.ProductSizes === undefined || this.shoppingCartData.ProductSizes.length === 0)) {
          // Entered quantity is higher than stock balance but possible from future stock, show pop-up with two options
          this.finalStockDeliveryDate = this.shoppingCartData.DeliveryDate;
          this.finalStockValue = this.shoppingCartData.StockBalance;

          let thresholdQuantity = this.shoppingCartData.StockBalance;
          let found = false;
          this.productDetail.ProductFutureStock.forEach((element) => {
            if (!found) {
              thresholdQuantity += element.Value;
              if (thresholdQuantity >= enteredQuantity) {
                this.finalFutureDeliveryDate = element.EarliestDeliveryDate;
                found = true;
              }
            }
          });

          this.finalFutureValue = enteredQuantity;
          this.restQuantity = enteredQuantity - this.shoppingCartData.StockBalance;

          if (new Date(this.finalStockDeliveryDate) < new Date(this.finalFutureDeliveryDate)) {
            this.isQuantityValid = false;
            setTimeout(() => {
              this.isQuantityValid = true;
            }, 100);

            // Set quantity to mininum quantity (in case user closes pop-up)
            this.shoppingCartData["OrderedQuantity"] = this.shoppingCartData.MinimumOrder;
            this.stepValidation(Number(this.shoppingCartData.MinimumOrder) / (this.shoppingCartData.FixedUnits ? this.shoppingCartData.PiecesPerUnit : 1), sb, status);
            if (this.shoppingCartData.FixedUnits) {
              this.shoppingCartData.units = Number(this.shoppingCartData.MinimumOrder / this.shoppingCartData.PiecesPerUnit);
            }

            this.firstTabSplitPopupOpened = true;
            this.futureStockModalTranslate();
            this.futureStockModal.show();
          }
        }
      }
    }
  }

  fixQuantity(quantity) {
    if (this.firstTabSplitPopupOpened) {
      this.shoppingCartData["OrderedQuantity"] = quantity;
      if (this.shoppingCartData.FixedUnits) {
        this.shoppingCartData.units = Number(quantity / this.shoppingCartData.PiecesPerUnit);
      }
      this.firstTabSplitPopupOpened = false;
    }
  }

  autoUpdateForQty(event, type, tabName) {
    this.currentTab = tabName;
    if (this.lastTab !== this.currentTab) {
      this.addOrUpdateShoppingCartStatus = false;
    }
    if (type === "input") {
      if (this.shoppingCartData.MinimumOrder <= Number(event)) {
        this.addOrUpdateShoppingCart(null, false, false, "");
      }
    } else {
      this.addOrUpdateShoppingCart(null, false, false, "");
    }
  }

  getProductDetails(productID) {

    try {
      // alert("getProductDetails : " + this.shoppingCartItemId);
      const userSettings = this.globalService.getUserSettings();
      const webShopSettings = this.globalService.webShopSettings();
      let shoppingCartItemIdStatus;
      let initialDeliveryCountry = "";
      if (this.shoppingCartItemId === null || this.shoppingCartItemId === undefined || this.shoppingCartItemId === "") {
        shoppingCartItemIdStatus = true;
        initialDeliveryCountry = webShopSettings.Country;
      }
      this.spinnerShow = true;
      this._httpProductService.getProductDetail(productID, userSettings.LanguageCode, shoppingCartItemIdStatus, initialDeliveryCountry).subscribe((data) => {
        this.loaderHideClass = true;
        this.spinnerShow = false;

        /** new-code => for loader show when QTy from Advances Search(we need to show loader until Addupdate API call Finish) */
        const breadCrumbsList = JSON.parse(sessionStorage.getItem("breadCrumbsList"));
        var searchUrl = breadCrumbsList[breadCrumbsList.length - 1].url;
        const idxQuantity = searchUrl.indexOf("Quantity=");
        var quantityStr = "";
        if (idxQuantity >= 0 && searchUrl.length > idxQuantity + 9 && (this.shoppingCartItemId === "" || this.shoppingCartItemId === 0 || this.shoppingCartItemId === undefined)) {
          searchUrl = searchUrl.substring(idxQuantity);
          if (searchUrl.indexOf("&") === -1) {
            quantityStr = searchUrl.substring(9);
          } else {
            quantityStr = searchUrl.substring(9, searchUrl.indexOf("&"));
          }
        }
        if (quantityStr !== "") {
          this.spinnerShow = true;
        }
        /** new-code => for loader show when QTy from Advances Search(we need to show loader until Addupdate API call Finish) */

        this.productDetail = data;

        if (this.productDetail.Brand !== 0) {
          this._httpWebshopService.getBrandName().subscribe((data: any) => {
            for (let b = 0; b < data.length; b++) {
              if (data[b].BrandId === this.productDetail.Brand) {
                this.productDetail.brandName = data[b].BrandName;
              }
            }
          });
        }

        /** new-code => to get product minimum QTY */
        if (this.productDetail.FixedUnits) {
          this.minimumOrderQty = Math.floor(this.productDetail.MinimumOrder / this.productDetail.PiecesPerUnit);
        } else {
          this.minimumOrderQty = this.productDetail.MinimumOrder;
        }
        /** new-code => to get product minimum QTY */

        /** new-code => to get product Maximum QTY */
        const priceArrayLength = this.productDetail.ProductPrices.length;
        this.productMaxInputValue = this.productDetail.ProductPrices[priceArrayLength - 1].MaximumQuantity;
        /** new-code => to get product Maximum QTY */

        //  this.getTransportationhandlingcosts(this.shoppingCartData.OrderedQuantity);
      });
    } catch (error) {
      this.toastr.error(error);
    }
  }

  afterNDays(numDays, date) {
    const res = date.setTime(date.getTime() + numDays * 24 * 60 * 60 * 1000);
    return (date = new Date(res));
  }

  sumOfSizeQuantity(sizes, left, right, futureStockArray, mainIndex, index) {
    this.productSizeCurrentQty = 0;
    let isChecksize = true;
    for (let i = 0; i < sizes.length; i++) {
      if (sizes[i].OrderedQuantity !== undefined) {
        if (sizes[i].OrderedQuantity > 0) {
          this.productSizeCurrentQty = Number(this.productSizeCurrentQty) + Number(sizes[i].OrderedQuantity);
        }
        if (sizes[i].OrderedQuantity > 0 && sizes[i].OrderedQuantity < sizes[i].MOQ) {
          isChecksize = false;
        }
      }
    }

    if (Number(this.productSizeCurrentQty) === Number(this.shoppingCartData.OrderedQuantity) && isChecksize) {
      let allow = false;
      this.calculatedFutureStock.forEach((element) => {
        if (element.futureStock === 0 && element.stockBalance >= element.orderQuantity) {
          // this.shoppingCartData.isSizesStepCompleted = false;
          // allow = true;
        }
      });
      if (!allow) {
        this.shoppingCartData.isSizesStepCompleted = true;
      }
      if (this.lastTab === "productSize") {
        this.shoppingCartData.isChecked = true;
        this.addOrUpdateShoppingCart("", false, false, "");
      }
      this.stepValidation(left, right, "isSizesStepCompleted");
      this.proceedNewOrder(futureStockArray, left, right, mainIndex, index);
    } else {
      this.shoppingCartData.isSizesStepCompleted = false;
      this.proceedNewOrder(futureStockArray, left, right, mainIndex, index);
    }
  }

  proceedNewOrder(futureStockArray, orderedQuantity, stockBalance, mainIndex, index) {
    if (orderedQuantity === "") {
      orderedQuantity = 0;
    }
    this.productCurrentQty = orderedQuantity;
    this.productDetail["ProductFutureStock"] = futureStockArray;
    this.productDetail["StockBalance"] = stockBalance;
    this.case1 = false;
    this.case2 = false;
    this.case3 = false;
    let total = 0;
    if (this.productDetail.ProductFutureStock !== undefined && this.productDetail.ProductFutureStock.length > 0) {
      this.productDetail.ProductFutureStock.forEach((element) => {
        total += element.Value;
        total = total;
      });
    }
    // total = total + this.productDetail.StockBalance;
    let allowfuturedate = true;
    if (allowfuturedate) {
      if (this.productDetail.StockBalance !== 0 && Number(orderedQuantity) > this.productDetail.StockBalance) {
        this.productDetail.ProductFutureStock.forEach((items) => {
          const setvalue = this.productDetail.StockBalance + items.Value;
          if (Number(orderedQuantity) <= setvalue) {
            const checkdate = this.afterNDays(this.productDetail.DeliveryWeeksTo * 7, new Date(items.Date));
            if (new Date(checkdate).getTime() <= new Date(this.shoppingCartData.DeliveryDate).getTime()) {
              allowfuturedate = true;
            }
          }
        });
      }
    }

    if (allowfuturedate !== true) {
      // this.param = {
      //   'DeliveryDate': '',
      //   'OrderQty': ''
      // }
    } else {
      if (this.productDetail.StockBalance < 0) {
        this.productDetail.StockBalance = 0;
      }
      if ((this.productDetail.StockBalance === 0 || Number(orderedQuantity) > this.productDetail.StockBalance) && this.productDetail.ProductFutureStock.length === 0) {
        this.case1 = true;
        if (this.productDetail.isInternalStockProduct === true) {
          this.isShowinquirybutton = true;
        } else {
          this.isShowinquirybutton = false;
        }
        // $scope.shownofuturestock = true;
        if (this.shoppingCartData.ProductColors && this.shoppingCartData.ProductColors.length !== 0) {
          this.shoppingCartData.ProductColors[mainIndex].AvailableSizes[index].OrderedQuantity = null;
        }
        if (this.shoppingCartData.ProductSizes && this.shoppingCartData.ProductSizes.length !== 0) {
          this.shoppingCartData.ProductSizes[index].OrderedQuantity = null;
        }
        this.isShowNoFutureStock = true;
        this.shoppingCartData.isSizesStepCompleted = false;
        this.productSizeCurrentQty = this.shoppingCartData.OrderedQuantity;
        this.stockModalTranslate();
        this.shoppingCartStockModal.show();
      }
      // tslint:disable-next-line:one-line
      else if (this.productDetail.StockBalance === 0 && Number(orderedQuantity) > this.productDetail.StockBalance && this.productDetail.ProductFutureStock.length !== 0) {
        // tslint:disable-next-line:no-shadowed-variable
        let keepGoing = true;
        let allownofuturepopup = false;
        this.productDetail.ProductFutureStock.forEach((items) => {
          if (keepGoing) {
            if (Number(orderedQuantity) <= items.Value) {
              this.finaldeliverydateformats = this.afterNDays(this.productDetail.DeliveryWeeksTo * 7, new Date(items.Date));
              this.finaldeliverydate = moment(this.finaldeliverydateformats).format("DD/MM/YYYY");
              this.case2 = true;
              keepGoing = false;
              allownofuturepopup = true;
              const set = {
                originalFutureStock: items.Value,
                orderedQuantity: orderedQuantity,
                earliestDeliveryDate: items.EarliestDeliveryDate,
                restQuantity: items.Value,
              };
              this.calculatedFutureStock.push(set);
              if (this.shoppingCartData.ProductColors && this.shoppingCartData.ProductColors.length !== 0) {
                this.shoppingCartData.ProductColors[mainIndex].AvailableSizes[index]["SelectedDeliveryDate"] = items.EarliestDeliveryDate;
              }
              if (this.shoppingCartData.ProductSizes && this.shoppingCartData.ProductSizes.length !== 0) {
                this.shoppingCartData.ProductSizes[index]["SelectedDeliveryDate"] = items.EarliestDeliveryDate;
              }
            }
          }
        });
        if (allownofuturepopup) {
          this.showfuturestockwithoutstock = true;
          //  this.stockModal.show();
          allownofuturepopup = false;
        }
        // tslint:disable-next-line:curly
        // tslint:disable-next-line:one-line
        else if (Number(orderedQuantity) <= total) {
          this.productDetail.ProductFutureStock.forEach((items) => {
            this.finaldeliverydateformats = this.afterNDays(this.productDetail.DeliveryWeeksTo * 7, new Date(items.Date));
            this.finaldeliverydate = moment(this.finaldeliverydateformats).format("DD/MM/YYYY");
            allownofuturepopup = true;
            this.case2 = true;
            const set = {
              originalFutureStock: items.Value,
              orderedQuantity: orderedQuantity,
              earliestDeliveryDate: items.EarliestDeliveryDate,
              restQuantity: items.Value,
            };
            this.calculatedFutureStock.push(set);
            if (this.shoppingCartData.ProductColors && this.shoppingCartData.ProductColors.length !== 0) {
              this.shoppingCartData.ProductColors[mainIndex].AvailableSizes[index]["SelectedDeliveryDate"] = items.EarliestDeliveryDate;
            }
            if (this.shoppingCartData.ProductSizes && this.shoppingCartData.ProductSizes.length !== 0) {
              this.shoppingCartData.ProductSizes[index]["SelectedDeliveryDate"] = items.EarliestDeliveryDate;
            }
          });
          if (allownofuturepopup) {
            //   this.stockModal.show();
            allownofuturepopup = false;
          }
        }
        // tslint:disable-next-line:one-line
        else {
          if (this.productDetail.isInternalStockProduct === true) {
            this.isShowinquirybutton = true;
          } else {
            this.isShowinquirybutton = false;
          }
          if (
            this.shoppingCartData.ProductColors &&
            this.shoppingCartData.ProductColors.length !== 0
          ) {
            this.shoppingCartData.ProductColors[mainIndex].AvailableSizes[index].OrderedQuantity = null;
          }
          if (this.shoppingCartData.ProductSizes && this.shoppingCartData.ProductSizes.length !== 0) {
            this.shoppingCartData.ProductSizes[index].OrderedQuantity = null;
          }
          this.shoppingCartData.isSizesStepCompleted = false;
          this.productSizeCurrentQty = this.shoppingCartData.OrderedQuantity;
          this.stockModalTranslate();
          this.shoppingCartStockModal.show();
        }
      }
      // tslint:disable-next-line:one-line
      else if (this.productDetail.StockBalance > 0 && Number(orderedQuantity) > this.productDetail.StockBalance && this.productDetail.ProductFutureStock.length !== 0) {
        let keepGoing = true;
        let getfuturevalue = 0;
        let allowpopup = false;
        // tslint:disable-next-line:prefer-const
        // tslint:disable-next-line:no-shadowed-variable
        this.restquantity = Number(orderedQuantity) - this.productDetail.StockBalance;

        this.productDetail.ProductFutureStock.forEach((items) => {
          getfuturevalue += items.Value;
          if (keepGoing) {
            if (this.restquantity <= getfuturevalue) {
              this.finaldeliverydateformats = this.afterNDays(this.productDetail.DeliveryWeeksTo * 7, new Date(items.Date));
              this.finaldeliverydateformat = moment(this.finaldeliverydateformats).format("DD/MM/YYYY");
              this.userproductiondates = moment(this.shoppingCartData.DeliveryDate).format("DD/MM/YYYY");
              keepGoing = false;
              allowpopup = true;
              this.case3 = true;
              const set = {
                originalFutureStock: items.Value,
                orderedQuantity: orderedQuantity,
                earliestDeliveryDate: items.EarliestDeliveryDate,
                restQuantity: this.restquantity,
              };
              this.calculatedFutureStock.push(set);
              if (this.shoppingCartData.ProductColors && this.shoppingCartData.ProductColors.length !== 0) {
                this.shoppingCartData.ProductColors[mainIndex].AvailableSizes[index]["SelectedDeliveryDate"] = items.EarliestDeliveryDate;
              }
              if (this.shoppingCartData.ProductSizes && this.shoppingCartData.ProductSizes.length !== 0) {
                this.shoppingCartData.ProductSizes[index]["SelectedDeliveryDate"] = items.EarliestDeliveryDate;
              }
            }
          }
        });

        if (allowpopup) {
          this.showfuturestockwithoutstock = false;
          //  this.stockModal.show();
          allowpopup = false;
        }
        // tslint:disable-next-line:one-line
        else if (Number(orderedQuantity) <= total) {
          // tslint:disable-next-line:no-shadowed-variable
          this.restquantity =
            Number(orderedQuantity) - this.productDetail.StockBalance;
          let allownofuturepopup = false;
          this.productDetail.ProductFutureStock.forEach((items) => {
            this.finaldeliverydateformats = this.afterNDays(this.productDetail.DeliveryWeeksTo * 7, new Date(items.Date));
            this.finaldeliverydateformat = moment(this.finaldeliverydateformats).format("DD/MM/YYYY");
            this.userproductiondates = moment(this.shoppingCartData.DeliveryDate).format("DD/MM/YYYY");
            allownofuturepopup = true;
            this.case3 = true;
            const set = {
              originalFutureStock: items.Value,
              orderedQuantity: orderedQuantity,
              earliestDeliveryDate: items.EarliestDeliveryDate,
              restQuantity: this.restquantity,
            };
            this.calculatedFutureStock.push(set);
            if (this.shoppingCartData.ProductColors && this.shoppingCartData.ProductColors.length !== 0) {
              this.shoppingCartData.ProductColors[mainIndex].AvailableSizes[index]["SelectedDeliveryDate"] = items.EarliestDeliveryDate;
            }
            if (this.shoppingCartData.ProductSizes && this.shoppingCartData.ProductSizes.length !== 0) {
              this.shoppingCartData.ProductSizes[index]["SelectedDeliveryDate"] = items.EarliestDeliveryDate;
            }
          });

          if (allownofuturepopup) {
            this.showfuturestockwithoutstock = false;
            //    this.stockModal.show();
            allownofuturepopup = false;
          }
        }
        // tslint:disable-next-line:one-line
        else {
          if (this.productDetail.isInternalStockProduct === true) {
            this.isShowinquirybutton = true;
          } else {
            this.isShowinquirybutton = false;
          }
          if (this.shoppingCartData.ProductColors && this.shoppingCartData.ProductColors.length !== 0) {
            this.shoppingCartData.ProductColors[mainIndex].AvailableSizes[index].OrderedQuantity = null;
          }
          if (this.shoppingCartData.ProductSizes && this.shoppingCartData.ProductSizes.length !== 0) {
            this.shoppingCartData.ProductSizes[index].OrderedQuantity = null;
          }
          this.shoppingCartData.isSizesStepCompleted = false;
          this.productSizeCurrentQty = this.shoppingCartData.OrderedQuantity;
          this.stockModalTranslate();
          this.shoppingCartStockModal.show();
        }
      }
      // tslint:disable-next-line:one-line
      else {
        // this.param = {
        //   'DeliveryDate': '',
        //   'OrderQty': ''
        // }
        // this.readyToProcceedNext();
      }
    }
    this.getcalculatedArray(false, false, "");
  }

  proceedToShoppingCart(case1, case2, case3, isMultipleCall) {
    if (this.pageName === "color") {
      this.shoppingCartData.isColorStepCompleted = true;
    }
    // this.shoppingCartData.isSizesStepCompleted = true;
    if (this.lastTab === "productSize") {
      this.shoppingCartData.isChecked = true;
      // this.addOrUpdateShoppingCart('', false, false);
    }
    //  this.stepValidation(this.productCurrentQty, this.productDetail.StockBalance, 'isSizesStepCompleted')
    if (case1) {
      this.param = {
        DeliveryDate: "",
        OrderQty: "",
      };
    } else if (case2 && !isMultipleCall) {
      this.param = {
        DeliveryDate: moment(this.finalStockDeliveryDate).format("YYYY-MM-DD"),
        OrderQty: this.finalStockValue,
      };
      const oldProductColorArray = this.shoppingCartData.ProductColors;
      const oldProductSizes = this.shoppingCartData.ProductColors;

      this.oldProductColorArray = oldProductColorArray;
      this.oldProductSizes = oldProductSizes;
      this.addOrUpdateShoppingCart(this.pageName, true, false, "case2");
    } else if (case2 && isMultipleCall) {
      this.param = {
        DeliveryDate: moment(this.finalFutureDeliveryDate).format("YYYY-MM-DD"),
        OrderQty: this.restQuantity,
      };
      setTimeout(() => {
        this.addOrUpdateShoppingCart(this.pageName, true, true, "case2");
      }, 1000);
    } else if (case3 && !isMultipleCall) {
      this.param = {
        DeliveryDate: moment(this.finalFutureDeliveryDate).format("YYYY-MM-DD"),
        OrderQty: this.finalFutureValue,
      };
      this.addOrUpdateShoppingCart(this.pageName, true, false, "case3");
    } else if (case3 && isMultipleCall) {
      this.param = {
        DeliveryDate: moment(this.finalFutureDeliveryDate).format("YYYY-MM-DD"),
        OrderQty: this.finalFutureValue,
      };
      this.addOrUpdateShoppingCart(this.pageName, true, true, "");
    }
  }

  readyToProcceedNext() {
    if (this.steps[this.stepName]) {
      this.staticTabs.setActiveTab(this.steps[this.stepName] + 1);
    }
    this.getTabCompletedCount();
  }

  openFutureStockModel(array) {
    this.batchSizesFutureStock = array;
    this.firstTabSplitPopupOpened = false;
    this.futureStockModalTranslate();
    this.futureStockModal.show();
  }

  getcalculatedArray(status, isColor, page) {
    if (this.shoppingCartData.StockBalance === undefined) {
      // this.addOrUpdateShoppingCart(page, false, false, '', this.currentTabName);
      /** old-code => hide old code above line and make new code to restirct call addOrUpdateShoppingCart for Size tab when user enter qty */
      if (this.currentTabName !== "productSize" || this.commonPriceSectionAddToCartButtonStatus) {
        this.addOrUpdateShoppingCart(page, false, false, "", this.currentTabName);
      }
    } else {
      this.pageName = page;
      this.calculatedFutureStock = [];
      // tslint:disable-next-line:radix
      this.shoppingCartData.OrderedQuantity = parseInt(this.shoppingCartData.OrderedQuantity);
      // this.addOrUpdateShoppingCart('productSize', false, false)
      if (this.shoppingCartData.ProductColors && this.shoppingCartData.ProductColors.length !== 0) {
        this.shoppingCartData.ProductColors.forEach((elements) => {
          if (elements.AvailableSizes.length === 0 && this.shoppingCartData.ProductColorID === elements.ProductColorID) {
            let addStockValues = elements.StockBalance ? Number(elements.StockBalance) : 0;
            if (elements.StockBalance === undefined || elements.StockBalance < 0) {
              elements.StockBalance = 0;
            }
            let restQuantity = this.shoppingCartData.OrderedQuantity - elements.StockBalance;
            if (restQuantity < 0) {
              restQuantity = 0;
            }
            if (this.shoppingCartData.OrderedQuantity > 0) {
              if (this.shoppingCartData.OrderedQuantity > elements.StockBalance && elements.ProductFutureStock.length !== 0) {
                let allow = true;
                elements.ProductFutureStock.forEach((stock) => {
                  addStockValues += stock.Value;
                  if (allow) {
                    if (addStockValues >= this.shoppingCartData.OrderedQuantity) {
                      const pushData = {
                        size: elements.Name,
                        restQuantity: restQuantity,
                        isFutureStock: true,
                        futureStock: stock.Value,
                        orderQuantity: this.shoppingCartData.OrderedQuantity,
                        earliestDeliveryDate: stock.EarliestDeliveryDate,
                        stockBalance: elements.StockBalance,
                      };
                      this.calculatedFutureStock.push(pushData);
                      allow = false;
                    }
                  }
                });
              } else if (this.shoppingCartData.OrderedQuantity <= elements.StockBalance && elements.ProductFutureStock.length !== 0) {
                const pushData = {
                  size: elements.Name,
                  restQuantity: restQuantity,
                  orderQuantity: this.shoppingCartData.OrderedQuantity,
                  isFutureStock: false,
                  futureStock: 0,
                  earliestDeliveryDate: new Date(this.shoppingCartData.DeliveryDate),
                  stockBalance: elements.StockBalance,
                };
                this.calculatedFutureStock.push(pushData);
              } else if (this.shoppingCartData.OrderedQuantity > 0 && elements.ProductFutureStock.length === 0) {
                const pushData = {
                  size: elements.Name,
                  restQuantity: restQuantity,
                  orderQuantity: this.shoppingCartData.OrderedQuantity,
                  isFutureStock: false,
                  futureStock: 0,
                  earliestDeliveryDate: new Date(this.shoppingCartData.DeliveryDate),
                  stockBalance: elements.StockBalance,
                };
                this.calculatedFutureStock.push(pushData);
              }
            }
          } else {
            elements.AvailableSizes.forEach((element) => {
              let addStockValues = element.StockBalance ? Number(element.StockBalance) : 0;
              if (element.StockBalance === undefined || element.StockBalance < 0) {
                element.StockBalance = 0;
              }
              let restQuantity = element.OrderedQuantity - element.StockBalance;
              if (restQuantity < 0) {
                restQuantity = 0;
              }
              if (element.OrderedQuantity > 0) {
                if (element.OrderedQuantity > element.StockBalance && element.ProductFutureStock.length !== 0) {
                  let allow = true;
                  element.ProductFutureStock.forEach((stock) => {
                    addStockValues += stock.Value;
                    if (allow) {
                      if (addStockValues >= element.OrderedQuantity) {
                        const pushData = {
                          size: element.Name,
                          restQuantity: restQuantity,
                          isFutureStock: true,
                          futureStock: stock.Value,
                          orderQuantity: element.OrderedQuantity,
                          earliestDeliveryDate: stock.EarliestDeliveryDate,
                          stockBalance: element.StockBalance,
                        };
                        this.calculatedFutureStock.push(pushData);
                        allow = false;
                      }
                    }
                  });
                } else if (element.OrderedQuantity <= element.StockBalance && element.ProductFutureStock.length !== 0) {
                  const pushData = {
                    size: element.Name,
                    restQuantity: restQuantity,
                    orderQuantity: element.OrderedQuantity,
                    isFutureStock: false,
                    futureStock: 0,
                    earliestDeliveryDate: new Date(this.shoppingCartData.DeliveryDate),
                    stockBalance: element.StockBalance,
                  };
                  this.calculatedFutureStock.push(pushData);
                } else if (element.OrderedQuantity > 0 && element.ProductFutureStock.length === 0) {
                  const pushData = {
                    size: element.Name,
                    restQuantity: restQuantity,
                    orderQuantity: element.OrderedQuantity,
                    isFutureStock: false,
                    futureStock: 0,
                    earliestDeliveryDate: new Date(this.shoppingCartData.DeliveryDate),
                    stockBalance: element.StockBalance,
                  };
                  this.calculatedFutureStock.push(pushData);
                }
              }
            });
          }
        });
      }
      if (this.shoppingCartData.ProductColors && this.shoppingCartData.ProductColors.length === 0 && this.shoppingCartData.ProductSizes && this.shoppingCartData.ProductSizes.length !== 0) {
        this.shoppingCartData.ProductSizes.forEach((elements) => {
          let addStockValues = elements.StockBalance ? Number(elements.StockBalance) : 0;
          if (elements.StockBalance === undefined || elements.StockBalance < 0) {
            elements.StockBalance = 0;
          }
          let restQuantity = elements.OrderedQuantity - elements.StockBalance;
          if (restQuantity < 0) {
            restQuantity = 0;
          }
          if (elements.OrderedQuantity > 0) {
            if (elements.OrderedQuantity > elements.StockBalance && elements.ProductFutureStock.length !== 0) {
              let allow = true;
              elements.ProductFutureStock.forEach((stock) => {
                addStockValues += stock.Value;
                if (allow) {
                  if (addStockValues >= elements.OrderedQuantity) {
                    const pushData = {
                      size: elements.Name,
                      restQuantity: restQuantity,
                      isFutureStock: true,
                      futureStock: stock.Value,
                      orderQuantity: elements.OrderedQuantity,
                      earliestDeliveryDate: stock.EarliestDeliveryDate,
                      stockBalance: elements.StockBalance,
                    };
                    this.calculatedFutureStock.push(pushData);
                    allow = false;
                  }
                }
              });
            } else if (elements.OrderedQuantity <= elements.StockBalance && elements.ProductFutureStock.length !== 0) {
              const pushData = {
                size: elements.Name,
                restQuantity: restQuantity,
                orderQuantity: elements.OrderedQuantity,
                isFutureStock: false,
                futureStock: 0,
                earliestDeliveryDate: new Date(this.shoppingCartData.DeliveryDate),
                stockBalance: elements.StockBalance,
              };
              this.calculatedFutureStock.push(pushData);
            } else if (elements.OrderedQuantity > 0 && elements.ProductFutureStock.length === 0) {
              const pushData = {
                size: elements.Name,
                restQuantity: restQuantity,
                orderQuantity: elements.OrderedQuantity,
                isFutureStock: false,
                futureStock: 0,
                earliestDeliveryDate: new Date(this.shoppingCartData.DeliveryDate),
                stockBalance: elements.StockBalance,
              };
              this.calculatedFutureStock.push(pushData);
            }
          }
        });
      }

      this.finalStockDeliveryDate = new Date(
        this.shoppingCartData.DeliveryDate
      );
      this.finalFutureDeliveryDate = new Date(Math.max.apply(null, this.calculatedFutureStock.map(function (e) { return new Date(e.earliestDeliveryDate); })));
      let finalFutureValue = 0;
      let finalStockValue = 0;
      this.calculatedFutureStock.forEach((element) => {
        if (element.isFutureStock) {
          finalFutureValue += Number(element.restQuantity);
          if (element.restQuantity > 0 && element.stockBalance > 0) {
            finalStockValue += Number(element.stockBalance);
          }
        } else {
          finalStockValue += Number(element.orderQuantity);
        }
      });
      this.finalFutureValue = finalFutureValue + finalStockValue;
      this.finalStockValue = finalStockValue;
      this.restQuantity = Math.abs(this.finalFutureValue - finalStockValue);
      if (status) {
        //console.log("this.calculatedFutureStock : ", this.calculatedFutureStock);
        if (page === "color" && isColor) {
          let indexColor = this.productSizesindex;
          if (indexColor < 0) {
            indexColor = 0;
          }
          if (this.shoppingCartData.isDisabled || this.shoppingCartData.ProductColors[indexColor].AvailableSizes.length !== 0) {
            this.shoppingCartData.isColorStepCompleted = true;
            this.addOrUpdateShoppingCart("color", false, false, "", this.currentTabName, false, false);
          } else if (this.calculatedFutureStock.length === 1) {
            if (this.calculatedFutureStock[0].futureStock === 0 && this.calculatedFutureStock[0].stockBalance >= this.calculatedFutureStock[0].orderQuantity) {
              this.shoppingCartData.isColorStepCompleted = true;
              this.addOrUpdateShoppingCart("color", false, false, "", this.currentTabName, false, false);
            } else {
              if (new Date(this.finalStockDeliveryDate) >= new Date(this.finalFutureDeliveryDate)) {
                this.shoppingCartData.isColorStepCompleted = true;
                this.addOrUpdateShoppingCart("color", false, false, "", this.currentTabName, false, false);
              } else {
                this.firstTabSplitPopupOpened = false;
                this.shoppingCartData.isColorStepCompleted = false;
                this.futureStockModalTranslate();
                if (Number(this.calculatedFutureStock[0].stockBalance !== 0)) {
                  this.futureStockModal.show();
                } else {
                  this.proceedToShoppingCart(false, false, true, false);
                  this.fixQuantity(this.finalFutureValue);
                }
              }
            }
          } else {
            if (
              new Date(this.finalStockDeliveryDate) >=
              new Date(this.finalFutureDeliveryDate)
            ) {
              this.shoppingCartData.isColorStepCompleted = true;
              this.addOrUpdateShoppingCart("color", false, false, "", this.currentTabName, false, false);
            } else {
              this.firstTabSplitPopupOpened = false;
              this.shoppingCartData.isColorStepCompleted = false;
              this.futureStockModalTranslate();
              this.futureStockModal.show();
            }
          }
        } else if (page === "productSize") {
          if (this.shoppingCartData.isDisabled) {
            this.addOrUpdateShoppingCart("productSize", false, false, "", this.currentTabName);
          } else {
            let futureStockallow = false;
            let stockCount = 0;
            let futureCount = 0;
            this.calculatedFutureStock.forEach((element) => {
              if (element.futureStock === 0 && element.stockBalance >= element.orderQuantity) {
                stockCount += stockCount;
              } else {
                futureCount += futureCount;
                futureStockallow = true;
              }
            });
            if (futureStockallow) {
              if (new Date(this.finalStockDeliveryDate) >= new Date(this.finalFutureDeliveryDate)) {
                this.addOrUpdateShoppingCart("productSize", false, false, "", this.currentTabName);
              } else {
                this.firstTabSplitPopupOpened = false;
                this.futureStockModalTranslate();
                this.futureStockModal.show();
              }
            } else {
              this.addOrUpdateShoppingCart("productSize", false, false, "", this.currentTabName);
            }
          }
        }
      } else {
        this.reFormSizesArray();
      }
    }
  }

  reFormSizesArray() {
    if (this.shoppingCartData.ProductColors && this.shoppingCartData.ProductColors.length !== 0) {
      this.shoppingCartData.ProductColors.forEach((elements) => {
        elements.AvailableSizes.forEach((element) => {
          if (element.SelectedDeliveryDate !== undefined) {
            element.SelectedDeliveryDate = moment(this.finalFutureDeliveryDate).format("YYYY-MM-DD");
          }
        });
      });
    }
    if (
      this.shoppingCartData.ProductColors && this.shoppingCartData.ProductColors.length === 0 && this.shoppingCartData.ProductSizes && this.shoppingCartData.ProductSizes.length !== 0) {
      this.shoppingCartData.ProductSizes.forEach((element) => {
        if (element.SelectedDeliveryDate !== undefined) {
          element.SelectedDeliveryDate = moment(this.finalFutureDeliveryDate).format("YYYY-MM-DD");
        }
      });
    }
  }

  stockModalTranslate() {
    // Translation Variables for stockModal
    // this.noFutureStock = this.translate.get('HEADING.THERE_IS_NO_FUTURE_STOCK');
    if (this.productDetail.ProductionProductID == undefined && this.productDetail.ProductionProductID == null) {
      this.translate.get("HEADING.THERE_IS_NO_FUTURE_STOCK").subscribe((res: string) => {
        this.noFutureStock = res;
      });
    } else {
      this.translate.get("HEADING.THERE_IS_NO_FUTURE_STOCK_ISP_PRODUCTION_PRODUCT").subscribe((res: string) => {
        this.noFutureStock = res;
      });
    }
    this.noFutureStockText = this.noFutureStock;

    // this.noFutureStockQty = this.translate.get('HEADING.THERE_IS_NO_FUTURE_STOCK_QUANTITY');
    if (this.productDetail.ProductionProductID == undefined && this.productDetail.ProductionProductID == null) {
      this.translate.get("HEADING.THERE_IS_NO_FUTURE_STOCK_QUANTITY").subscribe((res: string) => {
        this.noFutureStockQty = res;
      });
    } else {
      this.translate.get("HEADING.THERE_IS_NO_FUTURE_STOCK_QUANTITY_ISP_PRODUCTION_PRODUCT").subscribe((res: string) => {
        this.noFutureStockQty = res;
      });
    }
    this.noFutureStockQtyText = this.noFutureStockQty;

    // this.loginCancel = this.translate.get('LOGIN.CANCEL');
    this.translate.get("LOGIN.CANCEL").subscribe((res: string) => {
      this.loginCancel = res;
    });
    this.loginCancelText = this.loginCancel;

    this.inquiry = this.translate.get("HEADING.INQUIRY");
    this.translate.get("HEADING.INQUIRY").subscribe((res: string) => {
      this.inquiry = res;
    });
    this.inquiryText = this.inquiry;
  }

  futureStockModalTranslate() {
    // Translation Variables for futureStockModal
    // this.futureStockZero = this.translate.get('HEADING.THERE_IS_FUTURE_STOCK_0');
    this.translate.get("HEADING.THERE_IS_FUTURE_STOCK_0").subscribe((res: string) => {
      this.futureStockZero = res;
    });
    this.futureStockZeroText = this.futureStockZero;

    // this.futureStock3 = this.translate.get('HEADING.THERE_IS_FUTURE_STOCK_3');
    this.translate.get("HEADING.THERE_IS_FUTURE_STOCK_3").subscribe((res: string) => {
      this.futureStock3 = res;
    });
    this.futureStock3Text = this.futureStock3;

    // this.futureStock31 = this.translate.get('HEADING.THERE_IS_FUTURE_STOCK_3.1');
    this.translate.get("HEADING.THERE_IS_FUTURE_STOCK_3.1").subscribe((res: string) => {
      this.futureStock31 = res;
    });
    this.futureStock31Text = this.futureStock31;

    // this.futureTransportationFee = this.translate.get('HEADING.THERE_IS_FUTURE_TRANSPORTATION_FEE');
    this.translate.get("HEADING.THERE_IS_FUTURE_TRANSPORTATION_FEE").subscribe((res: string) => {
      this.futureTransportationFee = res;
    });
    this.futureTransportationFeeText = this.futureTransportationFee;

    // this.stockBalance = this.translate.get('HEADING.STOCK_BALANCE');
    this.translate.get("HEADING.STOCK_BALANCE").subscribe((res: string) => {
      this.stockBalance = res;
    });
    this.stockBalanceText = this.stockBalance;

    // this.restQty = this.translate.get('PRODUCT_DETAIL.REST_QUANTITY');
    this.translate.get("PRODUCT_DETAIL.REST_QUANTITY").subscribe((res: string) => {
      this.restQty = res;
    });
    this.restQtyText = this.restQty;

    // this.proceed = this.translate.get('PRODUCT_DETAIL.PROCEED');
    this.translate.get("PRODUCT_DETAIL.PROCEED").subscribe((res: string) => {
      this.proceed = res;
    });
    this.proceedText = this.proceed;

    // this.stock2Calculated = this.translate.get('HEADING.THERE_IS_FUTURE_STOCK_2_CALCULATED');
    this.translate.get("HEADING.THERE_IS_FUTURE_STOCK_2_CALCULATED").subscribe((res: string) => {
      this.stock2Calculated = res;
    });
    this.stock2CalculatedText = this.stock2Calculated;

    // this.fullQty = this.translate.get('PRODUCT_DETAIL.FULL_QUANTITY');
    this.translate.get("PRODUCT_DETAIL.FULL_QUANTITY").subscribe((res: string) => {
      this.fullQty = res;
    });
    this.fullQtyText = this.fullQty;

    //  this.loginCancel = this.translate.get('LOGIN.CANCEL');
    this.translate.get("LOGIN.CANCEL").subscribe((res: string) => {
      this.loginCancel = res;
    });
    this.loginCancelText = this.loginCancel;
  }

  /** new-code => for automatic API call when QTY change */
  callShoppingCartAPI(_event) {

    if (this.shoppingCartItemId === null || this.shoppingCartItemId === undefined || this.shoppingCartItemId === "") {
      /** new-code => callProceedStatus based on Stock balance of Product compare with enter QTY */
      //console.log("callShoppingCartAPI : ", event);
      const breadCrumbsList = JSON.parse(sessionStorage.getItem("breadCrumbsList"));
      //console.log("this.productDetail.SelectedDeliveryDate : ", this.productDetail.SelectedDeliveryDate);
      console.log("this.expressOrderDate : ", this.FileName);
      //  var deliveryDateStr = this.productDetail.SelectedDeliveryDate;
      /** new-code */
      let addDeliveryDate = this.productDetail.SelectedDeliveryDate;
      if (this.expressOrderDate !== undefined) {
        addDeliveryDate = this.expressOrderDate;
      }
      let deliveryDateStr = addDeliveryDate;
      /** new-code */
      this.shoppingCartDeliveryDate = this.expressOrderDate; // new-code for delivery date
      var searchUrl = breadCrumbsList[breadCrumbsList.length - 1].url;
      const idxDeliveryDate = searchUrl.indexOf("DeliveryDate=");
      if (idxDeliveryDate >= 0 && searchUrl.length > idxDeliveryDate + 13) {
        searchUrl = searchUrl.substring(idxDeliveryDate);
        if (searchUrl.indexOf("&") === -1) {
          deliveryDateStr = searchUrl.substring(13);
        } else {
          const dateSearchurl = searchUrl.substring(13, searchUrl.indexOf("&"));
          if (dateSearchurl !== "") {
            deliveryDateStr = dateSearchurl.split("%2F").join("-");
          }
        }
      }
      if (deliveryDateStr.indexOf("%2F") > 0) {
        deliveryDateStr = deliveryDateStr.split("%2F").join("-");
      }
      const data = {
        DeliveryDate: deliveryDateStr,
      };
      this.autoCallShoppingCartAPIStatus = true;
      if (data.DeliveryDate !== "" && data.DeliveryDate !== null) {
        this.shoppingCartAddItem(data, 0, "neworder", true);
      }
    } else {
      setTimeout(() => {
        let orderQty = 0;
        let callProceedStatus = true;
        if (this.shoppingCartData.isVisible) {
          if (this.productDetail.ProductBatchID !== undefined) {
            if (this.productDetail.ProductBatchColors && this.productDetail.ProductBatchColors.length !== 0) {
              let approve = true;
              if (this.selectColorId === null || this.selectColorId === undefined) {
                this.productDetail.ProductBatchColors.forEach((element) => {
                  if (approve) {
                    if (Number(element.TotalFutureStockQuantity + element.StockBalance) >= Number(this.orderQuantityParent)) {
                      approve = false;
                      callProceedStatus = true;
                    } else {
                      approve = true;
                      callProceedStatus = false;
                    }
                  }
                });
              } else {
                const selectedColor =
                  this.productDetail.ProductBatchColors.filter((option: any) => {
                    return option.ProductColorID === this.selectColorId;
                  });
                if (selectedColor[0] !== undefined) {
                  if (selectedColor[0].TotalFutureStockQuantity + selectedColor[0].StockBalance >= Number(this.orderQuantityParent)) {
                    approve = false;
                    callProceedStatus = true;
                  }
                }
              }
              if (this.productDetail.StockBalance === undefined) {
                approve = false;
                callProceedStatus = true;
              }
              if (!approve) {
                callProceedStatus = true;
              } else {
                this.productDetail.ProductBatchColors.forEach((element: any) => {
                  if (element.ProductFutureStock && element.ProductFutureStock.length !== 0) {
                    this.case1 = true;
                    this.case2 = false;
                  } else {
                    this.case1 = false;
                    this.case2 = true;
                  }
                });
                if (this.productDetail.isInternalStockProduct) {
                  this.isShowinquirybutton = true;
                } else {
                  this.isShowinquirybutton = false;
                }
                this.productStockModal.show();
                callProceedStatus = false;
              }
            } else {
              callProceedStatus = true;
            }
          } else {
            callProceedStatus = true;
          }
        }
        // get Delivery Date and Dynamic Order QTY

        // After Click Add to CART in NEW/JOIN Order Section based on Session(available means it is update, if not available means then it is a new one)

        // if ((sessionStorage.getItem('joinOrderData') !== undefined && sessionStorage.getItem('joinOrderData') !== null) ||
        //   (sessionStorage.getItem('newOrderData') !== undefined && sessionStorage.getItem('newOrderData') !== null)) {
        if (callProceedStatus) {
          if (this.productDetail.FixedUnits) {
            orderQty = this.productDetail.PiecesPerUnit * this.orderQuantityParent;
            if (this.joinOrderProductStatus) {
              orderQty = this.productDetail.PiecesPerUnit * Number(this.orderQuantityParent);
            }
          } else {
            orderQty = this.orderQuantityParent;
            if (this.joinOrderProductStatus) {
              orderQty = Number(this.orderQuantityParent);
            }
          }
          let productVisibleStatus = true;
          // tslint:disable-next-line:max-line-length
          if (this.autoCallShoppingCartAPIStatus || sessionStorage.getItem("joinOrderData") !== null || sessionStorage.getItem("newOrderData") !== null) {
            this.autoCallShoppingCartAPIStatus = true;
            productVisibleStatus = false;
          } else {
            this.autoCallShoppingCartAPIStatus = false;
          }
          this.shoppingCartData.OrderedQuantity = orderQty;
          this.shoppingCartData.isVisible = productVisibleStatus;
          this.shoppingCartData.isChecked = productVisibleStatus;
          //console.log(this.shoppingCartData);
          this.shoppingCartDeliveryDate = this.expressOrderDate; // new-code for delivery date
          this.addOrUpdateShoppingCart("initial", false, false, "", this.currentTabName, false, false);
        }
      }, 100);
    }
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy() {
    // Remove class in body tag
    const bodytag = document.getElementsByTagName("body")[0];
    bodytag.classList.remove("productModal"); // remove the class
  }

  openProductDetailModel(productID, shoppingCartItemId) {
    try {
      // alert("openProductDetailModel");
      let initialDeliveryCountry = "";
      const webShopSettings = this.globalService.webShopSettings();
      const loggedInData = this.globalService.getLoggedInData();
      if (this.shoppingCartItemId === null || this.shoppingCartItemId === undefined || this.shoppingCartItemId === "") {
        initialDeliveryCountry = webShopSettings.Country;
      }
      if (loggedInData !== null) {
        initialDeliveryCountry = loggedInData.DeliveryCountry;
      }

      this._httpProductService.getProductDetail(productID, this.lang, true, initialDeliveryCountry).subscribe((data) => {
        if(data){
        let set = data["Name"];
        set = set.replace(/\//g, "");
        set = set.replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");
        if (shoppingCartItemId === "") {
          window.history.replaceState(" ", "", "/productdetail/" + data["ProductID"] + "/" + set);
          if (data["ShoppingCartItem"] !== null && data["ShoppingCartItem"] !== undefined && data["ShoppingCartItem"] !== "") {
            // alert("Inside Product Detail with SHopping cart item array in Response");
            this.responseData = data["ShoppingCartItem"];
            this.earliestDeliveryDate = data["ShoppingCartItem"]["EarliestDeliveryDate"];
            // tslint:disable-next-line:max-line-length
            this.earliestDeliveryDateForNumOfDaysTillProductStart = data["ShoppingCartItem"]["EarliestDeliveryDateForNumOfDaysTillProductStart"];
            // tslint:disable-next-line:max-line-length
            this.calculateDays(data["ShoppingCartItem"]["EarliestDeliveryDateForNumOfDaysTillProductStart"], data["ShoppingCartItem"]["DeliveryDate"]);

            this.shoppingCartData = data["ShoppingCartItem"];

            //console.log('this.shoppingCartData openProductDetailModel');
            //console.log(this.shoppingCartData);
            //  this.getProductDetails(this.shoppingCartData.ProductID)
            this.sumOrderedQuantity = Number(this.shoppingCartData.OrderedQuantity) + Number(this.shoppingCartData.ProductionLineOrderedQuantity);
            /** new-code => preselect color if product have only one */
            if (this.shoppingCartItemId === null || this.shoppingCartItemId === undefined || this.shoppingCartItemId === "") {
              if (this.shoppingCartData.ProductColors && this.shoppingCartData.ProductColors.length === 1) {
                this.shoppingCartData.isColorStepCompleted = true;
                if (this.shoppingCartData.ProductColors[0].ParentColorID !== undefined && this.shoppingCartData.ProductColors[0].ParentColorID !== null) {
                  this.selectColorId = Number(this.shoppingCartData.ProductColors[0].ParentColorID);
                } else {
                  this.selectColorId = Number(this.shoppingCartData.ProductColors[0].ProductColorID);
                }
              }
            }
            /** new-code => preselect color if product have only one */

            for (let j = 0; j < this.shoppingCartData.ProductProperties.length; j++) {
              if (this.shoppingCartData.ProductProperties[j].SelectedOptionID > 0) {
                this.shoppingCartData.ProductProperties[j].SelectedOptionID = this.shoppingCartData.ProductProperties[j].SelectedOptionID + "";
              }
            }

            this.deliveryDate = this.datepipe.transform(this.shoppingCartData.DeliveryDate, "dd-MM-yyyy");
            this.shoppingCartDeliveryDate = this.datepipe.transform(this.shoppingCartData.DeliveryDate, "yyyy-MM-dd");
            if (this.shoppingCartData.isSampleWithoutLogo || this.shoppingCartData.isSampleWithLogo) {
              this.initialDate =
                this.shoppingCartData.DeliveryDate.split("-");
            } else {
              this.initialDate =
                this.shoppingCartData.EarliestDeliveryDate.split("-");
            }
            this.initialDateSplit = this.initialDate[2].split("T");
            this.initialDateSplit[0] = this.initialDateSplit[0] - 1;
            this.myOptions = {
              dateFormat: "dd-mm-yyyy",
              disableUntil: {
                year: this.initialDate[0],
                month: this.initialDate[1],
                day: this.initialDateSplit[0],
              },
            };
            // tslint:disable-next-line:max-line-length
            // if (this.shoppingCartData.Logos.length > 0 && !this.shoppingCartData.isSampleWithoutLogo && this.shoppingCartData.isFixedLogo !== true) {
            if (this.shoppingCartData.Logos.length > 0 && !this.shoppingCartData.isSampleWithoutLogo) {
              this.steps["logo"] = this.tabCount = this.tabCount + 1;
              this.lastTab = "logo";
            }
            if (this.shoppingCartData.ProductColors.length === 1) {
              this.shoppingCartData.ProductColorID = this.shoppingCartData.ProductColors[0].ProductColorID;
            }
            if (this.shoppingCartData.ProductColors.length >= 1) {
              this.steps["color"] = this.tabCount = this.tabCount + 1;
              this.lastTab = "color";
            }
            if (this.shoppingCartData.ProductSizes.length > 0) {
              this.steps["productSize"] = this.tabCount = this.tabCount + 1;
              this.lastTab = "productSize";
            }
            if ((this.shoppingCartData.AdditionalCosts && this.shoppingCartData.AdditionalCosts.length > 0) || (this.shoppingCartData.Price && this.shoppingCartData.Price.GovernmentalCosts && this.shoppingCartData.Price.GovernmentalCosts.length > 0 && this.userId !== null)) {
              this.steps["additionalCost"] = this.tabCount = this.tabCount + 1;
              this.lastTab = "additionalCost";
            }
            if (this.shoppingCartData.ProductProperties.length > 0) {
              this.steps["productProperty"] = this.tabCount = this.tabCount + 1;
              this.lastTab = "productProperty";
            }

            if (this.tabCount === 1) {
              this.shoppingCartData.isChecked = true;
              /** new-code => For Checkout Disable */
              this.oneTabStatus = true;
              /** new-code => For Checkout Disable */
            }

            if (this.shoppingCartData.FixedUnits) {
              this.shoppingCartData.units = this.shoppingCartData.OrderedQuantity / this.shoppingCartData.PiecesPerUnit;
            }
            this.shoppingCartData.isInitialStepCompleted = true;
            this.checked = { true: "check-", false: "", null: "" };
            for (let j = 0; j < this.shoppingCartData.ProductColors.length; j++) {
              // if ((this.shoppingCartData.ProductColorID === this.shoppingCartData.ProductColors[j].ProductColorID)
              //   || (this.shoppingCartData.ProductColorID === undefined)) {
              //   if (this.shoppingCartData.ProductColors[j].AvailableSizes.length > 0) {
              //     this.productSizes = this.shoppingCartData.ProductColors[j].AvailableSizes;
              //     this.productSizesindex = j;
              //   }
              // }
              if (this.shoppingCartData.ProductColorID === this.shoppingCartData.ProductColors[j].ProductColorID) {
                if (this.shoppingCartData.ProductColors[j].AvailableSizes.length > 0) {
                  this.productSizes = this.shoppingCartData.ProductColors[j].AvailableSizes;
                  this.productSizesindex = j;
                }
              }
            }
            for (let j = 0; j < this.shoppingCartData.AdditionalCosts.length; j++) {
              this.shoppingCartData.isAdditionalCostsStepCompleted = true;
              if (!this.shoppingCartData.AdditionalCosts[j].isOptionalForBuyer) {
                this.shoppingCartData.AdditionalCosts[j].isSelected = true;
              }
            }
            if ((this.shoppingCartData.AdditionalCosts && this.shoppingCartData.AdditionalCosts.length) === 0 && (this.shoppingCartData.Price && this.shoppingCartData.Price.GovernmentalCosts && this.shoppingCartData.Price.GovernmentalCosts.length) > 0) {
              this.shoppingCartData.isAdditionalCostsStepCompleted = true;
            }
            if (this.shoppingCartData.isSampleWithoutLogo === true) {
              this.shoppingCartData.isProceedWithoutLogo = true;
            }
            this.logoCompleted(false, "", false);
            if (this.shoppingCartData.isDisabled && this.tabCount !== 1) {
              this.shoppingCartData.isColorStepCompleted = true;
            }
            if (this.shoppingCartData.isDisabled && window.sessionStorage.showWidget === "true") {
              this.productCartModal.showProductCartModal();
              window.sessionStorage.showWidget = false;
            }

            this.translate.get("PRODUCT_DETAIL.RECOMMENDED").subscribe((res: string) => {
              // tslint:disable-next-line:max-line-length
              this.shoppingCartData.ProductLogoPositions.forEach((element) => {
                if (element.isDefault)
                  element.RecommendedText = " (" + res + ")";
              });
              this.shoppingCartData.ProductLogoTypes.forEach((element) => {
                if (element.isDefault)
                  element.RecommendedText = " (" + res + ")";
              });
              this.shoppingCartData.ProductLogoMaxAreas.forEach((element) => {
                if (element.isDefault)
                  element.RecommendedText = " (" + res + ")";
              });
            });

            for (let i = 0; i < this.shoppingCartData.Logos.length; i++) {
              this.shoppingCartData.Logos[i].ProductPrintPrices = this.shoppingCartData.ProductPrintPrices;
              this.shoppingCartData.Logos[i].ProductLogoPositions = this.shoppingCartData.ProductLogoPositions;
              this.shoppingCartData.Logos[i].ProductLogoTypes = this.shoppingCartData.ProductLogoTypes;
              this.shoppingCartData.Logos[i].ProductLogoMaxAreas = this.shoppingCartData.ProductLogoMaxAreas;

              if (this.shoppingCartData.DefaultLogoPositionID != undefined && this.shoppingCartData.Logos[i].PrintPriceID == undefined) {
                //this.shoppingCartData.Logos[i].LogoPositionID = this.shoppingCartData.DefaultLogoPositionID;
                this.shoppingCartData.Logos[i].LogoPositionID = "";
              } else if (this.shoppingCartData.Logos[i].PrintPriceID == undefined || this.shoppingCartData.Logos[i].LogoPositionID == undefined) {
                this.shoppingCartData.Logos[i].LogoPositionID = "";
              }

              if (this.shoppingCartData.DefaultLogoTypeID != undefined && this.shoppingCartData.Logos[i].PrintPriceID == undefined) {
                //this.shoppingCartData.Logos[i].LogoTypeID  = this.shoppingCartData.DefaultLogoTypeID;
                this.shoppingCartData.Logos[i].LogoTypeID = "";
              } else if (this.shoppingCartData.Logos[i].PrintPriceID == undefined) {
                this.shoppingCartData.Logos[i].LogoTypeID = "";
              }

              if (this.shoppingCartData.DefaultLogoMaxAreaID != undefined && this.shoppingCartData.Logos[i].PrintPriceID == undefined) {
                //this.shoppingCartData.Logos[i].LogoMaxAreaID  = this.shoppingCartData.DefaultLogoMaxAreaID;
                this.shoppingCartData.Logos[i].LogoMaxAreaID = "";
              } else if (this.shoppingCartData.Logos[i].PrintPriceID == undefined || this.shoppingCartData.Logos[i].LogoMaxAreaID == undefined) {
                this.shoppingCartData.Logos[i].LogoMaxAreaID = "";
              }

              this.applyLogoFilters(this.shoppingCartData.Logos[i]);
            }

            //  this.getTransportationhandlingcosts(this.shoppingCartData.OrderedQuantity);
          }
        } else {
          window.history.replaceState(" ", "", "/productdetail/" + data["ProductID"] + "/" + set + "/" + shoppingCartItemId);
        }
        this.productDetail = data;
        console.log("check",this.productDetail)
        if (this.productDetail.Brand !== 0) {
          const loggedInData = this.globalService.getLoggedInData();
     
          let tempCampaign = this.campaignService.getActiveCampaign().subscribe((res) => {
            let params = {};
            if(res){
              let campaignID = res.CampaignId;
              params = {
                UserID: loggedInData.UserID,
                CampignID: campaignID
              }
              
            }else{
              params = {
                UserID: loggedInData.UserID,
                CampignID: 0
              }
            }
          this._httpWebshopService.getBrands(params).subscribe((data: any) => {
            for (let b = 0; b < data.length; b++) {
              if (data[b].BrandId === this.productDetail.Brand) {
                this.productDetail.brandName = data[b].BrandName;
              }
            }
          });
        });
        }
        //(this.productDetail);
        /** new-code => to get product minimum QTY */
        if (this.productDetail.FixedUnits) {
          this.minimumOrderQty = Math.floor(this.productDetail.MinimumOrder / this.productDetail.PiecesPerUnit);
        } else {
          this.minimumOrderQty = this.productDetail.MinimumOrder;
        }
        /** new-code => to get product minimum QTY */

        /** new-code => to get product Maximum QTY */
        const priceArrayLength = this.productDetail.ProductPrices.length;
        if (priceArrayLength !== 0) {
          this.productMaxInputValue = this.productDetail.ProductPrices[priceArrayLength - 1].MaximumQuantity;
        }
        /** new-code => to get product Maximum QTY */

        if (data["PriorityProductionLineID"] !== undefined) {
          /** new-code => to find whether the product is Join Order */
          this.joinOrderProductStatus = true;
          /** new-code => to find whether the product is Join Order */
          this.getProductionLineData();
        }
        // to get Max Input Value (Price Highest Value)

        this.expressOrderDate = this.productDetail.SelectedDeliveryDate;
        if (!this.productDetail.FixedUnits) {
          this.orderQuantityParent = this.productDetail.MinimumOrder;
        }
        if (this.productDetail.FixedUnits) {
          this.orderQuantityParent = this.productDetail.MinimumOrder / this.productDetail.PiecesPerUnit;
        }

        /** new-code */
        if (this.shoppingCartItemId !== null && this.shoppingCartItemId !== undefined && this.shoppingCartItemId !== "") {
         
          if (this.shoppingCartData && !this.shoppingCartData.FixedUnits) {
              this.orderQuantityParent = this.shoppingCartData.OrderedQuantity;
          }
        
          if (this.productDetail && this.productDetail.FixedUnits) {
            this.orderQuantityParent = this.shoppingCartData.OrderedQuantity / this.shoppingCartData.PiecesPerUnit;
          }
        }
        /** new-code */

        // If user searched by quantity, put that value as initial quantity on product details page
        const breadCrumbsList = JSON.parse(sessionStorage.getItem("breadCrumbsList"));
        var searchUrl = breadCrumbsList[breadCrumbsList.length - 1].url;
        const idxQuantity = searchUrl.indexOf("Quantity=");
        var quantityStr = "";
        if (idxQuantity >= 0 && searchUrl.length > idxQuantity + 9) {
          searchUrl = searchUrl.substring(idxQuantity);
          if (searchUrl.indexOf("&") === -1) {
            quantityStr = searchUrl.substring(9);
          } else {
            quantityStr = searchUrl.substring(9, searchUrl.indexOf("&"));
          }
        }
        if (quantityStr !== "" && Number(quantityStr) >= this.productDetail.MinimumOrder) {
          this.orderQuantityParent = Number(quantityStr);
          if (this.productDetail.FixedUnits) {
            this.orderQuantityParent = Math.floor(this.orderQuantityParent / this.productDetail.PiecesPerUnit);
          }
        }

        if (this.productDetail["ProductColors"] && this.productDetail["ProductColors"].length > 0) {
          this.stepCount = this.stepCount + 1;
        }
        if (this.productDetail["ProductSizes"] && this.productDetail["ProductSizes"].length > 0) {
          this.stepCount = this.stepCount + 1;
        }
        if (this.productDetail["ProductProperties"] && this.productDetail["ProductProperties"].length > 0) {
          this.stepCount = this.stepCount + 1;
        }
        if (!this.productDetail["IsLogoDisabled"]) {
          this.stepCount = this.stepCount + 1;
        }
        this.productDetail.ProductPrices = this.productDetail.ProductPrices.filter(function (val) {
          return val;
        });
        this.showBrandingMethod = false;
        this.productDetail.ProductPrices.forEach((element, index) => {
          if (element.ProductPrintPrices.length > 0 && !this.showBrandingMethod) {
            this.showBrandingMethod = true;
            window.sessionStorage.printPriceIndex = index;
          }
        });
        this.callProductDetailAPI.emit(data);
        }
      }, (error) => {
        if (error.status === 500) {
          this.noProductModal.show();
        } else {
          this.toastr.error(error);
        }
      });
      if (JSON.parse(sessionStorage.getItem("colorsList")) === undefined || JSON.parse(sessionStorage.getItem("colorsList")) === null) {
        this._httpProductService.getProductColors(true).subscribe((data: any[]) => {
          this.colorsList = data;
          sessionStorage.setItem("colorsList", JSON.stringify(this.colorsList));
        }, (_error) => {
          //console.log('error', error)
          // this.toastService.error('Error: ' + error._body);
          // this.toastr.error('Error: ' + error.error.Message);
        }
        );
      } else {
        this.colorsList = JSON.parse(sessionStorage.getItem("colorsList"));
      }
      
    }
     catch (error) {
      this.toastr.error(error);
    }
  
  }

  changeNewOrderValues(params) {
    try {
      this.hideJoinOrders = false;
      if (params.checked) {
        this.hideJoinOrders = true;
      }
      const productionStartDays = this.newOrderComponent.setIsExpressOrder(params);
      this.newOrderValues = {
        ProductionStartDays: productionStartDays,
        DeliveryDate: params.date,
        MinimumDeliveryDate: params.MinimumDeliveryDate ? params.MinimumDeliveryDate : params.date,
      };
    } catch (error) {
      this.toastr.error(error);
    }
  }

  getProductionLineData() {
    try {
      let userId;
      const userData = this.globalService.getLoggedInData();
      if (userData === null || userData === undefined) {
        userId = null;
      } else {
        userId = userData.UserID;
      }
      const postParams = {
        productID: this.productDetail.ProductID,
        userID: userId,
      };
      this._httpProductService.getProductionLineDetails(postParams).subscribe((data) => {
        this.productionLineList = data;
        //console.log("get Product productionLineList : ", this.productionLineList);
      },
        (_error) => {
          // this.toastService.error(error);
        }
      );
    } catch (error) {
      // this.toastService.error(error);
    }
  }

  updateOrderQty(param) {
    try {
      this.orderQuantity = param;
      if (param > 0) {
        this.productDetail.ProductPrices.forEach((element) => {
          if (
            param >= element.MinimumQuantity &&
            element.MaximumQuantity >= param
          ) {
            this.currQty = param;
          }
        });
        this.orderQuantityParent = param;
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }
  goBack(): void {
    this.location.back();
  }

  orderSample(isSampleWithLogo, isSampleWithoutLogo) {
    this.shoppingCartAddItem({ isSampleWithLogo: isSampleWithLogo, isSampleWithoutLogo: isSampleWithoutLogo }, 0, undefined);
  }

  enquiryCount(data) {
    if (this.isParentCustomer) {
      if (data.OrderQty > 500) {
        this.inquiryModal.showInquiryModal(this.productDetail);
      }
    } else {
      if (data.OrderQty > 50) {
        //this.inquiryModal.showInquiryModal(this.productDetail);
        this.toastr.error("Maximum order quantity is 50")
      }
    }
  }

  shoppingCartAddItem(data, joinOrderStatus, orderName, autoShoppingCartAPICall = false, isProceedWithoutLogo = false) {
    /** Multiple API Call based on Future Stock have Two Options for Single proceed Button
     * "THERE_IS_FUTURE_STOCK_3": " Send me what you got now",
     * "THERE_IS_FUTURE_STOCK_3.1": " and the rest on",
     */

    if (!this.isParentCustomer) {
      let a = this.commonServicesService.getPoints().subscribe((data) => {
        this.balancePoints = data;
      });

      a.unsubscribe();
      if (this.balancePoints) {
        /*if (this.balancePoints < data.totalPrice * this.orderQuantityParent) {
          this.toastr.error("oops! Insufficent points to proceed");
          return false;
        }*/
      }
    }

    if (data.isMultipleCall !== null && data.isMultipleCall !== undefined) {
      //console.log("data.isMultipleCall Status : ", data);
      if ((data.isMultipleCall || !data.isMultipleCall) && data.isMultipleCall !== "") {
        if (sessionStorage.getItem("newOrderData") !== null && sessionStorage.getItem("newOrderData") !== undefined) {
          sessionStorage.removeItem("newOrderData");
        }
        this.shoppingCartItemId = "";
        window.history.replaceState(" ", "", "/productdetail/" + this.productDetail.ProductID + "/" + this.productDetail.Name);
        this.shoppingCartDeliveryDate = data.DeliveryDate;
      }
    }
    /** Multiple API Call based on Future Stock have Two Options for Single proceed Button
     * "THERE_IS_FUTURE_STOCK_3": " Send me what you got now",
     * "THERE_IS_FUTURE_STOCK_3.1": " and the rest on",
     */

    this.newOrderButtonClick = false;
    this.joinOrderButtonClick = false;
    if (data.newOrderButtonClickStatus !== undefined) {
      this.newOrderButtonClick = true;
    }
    if (data.joinOrderButtonClickStatus !== undefined) {
      this.joinOrderButtonClick = true;
    }

    /** new-code => To check product selected color have enough stock QTY when click "Add to cart button" */
    if (this.productDetail.ProductBatchID !== undefined && (this.joinOrderButtonClick || this.newOrderButtonClick)) {
      if (this.productDetail.ProductBatchColors && this.productDetail.ProductBatchColors.length !== 0) {
        let approve = false;
        if (this.selectColorId > 0) {
          let colorIndex = this.productDetail.ProductBatchColors.findIndex((data) => Number(data.ProductColorID) === Number(this.selectColorId));
          if (colorIndex < 0) {
            colorIndex = this.productDetail.ProductBatchColors.findIndex((data) => Number(data.ParentColorID) === Number(this.selectColorId));
          }

          if (colorIndex >= 0) {
            let selectedColor = this.productDetail.ProductBatchColors[colorIndex];
            //console.log("Selected COLRO is : ", selectedColor);
            if (selectedColor.TotalFutureStockQuantity + selectedColor.StockBalance >= Number(this.orderQuantityParent)) {
              approve = false;
            } else {
              approve = true;
            }
          } else {
            approve = false;
          }
        }
        if (this.productDetail.StockBalance === undefined) {
          approve = false;
        }
        if (approve) {
          this.productDetail.ProductBatchColors.forEach((element) => {
            if (element.ProductFutureStock && element.ProductFutureStock.length !== 0) {
              this.case1 = true;
              this.case2 = false;
            } else {
              this.case1 = false;
              this.case2 = true;
            }
          });
          if (this.productDetail.isInternalStockProduct) {
            this.isShowinquirybutton = true;
          } else {
            this.isShowinquirybutton = false;
          }
          /** new-code => show modal popup when click only "Add to Cart Button in Tab-1" */
          if (orderName === "neworder" || orderName === "joinorder") {
            this.productStockModal.show();
            this.selectColorId = 0;
            this.newOrderButtonClick = false;
            this.joinOrderButtonClick = false;
          }
          return false;
          /** new-code => show modal popup when click only "Add to Cart Button in Tab-1" */
        }
      }
    }
    /** new-code => To check product selected color have enough stock QTY when click "Add to cart button" */

    //console.log("shoppingCartAddItem data HERE: ", data);

    // if (sessionStorage.getItem('newOrderData') !== undefined && sessionStorage.getItem('newOrderData') !== null) {
    if ((sessionStorage.getItem("newOrderData") !== undefined && sessionStorage.getItem("newOrderData") !== null) || this.shoppingCartData.isVisible) {
      if (data.DeliveryDate !== undefined && data.DeliveryDate !== null && data.DeliveryDate !== "") {
        this.orderDate = data.DeliveryDate;
        this.shoppingCartDeliveryDate = data.DeliveryDate;
      }
      this.addOrUpdateShoppingCart("initial", false, false, "", this.currentTabName, false, false);
      return false;
    }
    /** new-code => for call ShoppingCart API twice based on QTY Enter without click Add to cart button */
    this.autoCallShoppingCartAPIStatus = autoShoppingCartAPICall;
    /** new-code => for call ShoppingCart API twice based on QTY Enter without click Add to cart button */

    /** new-code => For Add new Product based on Cart item Products and Display Popup for user to ask Proceed with new item add in cart */
    this.proceedNewJoinOrderStatus = true;
    this.newJoinOrderSubmitData = "";
    if ((orderName === "neworder" || orderName === "joinorder") && (this.shoppingCartItemId === null || this.shoppingCartItemId === undefined || this.shoppingCartItemId === "")) {
      this.proceedNewJoinOrderStatus = false;
      const shoppingCartCount = sessionStorage.getItem("shoppingCartCount");
      /** new-code => To check product is already added into CART Item */
      if (shoppingCartCount !== undefined && shoppingCartCount !== null) {
        if (Number(shoppingCartCount) > 0) {
          this.newJoinOrderSubmitData = data;
          const shoppingCartDatas = JSON.parse(sessionStorage.getItem("shoppingCartDatas"));
          let productExistCount = 0;
          if (shoppingCartDatas && shoppingCartDatas.length !== 0) {
            if (shoppingCartDatas !== "") {
              for (let i = 0; i < shoppingCartDatas.length; i++) {
                if (Number(shoppingCartDatas[i]["ProductID"]) === Number(this.productDetail.ProductID)) {
                  productExistCount += 1;
                }
              }
            }
          }
          if (productExistCount > 0) {
            this.newOrderModalConfirmation.show();
            this.proceedNewJoinOrderStatus = false;
          } else {
            this.newOrderModalConfirmation.hide();
            this.proceedNewJoinOrderStatus = true;
            this.autoCallShoppingCartAPIStatus = true; // auto API call when enter QTY in text Field
          }
        } else {
          this.newOrderModalConfirmation.hide();
          this.proceedNewJoinOrderStatus = true;
          this.autoCallShoppingCartAPIStatus = true; // auto API call when enter QTY in text Field
        }
      }
      /** new-code => To check product is already added into CART Item */
    }

    /** new-code => For Add new Product based on Cart item Products and Display Popup for user to ask Proceed with new item add in cart */

    /** new-code => Proceed Status based on product that are already add into cart or not */
    if (this.proceedNewJoinOrderStatus && (this.shoppingCartItemId === null || this.shoppingCartItemId === undefined || this.shoppingCartItemId === "")) {
      /** new-code */
      this.autoCallShoppingCartAPIStatus = true; // auto API call when enter QTY in text Field
      /** Concept to restrict Add Shopping Cart API call when change Date of New order */
      if (data.DeliveryDate !== undefined && data.DeliveryDate !== null && data.DeliveryDate !== "") {
        this.orderDate = data.DeliveryDate;
        this.shoppingCartDeliveryDate = data.DeliveryDate;
      } else {
        this.orderDate = "";
      }
      //console.log('this.orderDate : ');
      //console.log(this.orderDate);
      /** new-code */

      const webshopSettings = this.globalService.webShopSettings();

      // tslint:disable-next-line:max-line-length
      // if (webshopSettings.PDPNoBrandingConfirmation && this.productDetail.ProductPrices && this.productDetail.ProductPrices.length > 0 && this.productDetail.ProductPrices[0].ProductPrintPrices && this.productDetail.ProductPrices[0].ProductPrintPrices.length > 0
      //   && !this.productDetail.ProductPrices[0].ProductPrintPrices[0].isFixedLogo && this.selectedIds.length == 0
      //   && !this.orderWithoutBrandingConfirmed && !data.isSampleWithLogo && !data.isSampleWithoutLogo) {
      //   // If user didn't select branding, show confirmation pop-up
      //   this.placeOrderData = data;
      //   this.placeOrderIsJoinOrder = joinOrderStatus;
      //   // this.noBrandingConfirmation.show();
      //   this.confirmOrderWithoutBranding();
      // }
      // else {
      this.proceedShoppingCartAddItem(data, joinOrderStatus, isProceedWithoutLogo);
      // }
    } else {
      if (data.DeliveryDate !== undefined && data.DeliveryDate !== null && data.DeliveryDate !== "") {
        this.orderDate = data.DeliveryDate;
        this.shoppingCartDeliveryDate = data.DeliveryDate;
      }
      if (this.shoppingCartItemId !== null && this.shoppingCartItemId !== undefined && this.shoppingCartItemId !== "") {
        this.addOrUpdateShoppingCart("initial", false, false, "", this.currentTabName, false, false);
      }
    }
  }

  confirmOrderWithoutBranding() {
    this.orderWithoutBrandingConfirmed = true;
    this.shoppingCartAddItem(this.placeOrderData, this.placeOrderIsJoinOrder, undefined, false, true);
  }

  proceedShoppingCartAddItem(data, joinOrderStatus, isProceedWithoutLogo) {

    if (!this.isParentCustomer) {
      let a = this.commonServicesService.getPoints().subscribe((data) => {
        this.balancePoints = data;
      });

      a.unsubscribe();
      if (this.balancePoints <= 0) {
        this.toastr.error("You don't have points to select a product");
        return false;
      }
    }
    if (this.FileName === "") {
      this.productDetail["ShoppingCartItem"].FileName = "";
    }
    else {
      this.productDetail["ShoppingCartItem"].FileName = this.FileName;
    }
    //console.log("proceedShoppingCartAddItem");
    //console.log(data);
    let deliveryDate = "";
    let orderQty = 0;
    // get Delivery Date and Dynamic Order QTY
    setTimeout(() => {
      if (data.paramExists !== undefined && data.paramExists !== null) {
        // deliveryDate = data.param.DeliveryDate;
        // orderQty = data.param.OrderQty;
        // if (joinOrderStatus) {
        //   orderQty = (Number(data.param.OrderQty) + Number(this.productionLineList[0].Quantity));
        // }
        deliveryDate = data.DeliveryDate;
        orderQty = data.OrderQty;
        if (joinOrderStatus) {
          orderQty = Number(data.OrderQty) + Number(this.productionLineList[0].Quantity);
        }
      } else {
        deliveryDate = data.DeliveryDate;
        if (this.productDetail.FixedUnits) {
          orderQty = this.productDetail.PiecesPerUnit * this.orderQuantityParent;
          if (joinOrderStatus) {
            orderQty = this.productDetail.PiecesPerUnit * Number(this.orderQuantityParent);
          }
        } else {
          orderQty = this.orderQuantityParent;
          if (joinOrderStatus) {
            orderQty = Number(this.orderQuantityParent);
          }
        }
      }

      /** Multiple API Call based on Future Stock have Two Options for Single proceed Button
       * "THERE_IS_FUTURE_STOCK_3": " Send me what you got now",
       * "THERE_IS_FUTURE_STOCK_3.1": " and the rest on",
       */
      if (data.isMultipleCall !== null && data.isMultipleCall !== undefined) {
        orderQty = data.OrderQty;
        this.orderQuantityParent = data.OrderQty;
      }
      /** Multiple API Call based on Future Stock have Two Options for Single proceed Button
       * "THERE_IS_FUTURE_STOCK_3": " Send me what you got now",
       * "THERE_IS_FUTURE_STOCK_3.1": " and the rest on",
       */
    }, 50);
    /** new-code */
    /** When click add to cart button on tab-1, that time we need to assign selected date to this variable */
    if (data === "placeorder" && this.shoppingCartDeliveryDate !== "") {
      // deliveryDate = this.shoppingCartDeliveryDate;
    }
    /** new-code */

    let logos = [];
    for (let k = 0; k < this.selectedIds.length; k++) {
      logos.push({
        PrintPriceID: this.selectedIds[k],
        isEmailLogoLater: false,
      });
    }
    const userSettings = this.globalService.getUserSettings();
    const loggedInData = this.globalService.getLoggedInData();
    const webshopSettings = this.globalService.webShopSettings();
    let isColorStepCompleted = false;
    /** new-code => product color id check exist in ShoppingCartItem in Product Detail API Response */
    if (this.selectColorId > 0) {
      if (this.productDetail.ShoppingCartItem.ProductColors !== undefined && this.productDetail.ShoppingCartItem.ProductColors !== null) {
        if (this.productDetail.ShoppingCartItem.ProductColors.length > 0) {
          for (let j = 0; j < this.productDetail.ShoppingCartItem.ProductColors.length; j++) {
            if (Number(this.productDetail.ShoppingCartItem.ProductColors[j].ProductColorID) === Number(this.selectColorId)) {
              isColorStepCompleted = true;
            }

            /** new-code => Based on Product Color Parent Id */
            if (this.productDetail.ShoppingCartItem.ProductColors[j].ParentColorID !== undefined && this.productDetail.ShoppingCartItem.ProductColors[j].ParentColorID !== null) {
              if (Number(this.productDetail.ShoppingCartItem.ProductColors[j].ParentColorID) === Number(this.selectColorId)) {
                isColorStepCompleted = true;
              }
            }
            /** new-code => Based on Product Color Parent Id */
          }
        }
      }
    } else {
      /** new-code => if product have only one color then it should be preselect by default after Enter QTY */
      if (this.productDetail.ShoppingCartItem.ProductColors !== undefined && this.productDetail.ShoppingCartItem.ProductColors !== null) {
        if (this.productDetail.ShoppingCartItem.ProductColors.length === 1) {
          isColorStepCompleted = true;
          if (this.productDetail.ShoppingCartItem.ProductColors[0].ParentColorID !== undefined && this.productDetail.ShoppingCartItem.ProductColors[0].ParentColorID !== null) {
            this.selectColorId = this.productDetail.ShoppingCartItem.ProductColors[0].ParentColorID;
          } else {
            this.selectColorId = this.productDetail.ShoppingCartItem.ProductColors[0].ProductColorID;
          }
        }
      }
    }
    /** new-code => if product have only one color then it should be preselect by default after Enter QTY */

    // if (this.selectColorId === null) {
    //   if (this.productDetail.ProductBatchID !== undefined && this.productDetail.ProductFutureStock.length === 0) {
    //     if ((this.productDetail.ProductBatchColors && this.productDetail.ProductBatchColors.length !== 0)) {
    //       this.selectColorId = this.productDetail.ProductBatchColors[0].ProductColorID;
    //     }
    //   }
    // }

    //console.log("Before Settimeout in Add Process");
    setTimeout(() => {
      if (data.isSampleWithLogo === true) {
        this.sampleOrderWithLogo = true;
        this.sampleOrderWithoutLogo = false;
        this.expressOrderDate = this.productDetail.SampleWithLogoDeliveryDate;
        orderQty = 1;
        deliveryDate = this.productDetail.SampleWithLogoDeliveryDate;
      } else if (data.isSampleWithoutLogo === true) {
        this.sampleOrderWithLogo = false;
        this.sampleOrderWithoutLogo = true;
        this.expressOrderDate = this.productDetail.SampleWithoutLogoDeliveryDate;
        orderQty = 1;
        deliveryDate = this.productDetail.SampleWithoutLogoDeliveryDate;
      } else {
        this.sampleOrderWithLogo = false;
        this.sampleOrderWithoutLogo = false;
      }
      let isLogosStepCompleted = isProceedWithoutLogo;
      /** new-code => if product is fixed logo */
      if (this.productDetail["ShoppingCartItem"]["isFixedLogo"]) {
        isLogosStepCompleted = true;
      }
      if (this.productDetail.ProductPrices.length > 0) {
        let productPrintPrices = "";
        //console.log("orderQty : ", orderQty);
        this.printPriceCalculation = [];
        for (let x = 0; x < this.productDetail.ProductPrices.length; x++) {
          if (orderQty >= this.productDetail.ProductPrices[x].MinimumQuantity && this.productDetail.ProductPrices[x].MaximumQuantity >= orderQty) {
            // If user searched by branding type, cheapest logo of that type should be checked
            const breadCrumbsList = JSON.parse(sessionStorage.getItem("breadCrumbsList"));
            var searchUrl = breadCrumbsList[breadCrumbsList.length - 1].url;
            const idxBrandingType = searchUrl.indexOf("ShowPricesIncludingLogoType=");
            if (idxBrandingType >= 0 && searchUrl.length > idxBrandingType + 28) {
              searchUrl = searchUrl.substring(idxBrandingType);
              var brandingStr = "";
              if (searchUrl.indexOf("&") === -1) {
                brandingStr = searchUrl.substring(28);
              } else {
                brandingStr = searchUrl.substring(28, searchUrl.indexOf("&"));
              }
              if (brandingStr !== "") {
                this.ShowPricesIncludingLogoType = Number(brandingStr);
                if (this.ShowPricesIncludingLogoType >= 1 && this.ShowPricesIncludingLogoType <= 4) {
                  this.LogoTypesCodes.push("SILKSCREEN");
                  this.LogoTypesCodes.push("PAD");
                  this.LogoTypesCodes.push("SCREEN");
                  this.LogoTypesCodes.push("TRANSFER");
                  this.LogoTypesCodes.push("DOMING");
                  this.LogoTypesCodes.push("ALLOVER");
                  this.LogoTypesCodes.push("CERAMICXP");
                  this.LogoTypesCodes.push("CERAMICPAD");
                  this.LogoTypesCodes.push("CYLINDRICALSCREEN");
                  this.LogoTypesCodes.push("VINYLSTICKER");
                  this.LogoTypesCodes.push("LABEL");
                  this.LogoTypesCodes.push("WPTRANSFER");
                } else if (this.ShowPricesIncludingLogoType == 5) {
                  this.LogoTypesCodes.push("EMBROIDERY");
                } else if (this.ShowPricesIncludingLogoType == 6) {
                  this.LogoTypesCodes.push("ENGRAVING");
                  this.LogoTypesCodes.push("3DENGRAVING");
                } else if (this.ShowPricesIncludingLogoType == 7) {
                  this.LogoTypesCodes.push("EMBOSSING");
                } else if (this.ShowPricesIncludingLogoType == 8) {
                  this.LogoTypesCodes.push("DEBOSSING");
                } else if (this.ShowPricesIncludingLogoType == 9) {
                  this.LogoTypesCodes.push("SUBLIMATION");
                  this.LogoTypesCodes.push("DIGITAL");
                  this.LogoTypesCodes.push("CMYK");
                }
              }
            }

            productPrintPrices = this.productDetail.ProductPrices[x].ProductPrintPrices;
            var printPricesWithBranding = this.productDetail.ProductPrices[x].ProductPrintPrices.filter((ppp) => this.LogoTypesCodes.indexOf(ppp.LogoTypeCode) !== -1 && (Number(ppp.NumberOfColors) === this.ShowPricesIncludingLogoType || Number(ppp.NumberOfColors) === 0 || this.ShowPricesIncludingLogoType > 4));

            var printPricesWithBrandingSorted = printPricesWithBranding.sort((n1, n2) => n1.Price - n2.Price);
            if (printPricesWithBrandingSorted.length > 0) {
              var minPPP = printPricesWithBrandingSorted[0];

              this.productDetail.ProductPrices[x].ProductPrintPrices.forEach((element1, _index1) => {
                if (element1.LogoTypeID === minPPP.LogoTypeID && element1.NumberOfColors === minPPP.NumberOfColors && element1.LogoPositionID === minPPP.LogoPositionID && element1.LogoMaxAreaID === minPPP.LogoMaxAreaID) {
                  const checkExists = this.printPriceCalculation.filter((data) => data.LogoTypeID === element1.LogoTypeID && data.NumberOfColors === element1.NumberOfColors && data.LogoPositionID === element1.LogoPositionID && data.LogoMaxAreaID === element1.LogoMaxAreaID);
                  if (checkExists.length === 0) {
                    this.printPriceCalculation.push({
                      LogoTypeID: element1.LogoTypeID,
                      NumberOfColors: element1.NumberOfColors,
                      LogoPositionID: element1.LogoPositionID,
                      LogoMaxAreaID: element1.LogoMaxAreaID,
                      price: element1.Price,
                    });
                    // this.selectedIds = [];
                    // this.selectedIds.push(element1.ProductPrintPriceID);
                    logos = [];
                    //console.log("element1 : ", element1);
                    logos[0] = {
                      PrintPriceID: element1.ProductPrintPriceID,
                      LogoTypeID: element1.LogoTypeID,
                      LogoTypeName: element1.LogoTypeName,
                      PrintPriceDescription: element1.Description,
                      LogoPositionID: element1.LogoPositionID,
                      LogoPositionDescription: element1.LogoPositionDescription,
                      LogoMaxAreaID: element1.LogoMaxAreaID,
                      LogoMaxAreaDescription: element1.LogoMaxAreaDescription,
                      isEmailLogoLater: true,
                      DisableLogoColors: element1.DisableLogoColors,
                      OrderLogoColors: [],
                      NumberOfColors: element1.NumberOfColors,
                    };
                  }
                }
              });
            }
          }
        }
      }

      /** new-code => if product is searched from advanced flter search with preselected branding logo */
      if (logos.length >= 1) {
        if (logos[0].PrintPriceID !== undefined) {
          if (logos[0].PrintPriceID !== 0 && logos[0].PrintPriceID !== "" && logos[0].PrintPriceID !== null) {
            isLogosStepCompleted = true;
          }
        }
      }
      //console.log("Add BEfore Logod Call : ", logos);
      /** new-code => Sample order - Should take first color into consideration */
      if ((this.sampleOrderWithLogo || this.sampleOrderWithoutLogo) && (this.shoppingCartItemId === null || this.shoppingCartItemId === undefined || this.shoppingCartItemId === "")) {
        if (this.shoppingCartData.ProductColors !== undefined && this.shoppingCartData.ProductColors !== null && this.shoppingCartData.ProductColors.length > 0 && (this.selectColorId <= 0 || this.selectColorId === undefined || this.selectColorId === null)) {
          let selectedColor = [];
          if (this.shoppingCartData.ProductColors[0].ParentColorID !== undefined && this.shoppingCartData.ProductColors[0].ParentColorID !== null) {
            this.selectColorId = this.shoppingCartData.ProductColors[0].ParentColorID;
            selectedColor = this.productDetail.ProductBatchColors.filter((option) => {
              return option.ProductColorID === this.selectColorId;
            });
          } else {
            this.selectColorId = this.shoppingCartData.ProductColors[0].ProductColorID;
            selectedColor = this.productDetail.ProductBatchColors.filter((option) => {
              return option.ProductColorID === this.selectColorId;
            });
          }
          if (selectedColor.length > 0) {
            /*if ((selectedColor[0].TotalFutureStockQuantity + selectedColor[0].StockBalance) <
              Number(orderQty)) {
              this.case1 = false;
              this.case2 = true;
              this.productStockModal.show();
              return false;
            }*/
          }
        } else {
          if (this.shoppingCartData.ProductColors !== undefined && this.shoppingCartData.ProductColors !== null && this.shoppingCartData.ProductColors.length > 0 && this.selectColorId > 0) {
            let colorIndex = -1;
            if (this.selectColorId > 0) {
              colorIndex = this.productDetail.ProductBatchColors.findIndex((data) => Number(data.ProductColorID) === Number(this.selectColorId));
              if (colorIndex < 0) {
                colorIndex = this.productDetail.ProductBatchColors.findIndex((data) => Number(data.ParentColorID) === Number(this.selectColorId));
              }
            }
            if (colorIndex >= 0) {
              let selectedColor: any = "";
              selectedColor = this.productDetail.ProductBatchColors[colorIndex];
              /* if (Number(selectedColor.TotalFutureStockQuantity + selectedColor.StockBalance) <
                Number(orderQty)) {
                this.case1 = false;
                this.case2 = true;
                this.productStockModal.show();
                return false;
              }*/
            }
          }
        }
      }
      /** new-code => Sample order - Should take first color into consideration */
      if (loggedInData !== null && loggedInData !== undefined) {
        this.loginUserId = loggedInData.UserID;
        this.shoppingCartPostParams = {
          UserID: loggedInData.UserID,
          CustomerID: webshopSettings.CustomerID,
          CurrencyID: userSettings.CurrencyID,
          InitialDeliveryCountry: loggedInData.Country,
          // 'DeliveryCountry': loggedInData.Country,
          //  'CustomerCountry': loggedInData.Country,
          Item: {
            UserID: loggedInData.UserID,
            ProductID: this.productDetail.ProductID,
            DeliveryDate: deliveryDate,
            OrderedQuantity: orderQty,
            ProductColorID: this.selectColorId !== 0 ? this.selectColorId : null,
            isExpressOrder: this.expressOrderStatus,
            isSampleWithoutLogo: this.sampleOrderWithoutLogo,
            isSampleWithLogo: this.sampleOrderWithLogo,
            isProceedWithoutLogo: isProceedWithoutLogo,
            isEmailLogoLater: false,
            isChecked: this.autoCallShoppingCartAPIStatus ? false : true,
            isInitialStepCompleted: true,
            isColorStepCompleted: isColorStepCompleted,
            isSizesStepCompleted: false,
            isAdditionalCostsStepCompleted: false,
            isProductPropertiesStepCompleted: false,
            // 'isLogosStepCompleted': isProceedWithoutLogo,
            isLogosStepCompleted: isLogosStepCompleted,
            Comment: "",
            Logos: logos,
            // tslint:disable-next-line:max-line-length
            isVisible: this.autoCallShoppingCartAPIStatus ? false : true, // new-code code is based on to show shopping cart product in widget,
            FileName: this.FileName
          },
        };
      } else {
        let userBrowserID = "";
        const cookieExists: Boolean = this.cookieService.check("shoppingCardTokenId");
        if (!cookieExists) {
          let domainName = this._httpWebshopService.getSubdomain(window.location.hostname);
          domainName = domainName ? domainName : 'voucherkart';
          const randomString = this._httpWebshopService.randomGeneration(6);
          this.cookieService.set("shoppingCardTokenId", domainName + randomString);
          userBrowserID = domainName + randomString;
        } else {
          userBrowserID = this.cookieService.get("shoppingCardTokenId");
        }
        this.browserShoppingToken = userBrowserID;
        this.shoppingCartPostParams = {
          UserBrowserID: userBrowserID,
          CustomerID: webshopSettings.CustomerID,
          CurrencyID: userSettings.CurrencyID,
          InitialDeliveryCountry: webshopSettings.Country,
          //  'DeliveryCountry': webshopSettings.Country,
          //  'CustomerCountry': webshopSettings.Country,
          Item: {
            UserBrowserID: userBrowserID,
            ProductID: this.productDetail.ProductID,
            DeliveryDate: deliveryDate,
            OrderedQuantity: orderQty,
            ProductColorID: this.selectColorId !== 0 ? this.selectColorId : null,
            isExpressOrder: this.expressOrderStatus,
            isSampleWithoutLogo: this.sampleOrderWithoutLogo,
            isSampleWithLogo: this.sampleOrderWithLogo,
            isProceedWithoutLogo: isProceedWithoutLogo,
            isEmailLogoLater: false,
            isChecked: this.autoCallShoppingCartAPIStatus ? false : true,
            isInitialStepCompleted: true,
            isColorStepCompleted: isColorStepCompleted,
            isSizesStepCompleted: false,
            isAdditionalCostsStepCompleted: false,
            isProductPropertiesStepCompleted: false,
            // 'isLogosStepCompleted': isProceedWithoutLogo,
            isLogosStepCompleted: isLogosStepCompleted,
            Comment: "",
            Logos: logos,
            // tslint:disable-next-line:max-line-length
            isVisible: this.autoCallShoppingCartAPIStatus ? false : true, // new-code code is based on to show shopping cart product in widget
            FileName: this.FileName
          },
        };
      }
      // if (data.param !== undefined && data.param.isDisabled) {
      //   alert("Inside : "+data.param.isDisabled);
      //   this.shoppingCartPostParams['Item']['isDisabled'] = data.param.isDisabled;
      //   this.shoppingCartPostParams['Item']['ItemGUID'] = window.sessionStorage.guid;
      // }
      if (data.paramExists !== undefined && data.paramExists !== null) {
        this.shoppingCartPostParams["Item"]["isDisabled"] = data.isDisabled;
        this.shoppingCartPostParams["Item"]["ItemGUID"] = window.sessionStorage.guid;
      }
      if (joinOrderStatus === 1 || this.orderName === "joinorder") {
        this.shoppingCartPostParams["Item"]["isJoinOrder"] = true;
      }
      Object.keys(this.shoppingCartPostParams).forEach((key) =>
        this.shoppingCartPostParams[key] === "" &&
        this.shoppingCartPostParams[key] === null &&
        this.shoppingCartPostParams[key] === undefined &&
        delete this.shoppingCartPostParams[key]
      );

      if (this.shoppingCartItemId === null || this.shoppingCartItemId === "" || this.shoppingCartItemId === undefined) {
        //console.log("After Sucess => Add shopping cart item");
        //console.log(this.shoppingCartPostParams);
        /** new-code => to restrick to call addupdateshopping cart API when changes occur
         * (Date or Checkbox click) in New Order or Join Order Section */
        if (this.checkOrderType === "neworder" || this.checkOrderType === "joinorder") {
          return false;
        }

        if (this.shoppingCartPostParams["Item"]["OrderedQuantity"] !== "") {
          this.spinnerShow = true;
          this._httpWebshopService.addShoppingCart(this.shoppingCartPostParams).subscribe((data) => {
            //console.log("After addShoppingCart Response : ");
            //console.log(data);
            /** new-code => Call another func whether item is to be SamplewithLogo/SampleWithoutLogo */
            if (this.shoppingCartPostParams["Item"]["isSampleWithLogo"] || this.shoppingCartPostParams["Item"]["isSampleWithoutLogo"]) {
              this.sampleOrderAddtoCart(data, this.shoppingCartPostParams["Item"]["isSampleWithLogo"], this.shoppingCartPostParams["Item"]["isSampleWithoutLogo"]);
              return false;
            }
            /** new-code => Call another func whether item is to be SamplewithLogo/SampleWithoutLogo */

            /** new-code => code to set locaStorage of new order data which product only enter QTY */
            if (this.autoCallShoppingCartAPIStatus) {
              sessionStorage.setItem("newOrderData", JSON.stringify(data));
              sessionStorage.setItem("newOrderId", data["Item"].ShoppingCartItemID);
            }
            /** new-code => code to set locaStorage of new order data which product only enter QTY */

            /** new-code => code to set locaStorage of Join order data which product only enter QTY */
            if (this.autoCallShoppingCartAPIStatus && this.joinOrderProductStatus) {
                this.shoppingCartPostParams["Item"]["OrderedQuantity"] = Number(orderQty);
                this.shoppingCartPostParams["Item"]["isJoinOrder"] = true;
                this.shoppingCartPostParams["Item"]["DeliveryDate"] = this.productDetail.DeliveryDate;
                this._httpWebshopService.addShoppingCart(this.shoppingCartPostParams).subscribe((data1) => {
                this.joinOrderShoppingCartData = data1;
                /** Replace Shoppingcart data printprice values based on Join order API response for
                 * Fixed Logo products
                 */
                // tslint:disable-next-line:max-line-length
                if (this.joinOrderShoppingCartData.Item.ProductPrintPrices !== undefined && this.joinOrderShoppingCartData.Item.ProductPrintPrices !== null) {
                 this.fixedLogoProductPrintPrices = [];
                  if (this.joinOrderShoppingCartData.Item.isFixedLogo) {
                   // console.log("Inside Fixed Logo Condition Initial");
                   if (data["Item"]["ProductPrintPrices"] !== undefined && data["Item"]["ProductPrintPrices"] !== null) {
                   data["Item"]["ProductPrintPrices"] = this.joinOrderShoppingCartData.Item.ProductPrintPrices;
                   this.fixedLogoProductPrintPrices = this.joinOrderShoppingCartData.Item.ProductPrintPrices;
                   }
                }
               }
              console.log("proceedShoppingCartAddItem Function : ", this.joinOrderShoppingCartData);
              sessionStorage.setItem("joinOrderData", JSON.stringify(data1));
               sessionStorage.setItem("joinOrderId", data1["Item"].ShoppingCartItemID);
               this.displayJoinOrderCommonPrice(data1["Item"], data1["CurrencyCode"]);
            });
           } else {
            this.spinnerShow = false;
              /* const shoppingCartCount = Number(sessionStorage.getItem("shoppingCartCount")) + 1;
               sessionStorage.setItem("shoppingCartCount", shoppingCartCount.toString());
               this._httpWebshopService.setCount(sessionStorage.getItem("shoppingCartCount"));*/
              /*const shoppingCartDatas = JSON.parse(sessionStorage.getItem("shoppingCartDatas"));
              //
              //console.log(shoppingCartDatas)
              if(this.responseData.Item){
              this.responseData.ProductID = this.responseData.Item.ProductID;
              shoppingCartDatas.push(this.responseData.Item);
              //console.log(shoppingCartDatas)
              sessionStorage.setItem("shoppingCartDatas", JSON.stringify(shoppingCartDatas));   
             } */
            }
            this.spinnerShow = false;
            /** new-code => code to set locaStorage of Join order data which product only enter QTY */

            /** new-code => code to remove locaStorage of Join/New order data which product clicked ADD TO CART Button */
            if (!this.autoCallShoppingCartAPIStatus) {
              if (sessionStorage.getItem("joinOrderData") !== undefined && sessionStorage.getItem("joinOrderData") !== null) {
                sessionStorage.removeItem("joinOrderData");
                sessionStorage.removeItem("joinOrderId");
              }
            }
            if (!this.autoCallShoppingCartAPIStatus) {
              if (sessionStorage.getItem("newOrderData") !== undefined && sessionStorage.getItem("newOrderData") !== null) {
                sessionStorage.removeItem("newOrderData");
                sessionStorage.removeItem("newOrderId");
              }
            }
            /** new-code => code to remove locaStorage of Join/New order data which product clicked ADD TO CART Button */

            this.spinnerShow = false;
            this.orderName = "";
            this.shoppingCartItemIdStatus = true;
            this.tabsDisable = false;
            this.responseData = data;
            this.shoppingCartData = data["Item"];
            if (this.selectColorId === null) {
              this.selectColorId = 0;
            }

            sessionStorage.setItem("cartData", JSON.stringify(this.responseData));

            /** new-code */
            this.displayCommonPrice(data["Item"], data["CurrencyCode"]);
            if (this.shoppingCartData && this.shoppingCartData.length > 0) {
              this.orderDate = this.shoppingCartData.DeliveryDate; // assign value to new order / Join Order Date variable
              this.expressOrderStatus = this.shoppingCartData.isExpressOrder; // assign value to Express order Checkbox

              this.tabNames = [];
              if (this.shoppingCartData && this.shoppingCartData.Logos.length > 0 && !this.shoppingCartData.isSampleWithoutLogo && this.shoppingCartData.isFixedLogo !== true) {
                /** new-code => to know list of tab names for particular visited product in PDP */
                this.tabNames.push("logo");
              }
              if (this.shoppingCartData && this.shoppingCartData.ProductColors.length >= 1) {
                /** new-code => to know list of tab names for particular visited product in PDP */
                this.tabNames.push("color");
              }
              if (this.shoppingCartData && this.shoppingCartData.ProductSizes.length > 0) {
                /** new-code => to know list of tab names for particular visited product in PDP */
                this.tabNames.push("productSize");
              }
              if (this.shoppingCartData && (this.shoppingCartData.AdditionalCosts && this.shoppingCartData.AdditionalCosts.length > 0) || (this.shoppingCartData.Price && this.shoppingCartData.Price.GovernmentalCosts && this.shoppingCartData.Price.GovernmentalCosts.length > 0 && this.userId !== null)) {
                /** new-code => to know list of tab names for particular visited product in PDP */
                this.tabNames.push("additionalCost");
              }
              if (this.shoppingCartData && this.shoppingCartData.ProductProperties.length > 0) {
                /** new-code => to know list of tab names for particular visited product in PDP */
                this.tabNames.push("productProperty");
              }
            }
            //("Total Tab Names AFTER First tab Click");
            //console.log(this.tabNames);
            /** new-code */
            if (this.responseData.Item.length > 0) {
              const shoppingCartItemID = this.responseData.Item.ShoppingCartItemID;
              this.shoppingCartItemId = this.responseData.Item.ShoppingCartItemID;
              const productId = this.responseData.Item.ProductID;
              const productName = this.responseData.Item.ProductName;
              window.history.replaceState(" ", "", "/productdetail/" + productId + "/" + productName + "/" + shoppingCartItemID);
            } // this.router.navigate(['/shoppingcart', shoppingCartItemID],
            //  { replaceUrl: false });
            if (!this.autoCallShoppingCartAPIStatus) {
              const shoppingCartCount = Number(sessionStorage.getItem("shoppingCartCount")) + 1;
              sessionStorage.setItem("shoppingCartCount", shoppingCartCount.toString());
              this._httpWebshopService.setCount(sessionStorage.getItem("shoppingCartCount"));

              /** new-code => add product into shopping cart datas in session Storage */
              const shoppingCartDatas = JSON.parse(sessionStorage.getItem("shoppingCartDatas"));
              this.responseData.ProductID = this.responseData.Item.ProductID;
              //console.log(shoppingCartDatas)
              shoppingCartDatas.push(this.responseData.Item);
              //console.log(shoppingCartDatas)
              sessionStorage.setItem("shoppingCartDatas", JSON.stringify(shoppingCartDatas));
            }
            /** new-code => add product into shopping cart datas in session Storage */

            /** new-code */
            if (this.joinOrderProductStatus) {
              this.spinnerShow = true;
              setTimeout(() => {
                this.addOrUpdateShoppingCart("initial", false, false, "", this.currentTabName, false, false);
              }, 1000);
            } else {
              /** Big important issue */
              //  this.addOrUpdateShoppingCart('initial', false, false, '', this.currentTabName, false, false);
            }

            /** new-code => To set Color index bsed on user select color */
            //console.log("this.shoppingCartData.ProductColorID : ", this.shoppingCartData.ProductColorID);
            if (this.shoppingCartData && this.shoppingCartData.length > 0) {
              if (this.shoppingCartData.ProductColorID !== undefined) {
                for (let j = 0; j < this.shoppingCartData.ProductColors.length; j++) {
                  if (this.shoppingCartData.ProductColorID === this.shoppingCartData.ProductColors[j].ProductColorID) {
                    this.selectedColor(this.shoppingCartData.ProductColors[j], j, "color");
                    if (this.shoppingCartData.ProductColors[j].AvailableSizes.length > 0) {
                      this.productSizes = this.shoppingCartData.ProductColors[j].AvailableSizes;
                      this.productSizesindex = j;
                      this.selectColorId = this.shoppingCartData.ProductColors[j].ProductColorID;
                    }
                  }
                }
              }
            }
            this.toastr.success("Product has been saved to your shopping cart");
            /** new-code => To set Color index bsed on user select color */
            /** new-code */
          }, (error) => {
            this.toastr.error("Error: " + error.Message);
          }
          );
        }
      } else {
        return false;
        //this.addOrUpdateShoppingCart("initial", false, false, "", this.currentTabName, false, false);
      }
    }, 100);
  }

  sampleOrderAddtoCart(data, isSampleWithLogo, isSampleWithoutLogo) {
    data["Item"]["isSampleWithLogo"] = isSampleWithLogo;
    data["Item"]["isSampleWithoutLogo"] = isSampleWithoutLogo;
    data["Item"]["isValid"] = true;
    data["Item"]["isVisible"] = true;
    data["Item"]["isChecked"] = true;
    data["Item"]["isColorStepCompleted"] = false;
    data["Item"]["isSizesStepCompleted"] = false;
    data["Item"]["isAdditionalCostsStepCompleted"] = false;
    data["Item"]["isProductPropertiesStepCompleted"] = false;
    data["Item"]["isLogosStepCompleted"] = true;
    if (this.selectColorId !== undefined && this.selectColorId !== null && this.selectColorId !== "") {
      data["Item"]["ProductColorID"] = this.selectColorId;
      data["Item"]["isColorStepCompleted"] = true;
    }
    // if (data['Item'].ProductColors && data['Item'].ProductColors.length >= 1) {
    //   data['Item']['isColorStepCompleted'] = true;
    // }
    // if (data['Item'].ProductSizes && data['Item'].ProductSizes.length > 0) {
    //   data['Item']['isSizesStepCompleted'] = true;
    // }
    // if (data['Item'].AdditionalCosts && data['Item'].AdditionalCosts.length > 0) {
    //   data['Item']['isAdditionalCostsStepCompleted'] = true;
    // }
    // if (data['Item'].ProductProperties && data['Item'].ProductProperties.length > 0) {
    //   data['Item']['isProductPropertiesStepCompleted'] = true;
    // }
    // if (data['Item'].Logos && data['Item'].Logos.length > 0 && data['Item'].Logos[0].PrintPriceID !== undefined) {
    //   data['Item']['isLogosStepCompleted'] = true;
    // }
    this.spinnerShow = true;
    this._httpWebshopService.addShoppingCart(data).subscribe((_data1) => {
      this.sampleOrder.hide();
      this.spinnerShow = false;
      //console.log("After Add Sample with/without Logo : ", data1);
      const shoppingCartCount = Number(sessionStorage.getItem("shoppingCartCount")) + 1;
      sessionStorage.setItem("shoppingCartCount", shoppingCartCount.toString());
      this._httpWebshopService.setCount(sessionStorage.getItem("shoppingCartCount"));

      const shoppingCartDatas = JSON.parse(sessionStorage.getItem("shoppingCartDatas"));
      shoppingCartDatas.push(data["Item"]);
      sessionStorage.setItem("shoppingCartDatas", JSON.stringify(shoppingCartDatas));
    });
  }

  openSampleOrderPopup() {
    this.sampleOrder.show();
  }
  getScreenheight() {
    setTimeout(() => {
      const body = document.body;
      const screenHeight = body.offsetHeight;
      this.getPopupHeight = screenHeight - 135;
    }, 100);
  }

  scroll() {
    var elmnt = document.getElementById("target");
    elmnt.scrollIntoView({ behavior: "smooth" });
    document.getElementById("sbp-toggle-card").click();
  }

  /** new-code */
  displayCommonPrice_1(_data, _type = null) {
    /*console.log(type);
    console.log("Display Coomon price");
    console.log(data);*/
  }

  displayCommonPrice(data, CurrencyCode) {
    let productShoppingCartData: any;
    let logoProductPrintPrice = 0;
    let logoPrintPrice = 0;
    let logoScreenChargesCost = 0;
    let logoSetupCost = 0;
    let logoToolCost = 0;
    let logoProductHandlingCost = 0;
    let logoTotalHandlingCost = 0;
    let additionalCost = 0;
    let governmentalCost = 0;
    let additionalCostForProduct = 0;
    let governmentalCostForProduct = 0;
    let totalWithoutTransportationCost = 0;
    let transportationCost = 0;
    let suborderTransportationHandlingCost = 0;

    if (data.ProductID !== undefined && data.ProductID !== null) {
      productShoppingCartData = data;
    } else {
      for (let i = 0; i < data.length; i++) {
        if (Number(data[i].ShoppingCartItemID) === Number(this.shoppingCartItemId) && data[i].ProductionLineID === undefined) {
          productShoppingCartData = data[i];
        }
      }
    }
    //console.log("Display Common Price section : ", productShoppingCartData);
    if (productShoppingCartData !== "" && productShoppingCartData !== undefined) {
      if (productShoppingCartData.Price !== undefined && productShoppingCartData.Price !== null) {
        this.displayCommonPriceValue = 0;
        this.productPriceInclBranding = 0;
        let orderQty = productShoppingCartData.Price.OrderedQuantity;
        this.displayCommonCurrency = CurrencyCode;
        // NewOrder/JoinOrder QTY Calculation
        if (productShoppingCartData.Price.ProductPrice !== undefined && productShoppingCartData.Price.ProductPrice !== null) {
          // tslint:disable-next-line:max-line-length
          // this.displayCommonPriceValue += ((orderQty) * (productShoppingCartData.Price.ProductPrice));

          productShoppingCartData.ProductPrices.forEach((prc, _index) => {
            if (orderQty >= prc.MinimumQuantity && orderQty <= prc.MaximumQuantity) {
              this.displayCommonPriceValue += prc.Price;
            }
          });
          // this.displayCommonPriceValue += productShoppingCartData.Price.ProductPrice;
          //console.log(" productShoppingCartData.Price.ProductPrice : ", productShoppingCartData.Price.ProductPrice);
          //  this.productPriceInclBranding += ((orderQty) * (productShoppingCartData.Price.ProductPrice));
        }

        if (productShoppingCartData.isFixedLogo && productShoppingCartData.ProductPrintPrices !== undefined && productShoppingCartData.ProductPrintPrices !== null) {
          if (productShoppingCartData.ProductPrintPrices[0] !== undefined) {
            logoProductPrintPrice += Number(productShoppingCartData.ProductPrintPrices[0].Price);
            if (productShoppingCartData.ProductPrintPrices[0].HandlingCost !== undefined) {
              logoProductPrintPrice += Number(productShoppingCartData.ProductPrintPrices[0].HandlingCost);
            }
          }
        }

        // Logo PRINT PRICE, SCREEN CHARGES, SETUP COST, TOOL COST, HANDLING COST
        if (productShoppingCartData.Price.Logos !== undefined && productShoppingCartData.Price.Logos !== null) {
          for (let j = 0; j < productShoppingCartData.Price.Logos.length; j++) {
            // Logo Print Price
            // tslint:disable-next-line:max-line-length
            if (productShoppingCartData.Price.Logos[j].ProductPrintPrice !== undefined && productShoppingCartData.Price.Logos[j].ProductPrintPrice !== null && !productShoppingCartData.isFixedLogo) {
              //console.log(productShoppingCartData.Price.Logos[j]);
              logoProductPrintPrice += Number(productShoppingCartData.Price.Logos[j].ProductPrintPrice);
            }

            if (productShoppingCartData.Price.Logos[j].ProductPrintPrice !== undefined && productShoppingCartData.Price.Logos[j].ProductPrintPrice !== null) {
              logoPrintPrice += Number(orderQty) * Number(productShoppingCartData.Price.Logos[j].ProductPrintPrice);
            }

            // SCREEN CHARGES
            // tslint:disable-next-line:max-line-length
            if (productShoppingCartData.Price.Logos[j].ScreenChargesCost !== undefined && productShoppingCartData.Price.Logos[j].ScreenChargesCost !== null) {
              logoScreenChargesCost += productShoppingCartData.Price.Logos[j].ScreenChargesCost;
            }

            // SETUP COST
            // tslint:disable-next-line:max-line-length
            if (productShoppingCartData.Price.Logos[j].SetupCost !== undefined && productShoppingCartData.Price.Logos[j].SetupCost !== null) {
              logoSetupCost += productShoppingCartData.Price.Logos[j].SetupCost;
            }

            // TOOL COST
            // tslint:disable-next-line:max-line-length
            if (productShoppingCartData.Price.Logos[j].ToolCost !== undefined && productShoppingCartData.Price.Logos[j].ToolCost !== null) {
              logoToolCost += productShoppingCartData.Price.Logos[j].ToolCost;
            }

            // HANDLING COST FOR TOTAL PRINT PRICE
            // tslint:disable-next-line:max-line-length
            if (productShoppingCartData.Price.Logos[j].TotalHandlingCost !== undefined && productShoppingCartData.Price.Logos[j].TotalHandlingCost !== null && productShoppingCartData.Price.Logos[j].HandlingCostTypeName !== "per piece") {
              logoTotalHandlingCost += productShoppingCartData.Price.Logos[j].TotalHandlingCost;
            }

            // HANDLING COST FOR PRODUCT PRICE SECTION
            // tslint:disable-next-line:max-line-length
            if (productShoppingCartData.Price.Logos[j].HandlingCost !== undefined && productShoppingCartData.Price.Logos[j].HandlingCost !== null && productShoppingCartData.Price.Logos[j].HandlingCostTypeName === "per piece" && !productShoppingCartData.isFixedLogo) {
              logoProductHandlingCost += productShoppingCartData.Price.Logos[j].HandlingCost;
            }
          }
        }

        // ADDITIONAL COSTS
        if (productShoppingCartData.Price.AdditionalCosts !== undefined && productShoppingCartData.Price.AdditionalCosts !== null) {
          for (let j = 0; j < productShoppingCartData.Price.AdditionalCosts.length; j++) {
            additionalCost += productShoppingCartData.Price.AdditionalCosts[j].TotalAdditionalCostPrice;
          }
        }

        // ADDITIONAL COSTS For Product Price Section
        if (productShoppingCartData.Price.AdditionalCosts !== undefined && productShoppingCartData.Price.AdditionalCosts !== null) {
          for (let j = 0; j < productShoppingCartData.Price.AdditionalCosts.length; j++) {
            if (productShoppingCartData.Price.AdditionalCosts[j].AdditionalCostTypeCode === "PerUnit") {
              if (productShoppingCartData.Price.AdditionalCosts[j].AdditionalCostPrice !== undefined && productShoppingCartData.Price.AdditionalCosts[j].AdditionalCostPrice !== null) {
                additionalCostForProduct += productShoppingCartData.Price.AdditionalCosts[j].AdditionalCostPrice;
              }
            }
          }
        }

        // GOVERNMENTAL COSTS
        if (productShoppingCartData.Price.GovernmentalCosts !== undefined && productShoppingCartData.Price.GovernmentalCosts !== null) {
          for (let j = 0; j < productShoppingCartData.Price.GovernmentalCosts.length; j++) {
            governmentalCost += productShoppingCartData.Price.GovernmentalCosts[j].TotalGovernmentalCostPrice;
          }
        }

        // GOVERNMENTAL COSTS For Product Price Section
        if (productShoppingCartData.Price.GovernmentalCosts !== undefined && productShoppingCartData.Price.GovernmentalCosts !== null) {
          for (let j = 0; j < productShoppingCartData.Price.GovernmentalCosts.length; j++) {
            if (productShoppingCartData.Price.GovernmentalCosts[j].GovernmentalCostTypeCode === "PerUnit") {
              governmentalCostForProduct += productShoppingCartData.Price.GovernmentalCosts[j].GovernmentalCostPrice;
            }
          }
          if (this.userId === null) {
            governmentalCostForProduct = 0;
          }
        }

        // tslint:disable-next-line:max-line-length
        /*console.log("logoProductPrintPrice : " + logoProductPrintPrice);
        console.log("logoProductHandlingCost : " + logoProductHandlingCost);
        console.log("additionalCostForProduct : " + additionalCostForProduct);
        console.log("governmentalCostForProduct : " + governmentalCostForProduct);*/
        this.displayCommonPriceValue += logoProductPrintPrice + logoProductHandlingCost + additionalCostForProduct + governmentalCostForProduct;
        //console.log("Logo Print Price : " + logoPrintPrice);

        // this.productPriceInclBranding += logoPrintPrice + logoScreenChargesCost + logoSetupCost + logoToolCost + logoTotalHandlingCost;

        //  this.productPriceInclBranding += logoPrintPrice + logoScreenChargesCost + logoSetupCost + logoToolCost + logoTotalHandlingCost + additionalCost + governmentalCost + totalWithoutTransportationCost + transportationCost + suborderTransportationHandlingCost;

        // this.productPriceInclBranding = productShoppingCartData.Price.TotalWithoutTransportationCost;
        this.productPriceInclBranding = 0;
      }
    }

    //console.log("Display Coomon price");
    //console.log(data);
  }

  displayJoinOrderCommonPrice(data, CurrencyCode) {
    let productShoppingCartData: any;
    let logoProductPrintPrice = 0;
    let logoPrintPrice = 0;
    let logoScreenChargesCost = 0;
    let logoSetupCost = 0;
    let logoToolCost = 0;
    let logoProductHandlingCost = 0;
    let logoTotalHandlingCost = 0;
    let additionalCost = 0;
    let governmentalCost = 0;
    let additionalCostForProduct = 0;
    let governmentalCostForProduct = 0;
    let totalWithoutTransportationCost = 0;
    let transportationCost = 0;
    let suborderTransportationHandlingCost = 0;
    //console.log("Ïnside JOIN ORDER PRICE SECTION : ", data);
    if (data && data.ProductID !== undefined && data.ProductID !== null) {
      productShoppingCartData = data;
    } else if(data){
      for (let i = 0; i < data.length; i++) {
        if (Number(data[i].ShoppingCartItemID) === Number(this.shoppingCartItemId)) {
          productShoppingCartData = data[i];
        }
      }
    }

    if (productShoppingCartData !== "" && productShoppingCartData !== undefined) {
      if (productShoppingCartData.Price !== undefined && productShoppingCartData.Price !== null) {
        this.displayJoinOrderCommonPriceValue = 0;
        this.joinOrderProductPriceInclBranding = 0;
        let orderQty = productShoppingCartData.Price.OrderedQuantity;
        this.displayCommonCurrency = CurrencyCode;
        // NewOrder/JoinOrder QTY Calculation
        if (productShoppingCartData.Price.ProductPrice !== undefined && productShoppingCartData.Price.ProductPrice !== null) {
          // tslint:disable-next-line:max-line-length
          this.displayJoinOrderCommonPriceValue += productShoppingCartData.Price.ProductPrice;
          //  this.joinOrderProductPriceInclBranding += ((orderQty) * (productShoppingCartData.Price.ProductPrice));
        }

        // Logo PRINT PRICE, SCREEN CHARGES, SETUP COST, TOOL COST, HANDLING COST
        if (productShoppingCartData.Price.Logos !== undefined && productShoppingCartData.Price.Logos !== null) {
          for (let j = 0; j < productShoppingCartData.Price.Logos.length; j++) {
            // Logo Print Price
            //console.log("productShoppingCartData.Price.LOGOS : ", productShoppingCartData.Price.Logos);
            // tslint:disable-next-line:max-line-length
            if (!productShoppingCartData.isFixedLogo) {
              // tslint:disable-next-line:max-line-length
              if (productShoppingCartData.Price.Logos[j].ProductPrintPrice !== undefined && productShoppingCartData.Price.Logos[j].ProductPrintPrice !== null) {
                logoProductPrintPrice += Number(productShoppingCartData.Price.Logos[j].ProductPrintPrice);
              }
            } else {
              if (productShoppingCartData.ProductPrintPrices.length > 0) {
                for (let j = 0; j < productShoppingCartData.ProductPrintPrices.length; j++) {
                  if (productShoppingCartData.ProductPrintPrices[j].isFixedLogo) {
                    logoProductPrintPrice += Number(productShoppingCartData.ProductPrintPrices[j].Price);
                    if (productShoppingCartData.ProductPrintPrices[j].HandlingCost !== undefined && productShoppingCartData.ProductPrintPrices[j].HandlingCost !== null) {
                      logoProductPrintPrice += Number(productShoppingCartData.ProductPrintPrices[j].HandlingCost);
                    }
                  }
                }
              }
              //  logoProductPrintPrice += Number(productShoppingCartData.Price.Logos[0].HandlingCost) + Number(productShoppingCartData.Price.Logos[0].ProductPrintPrice);
            }

            // tslint:disable-next-line:max-line-length
            if (productShoppingCartData.Price.Logos[j].ProductPrintPrice !== undefined && productShoppingCartData.Price.Logos[j].ProductPrintPrice !== null) {
              logoPrintPrice += Number(orderQty) * Number(productShoppingCartData.Price.Logos[j].ProductPrintPrice);
            }

            // SCREEN CHARGES
            // tslint:disable-next-line:max-line-length
            if (productShoppingCartData.Price.Logos[j].ScreenChargesCost !== undefined && productShoppingCartData.Price.Logos[j].ScreenChargesCost !== null) {
              logoScreenChargesCost += productShoppingCartData.Price.Logos[j].ScreenChargesCost;
            }

            // SETUP COST
            // tslint:disable-next-line:max-line-length
            if (productShoppingCartData.Price.Logos[j].SetupCost !== undefined && productShoppingCartData.Price.Logos[j].SetupCost !== null) {
              logoSetupCost += productShoppingCartData.Price.Logos[j].SetupCost;
            }

            // TOOL COST
            // tslint:disable-next-line:max-line-length
            if (productShoppingCartData.Price.Logos[j].ToolCost !== undefined && productShoppingCartData.Price.Logos[j].ToolCost !== null) {
              logoToolCost += productShoppingCartData.Price.Logos[j].ToolCost;
            }

            // HANDLING COST
            // tslint:disable-next-line:max-line-length
            if (productShoppingCartData.Price.Logos[j].TotalHandlingCost !== undefined && productShoppingCartData.Price.Logos[j].TotalHandlingCost !== null && productShoppingCartData.Price.Logos[j].HandlingCostTypeName !== "per piece") {
              logoTotalHandlingCost += productShoppingCartData.Price.Logos[j].TotalHandlingCost;
            }

            // HANDLING COST FOR PRODUCT PRICE SECTION
            // tslint:disable-next-line:max-line-length
            if (productShoppingCartData.Price.Logos[j].HandlingCost !== undefined && productShoppingCartData.Price.Logos[j].HandlingCost !== null && productShoppingCartData.Price.Logos[j].HandlingCostTypeName === "per piece" && !productShoppingCartData.isFixedLogo) {
              logoProductHandlingCost += productShoppingCartData.Price.Logos[j].HandlingCost;
            }
          }
        }

        // ADDITIONAL COSTS
        if (productShoppingCartData.Price.AdditionalCosts !== undefined && productShoppingCartData.Price.AdditionalCosts !== null) {
          for (let j = 0; j < productShoppingCartData.Price.AdditionalCosts.length; j++) {
            additionalCost += productShoppingCartData.Price.AdditionalCosts[j].TotalAdditionalCostPrice;
          }
        }

        // ADDITIONAL COSTS For Product Price Section
        if (productShoppingCartData.Price.AdditionalCosts !== undefined && productShoppingCartData.Price.AdditionalCosts !== null) {
          for (let j = 0; j < productShoppingCartData.Price.AdditionalCosts.length; j++) {
            if (productShoppingCartData.Price.AdditionalCosts[j].AdditionalCostTypeCode === "PerUnit") {
              if (this.shoppingCartData.Price.AdditionalCosts[j].AdditionalCostPrice !== undefined && this.shoppingCartData.Price.AdditionalCosts[j].AdditionalCostPrice !== null) {
                additionalCostForProduct += productShoppingCartData.Price.AdditionalCosts[j].AdditionalCostPrice;
              }
            }
          }
        }

        // GOVERNMENTAL COSTS
        if (productShoppingCartData.Price.GovernmentalCosts !== undefined && productShoppingCartData.Price.GovernmentalCosts !== null) {
          for (let j = 0; j < productShoppingCartData.Price.GovernmentalCosts.length; j++) {
            governmentalCost += productShoppingCartData.Price.GovernmentalCosts[j].TotalGovernmentalCostPrice;
          }
        }

        // GOVERNMENTAL COSTS For Product Price Section
        if (productShoppingCartData.Price.GovernmentalCosts !== undefined && productShoppingCartData.Price.GovernmentalCosts !== null) {
          for (let j = 0; j < productShoppingCartData.Price.GovernmentalCosts.length; j++) {
            if (productShoppingCartData.Price.GovernmentalCosts[j].GovernmentalCostTypeCode === "PerUnit") {
              governmentalCostForProduct += productShoppingCartData.Price.GovernmentalCosts[j].GovernmentalCostPrice;
            }
          }
          if (this.userId === null) {
            governmentalCostForProduct = 0;
          }
        }

        // tslint:disable-next-line:max-line-length
        this.displayJoinOrderCommonPriceValue += logoProductPrintPrice + logoProductHandlingCost + additionalCostForProduct + governmentalCostForProduct;
        this.joinOrderProductPriceInclBranding = productShoppingCartData.Price.TotalWithoutTransportationCost;
      }
    }
  }

  resetNewOrderDate(event = null) {
    this.expressOrderStatus = false;
    this.resetJoinOrderStatus = true;
    this.orderDate = this.shoppingCartDeliveryDate = "";
    if (event !== null) {
      if (event.DeliveryDate !== "") {
        this.resetNewOrderStatus = true; // code for reset new order date and also Express order date with checkbox
        this.shoppingCartDeliveryDate = this.orderDate = this.datepipe.transform(event.DeliveryDate, "yyyy-MM-dd");
      } else {
        this.resetNewOrderStatus = false;
      }
    } else {
      this.resetNewOrderStatus = false;
    }
    this.checkOrderType = "joinorder"; // based on to call Function of "New Order" or "Join Order" button. currently both buttons display invisible
  }

  resetJoinOrderDate(event = null) {
    this.orderDate = this.shoppingCartDeliveryDate = "";
    this.resetJoinOrderStatus = false; // code for reset join order date and also checkbox
    this.resetNewOrderStatus = false;
    if (event !== null) {
      if (event.DeliveryDate !== "") {
        this.shoppingCartDeliveryDate = this.orderDate =
          this.datepipe.transform(event.DeliveryDate, "yyyy-MM-dd");
      }
    }
    this.checkOrderType = "neworder"; // based on to call Function of "New Order" or "Join Order" button. currently both buttons display invisible
  }
  /** new-code */

  checkOrderTypeFunc() {
    if (this.checkOrderType === "joinorder") {
      this.orderName = "joinorder";
      this.checkOrderType = "";
      document.getElementById("join_order_button").click();
    } else {
      this.orderName = "neworder";
      this.checkOrderType = "";
      document.getElementById("new_order_button").click();
    }
  }

  resetNewJoinOrderStatus() {
    //console.log("resetNewJoinOrderStatus Called");
    this.resetJoinOrderStatus = false;
    this.resetNewOrderStatus = true;
    this.expressOrderStatus = false;
    // this.resetJoinOrderDate();
  }

  productionProductRedirect(productId) {
    window.history.replaceState(" ", "", "/productdetail/" + productId + "/" + this.productDetail.Name);
    window.location.reload();
  }

  closePage() {
    this.showDetail = false;
    let perpage = JSON.parse(sessionStorage.getItem('prePages'));
    if (perpage) {
      let url = perpage[perpage.length - 1];
      let url_a = url.url.split('?');
      if (url_a[0] === '/search') {
        if (url_a[1]) {
          let k = url_a[1].split('&');
          for (let i = 0; i < k.length; i++) {
            let v = k[i].split('=');
            v[1] = v[1].replace(/%20/g, ' ');
            this.postParams[v[0]] = v[1]
          }
        }
        this.router.navigate(['' + url_a[0] + ''], { queryParams: this.postParams, replaceUrl: false });
      } else {
        url_a[0] =  url_a[0].replace(/%252F/g,'/');
        url_a[0] =  url_a[0].replace(/%2F/g,'/');
        this.router.navigate(['' + url_a[0] + '']);
      }
    } else {
      this.router.navigate(['/']);
    }
  }
  showProductCartModal(_event) {
    this.productCartModal.showProductCartModal();
  }
}
