
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { GlobalService } from '../../globals/global.service';
import 'rxjs/Rx';

@Injectable()
export class PointsService{
    constructor(private http: HttpClient, private _httpClient: HttpClient, public globalService: GlobalService) { }

    getUsedPoints(pagination,value) {
        const userSettings = this.globalService.getUserSettings();
        const userData = this.globalService.getLoggedInData();
        return this.http.get(`${environment.ENVIRONMENT.baseURL}${userSettings.LanguageCode}/GetPointsInfo?UserId=${userData.UserID}&PageSize=${pagination.pageSize}&PageIndex=${pagination.pageIndex}&Value=${value}`, {responseType: 'json'});
      }
} 