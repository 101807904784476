import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from "@angular/core";
import { CommonServicesService } from "app/linknet-common/common-services/common-services.service";
import { GlobalService } from "app/linknet-common/globals/global.service";
import { CampaignService, VoucherService, WebshopService} from "app/linknet-common/restful-services/restful.service";
import { Subscription } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { Router } from '@angular/router';

@Component({
  selector: "shop-app-header-two",
  templateUrl: "./header-two.component.html",
  styleUrls: ["./header-two.component.scss"],
})
export class HeaderTwoComponent implements OnInit {
  public webshopDetail;
  public wsaStatus: Boolean = false;
  public msmwsStatus: Boolean = false;
  public foaStatus: Boolean = false;
  public boStatus: Boolean = false;
  public biomarStatus: Boolean = false;
  public gnStatus: Boolean = false;
  public isParentWebshop: Boolean = true;
  public goldenberryStatus: Boolean = false;
  public footerLinksList: any;
  public FAQLink: any;
  public balancePoints: any = 0;
  public campaignList = [];
  public optionsSelectCampaign = [];
  public campaignDisabled: Boolean = true;
  selectedCampaign: any;
  public cartParams: any;
  @Output() selectCampaign = new EventEmitter();
  userPoints: Subscription;
  tempPoints: Subscription;
  @ViewChild("DeleteModal") public deleteModal;
  @ViewChild("DeleteModalOneItem") public deleteModalOneItem;
  @ViewChild("campaignModal") public campaignModal;
  public loaderShow: Boolean = false;
  public campaignEvent: any;
  public campaignName;
  public cartCount;
  public getShoppingCartIDList: any;
  public isVoucherKart: boolean = false;
  public isCorpKonnex: boolean = false;
  CID: any;
  isMobile: boolean;
  voucherList: Object;
  voucherListFilter: any = [];
  showSubcategory: boolean = false;
  showMenu: boolean;
  isSmartworks: boolean = false;
  hrm: boolean = false;
  @ViewChild("productCartModal") public productCartModal;
  loggedInData: any;
  isSpringboard: boolean = false;
  isPenna: boolean = false;
  isGrowWell: boolean = false;

  constructor(
    private globalService: GlobalService,
    private _http: WebshopService,
    public campaignService: CampaignService,
    private commonService: CommonServicesService,
    private toastr: ToastrService,
    public router: Router,
    private httpVoucher: VoucherService
  ) {
    if (/Android|webOS|iPhone|iPad|iPod|pocket|psp|kindle|avantgo|blazer|midori|Tablet|Palm|maemo|plucker|phone|BlackBerry|symbian|IEMobile|mobile|ZuneWP7|Windows Phone|Opera Mini/i.test(navigator.userAgent)) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    const domainName = JSON.parse(sessionStorage.getItem("webshopDetail"));
    this.loggedInData = this.globalService.getLoggedInData();
    if(domainName.DomainName === "247hrm"){
      this.hrm = true;
    }
    if (domainName.ParentCustomerDomainName === domainName.DomainName) {
      this.isParentWebshop = true;
      if(domainName.DomainName === "smartworks"){
        this.isSmartworks = true;
      }
       
    } else {
      this.isParentWebshop = false;
      if(domainName.DomainName === "voucherkart"){
        this.isVoucherKart = true;
      }
      else if(domainName.DomainName === "sw"){
        this.isSmartworks = true;
      }
      else if(domainName.DomainName === "91sb") {
        this.isSpringboard = true;
      }
    }
    if(domainName.DomainName === "corpkonnex" || domainName.DomainName === "ckonnex"){
      this.isCorpKonnex = true;
    }
    if(domainName.DomainName === "pc"){
      this.isPenna = true;
    }
    if(domainName.DomainName === "growmore" || domainName.DomainName === "gm"){
      this.isGrowWell = true;
    }

    let a = JSON.parse(sessionStorage.getItem('campaign'));

    if(a){
      this.campaignName = a['CampaignName'];  
      this.CID = this.router.routerState.snapshot.url;
      this.CID = this.CID.split("Status=");
      if(this.CID[1] && this.CID[1] === 'Success'){
      }else{
        this.commonService.setPoints(a['BalancePoints']);
        this.campaignService.setActiveCampaign(a)
      }
    }

    this._http.getCount().subscribe((c)=>{
      this.cartCount = c;
    })

  }


  ngOnInit() {
    if(!this.isParentWebshop || this.isVoucherKart){
          this.commonService.getPoints().subscribe((res) => {
            this.balancePoints = res;
            if(this.balancePoints && this.isVoucherKart){
            //this.commonService.setPoints(this.balancePoints);
            }
          });
    
          this.campaignService.getCampaignStatus().subscribe((res) => {
            this.campaignDisabled = res;
          });
    }
    let lang_code = "";
    const userSettings = JSON.parse(sessionStorage.getItem("userSettings"));
    if (userSettings !== null && userSettings !== undefined && userSettings !== "") {
      lang_code = userSettings.LanguageCode;
    } else {
      lang_code = "en";
    }
    // if (customerName === 'wsa') {
  
    
    this.getFooterLinks();
    
  }

  ngAfterViewInit(){
    if (!this.isSmartworks && (this.isVoucherKart || (!this.isParentWebshop && this.router.url  === '/' ))) {
      let a = JSON.parse(sessionStorage.getItem('campaign'));
      if (!a) {
        this.changeCampaign();
      }else{
        this.campaignService.setActiveCampaign(a);
      }
      if(this.isVoucherKart){
        this.changeCampaign();
      }
    }
    if(this.isSmartworks && this.router.url  === '/'){
      this.changeCampaign();
    }
    if(this.hrm && this.router.url  === '/'){
      this.changeCampaign();
    }
  }

  

  getFooterLinks() {
    this._http.getFooterLinks().subscribe((data) => {
      this.footerLinksList = data["ItemsCollection"];
      if (this.footerLinksList && this.footerLinksList.length > 0) {
        for (var item of this.footerLinksList) {
          if (item.Description == "FAQ") {
            this.FAQLink = true;
            break;
          } else {
            this.FAQLink = false;
          }
        }
      }
    });
  }


  cancelChange(){
    this.deleteModal.hide();
  }

  changeConfirm(){
    let a = 0;
    const tempCampaign = this.campaignList.filter(
      (item) => item.RewardId === this.campaignEvent.value
    );
    this.selectedCampaign = tempCampaign[0].value;
    sessionStorage.setItem('campaign', this.selectedCampaign);
    this.campaignService.setActiveCampaign(tempCampaign);
    this.tempPoints = this.commonService.getPoints().subscribe((res) => {
      this.balancePoints = res;
      if(a === 0){
        a++;
        this.commonService.setPoints(this.balancePoints);
      }
    });
    this.tempPoints.unsubscribe();
  }

  clearAdvanceSearchData() {}

  changeCampaign(){
    if(!this.isVoucherKart && !this.isSmartworks && !this.hrm){
      this.campaignModal.show({
        backdrop: 'static',
				keyboard: false
      });
    };
    this.loaderShow = true;
    this.campaignService.getCampaignForUser().subscribe((data: any) => {
      if(data) {
        //sessionStorage.setItem('campaign', JSON.stringify(data))
        this.loaderShow = false;
        this.campaignList = data;
        if(this.isVoucherKart){
          this.campaignList = this.campaignList.filter((e:any)=>{return e.CampaignDesc === "voucherkart"});
          this.campaignService.setActiveCampaign(177);
          this.campaignSelect(this.campaignList[0]);
          this.balancePoints = this.campaignList[0].BalancePoints
          this.commonService.setPoints(this.campaignList[0].BalancePoints);
        }else{
          if(this.isSmartworks && !this.isParentWebshop){
            this.campaignService.setActiveCampaign(295);
            this.campaignSelect(this.campaignList[0]);
          }
          else if(this.hrm && !this.isParentWebshop){
            this.campaignService.setActiveCampaign(433);
            if(this.campaignList[0] !== undefined){
              this.campaignSelect(this.campaignList[0]);
            }
          }else{
            this.campaignList = this.campaignList.filter((e:any)=>{ return e.Aprovel === 'Aproved'});
          }
        }
      }
    },_error=>{
      this.loaderShow = false;
    });
  }

  campaignSelect(id) {
    let a = this.campaignList;
    sessionStorage.setItem('campaign', JSON.stringify(this.campaignList))
    if (!a || (a['CampaignId'] !== id['CampaignId'])) {
      sessionStorage.setItem('campaign', JSON.stringify(id));
      this.commonService.setPoints(id.BalancePoints);
      this.campaignService.setActiveCampaign(id);
    }
    this.campaignName = id['CampaignName'];
    this.campaignModal.hide();
    if(!this.isVoucherKart && !this.isSmartworks){
      this.deleteShoppingCart();
    }
    if(!this.isVoucherKart && !this.isSmartworks && this.router.url  !== '/'){
      this.router.navigate(['/']);
    }
  }

  deleteShoppingCart() {
    const sessionShoppingCartData = JSON.parse(sessionStorage.getItem("shoppingCartDatas"));
    let a = JSON.parse(sessionStorage.getItem('campaign'));
    this.getShoppingCartIDList = [];
    if (sessionShoppingCartData && sessionShoppingCartData.length > 0) {
      let len3 = sessionShoppingCartData.length;
      for (let i = 0; i < len3; i++) {
        this.getShoppingCartIDList.push(sessionShoppingCartData[i].ShoppingCartItemID);
      }
    }
    if (this.getShoppingCartIDList.length > 0) {
      const userSettings = this.globalService.getUserSettings();
      const webShopSettings = this.globalService.webShopSettings();
      const loggedInData = this.globalService.getLoggedInData();
      
      if (loggedInData !== null) {
        this.cartParams = {
          UserID: loggedInData.UserID,
          CustomerID: webShopSettings.CustomerID,
          CurrencyID: userSettings.CurrencyID,
          DeleteShoppingCartItemIDs: this.getShoppingCartIDList,
        };
      } else {
        this.cartParams = {
          UserID: null,
          CustomerID: webShopSettings.CustomerID,
          CurrencyID: userSettings.CurrencyID,
          DeleteShoppingCartItemIDs: this.getShoppingCartIDList,
        };
      }
      try {
        this._http.deleteShoppingCartItem(this.cartParams).subscribe(
          (_data) => {
            this.campaignService.setCartStatus(true)
          },
          (error) => {
            this.toastr.error(error);
          }
        );
      } catch (error) {
        // this.toastr.error(error);
      }
    }

  }

  goHome(){
    sessionStorage.removeItem('selectedCatId');
    this.router.navigate(['/']);
    
    if(this.isMobile){
      var element = document.querySelectorAll(".navbar-toggler")[1] as HTMLElement;
      if(element){
        element.click();
      }
    }
  }
  
  SecondaryMenu() {
    if(this.isMobile){
      var element = document.querySelectorAll(".navbar-toggler")[1] as HTMLElement;
      if(element){
        element.click();
      }
    }
  }

  voucherKart(){
  //  this.campaignModal.hide();
   // this.router.navigate(['/vouchers']);
  }

  ngOnDestroy(): void {
    if (this.userPoints) {
      this.userPoints.unsubscribe();
    }
  }

  showProductCartModal() {
    this.productCartModal.showCartModal();
  }
  
  logout() {
    try {
      // Remove saved data from sessionStorage
      if(!this.isParentWebshop){
        this.commonService.setPoints(0);
      }
      // based on filter section regarding product tags.
      if (sessionStorage.getItem("productTags") !== null && sessionStorage.getItem("productTags") !== undefined && sessionStorage.getItem("productTags") !== "") {
        sessionStorage.removeItem("productTags");
      }
      // Remove all saved data from sessionStorage
      const webshopDetail = sessionStorage.getItem("webshopDetail");
      sessionStorage.setItem("webshopDetail", webshopDetail);
      const data = JSON.parse(sessionStorage.getItem("webshopDetail"));
      //this.loggedInData = {};
      this.globalService.loggedin(null);
      // this.router.navigate([''], { preserveQueryParams: false, replaceUrl: true });
      this.globalService.overwriteSession("userSettings", "LanguageCode", data.DefaultLanguageCode);
      this.globalService.overwriteSession("userSettings", "LanguageID", data.DefaultLanguageID);
      this.globalService.overwriteSession("userSettings", "CurrencyCode", data.DefaultCurrencyCode);
      this.globalService.overwriteSession("userSettings", "CurrencyID", data.DefaultCurrencyID);
      //  location.reload();
      setTimeout(() => {
        sessionStorage.removeItem("webshopDetail");
        sessionStorage.removeItem("themeReApplyStatus");
      }, 100);
      this.router.navigate(['/']);
      setTimeout(() => {
          location.reload();
      }, 200);
        // location.reload();
      
    } catch (error) {
      // this.toastr.error(error);
    }
    setTimeout(() => {
    sessionStorage.clear();
    sessionStorage.removeItem("categoryList");
    sessionStorage.removeItem("colorsList");
    sessionStorage.removeItem("categoryOptionList");
    }, 100);
  }
}
