import { Component, OnInit, ViewChild } from '@angular/core';
import { PointsService, ProductService } from '../../linknet-common/restful-services/restful.service';
import { GlobalService } from '../../linknet-common/globals/global.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'shop-app-points-history',
    templateUrl: './points-history.component.html',
    styleUrls: ['./points-history.component.scss']
  })
export class PointsHistoryComponent implements OnInit {

    public loaderShow: Boolean = false;
    public pointsHistoryList = [];
    public perPageCount = 20;
    public totalCount;
    public currentPage = 1;
    public isVoucherKart :boolean = false;
    webshopName: any;
    pointShow: string = "C";

    constructor(private _httpOrdersService: PointsService, public router: Router, private toastr: ToastrService){
      const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if(webshopDetail.DomainName === "voucherkart"){
        this.isVoucherKart = true;
      }
    }

    ngOnInit(): void {
        const webshopDetail = JSON.parse(sessionStorage.getItem("webshopDetail"));
        this.webshopName = webshopDetail;
        if(this.webshopName.DomainName === "voucherkart"){
          this.isVoucherKart = true;
        }else{
          this.isVoucherKart = false;
        }
        const userDetails = JSON.parse(sessionStorage.getItem('loggedInData'));
        if (userDetails === undefined || userDetails === null) {
        this.router.navigate(['/'], { replaceUrl: false });
        } else {
          const pages = {
            'pageIndex': 1,
            'pageSize': 20
          }
        this.getReardPointsHistory(pages);
        }
    }

    pointsChange(event:string){
      this.pointShow = event;
      const pages = {
        'pageIndex': 1,
        'pageSize': 20
      }
      this.getReardPointsHistory(pages);
    }


    getReardPointsHistory(valueePageChange){
        try {
            this.loaderShow = true;
            this._httpOrdersService.getUsedPoints(valueePageChange,this.pointShow).subscribe(
              (data: any[]) => {
                this.loaderShow = false;
                this.pointsHistoryList = data;
                this.totalCount =  this.pointsHistoryList.length !== 0 ? data[0]['TotalCount'] : 0;
                this.currentPage = 1;
                this.perPageCount = 20;
              }, error => {
                this.toastr.error(error.error.Message);
              })
          } catch (error) {
            this.toastr.error(error);
          }
    }

    paginate(){

    }

}