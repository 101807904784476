import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { GlobalService } from '../../globals/global.service';
import 'rxjs/Rx';
import { map } from 'rxjs/operators';
import { WebshopModule } from 'app/webshop/webshop.module';

@Injectable()
export class CategoryService {

  public data;
  constructor(private http: HttpClient, public globalService: GlobalService) { }

  /**
     * Service for Categories
  */

  getMainCatgeories(){
    const webShopSettings = this.globalService.webShopSettings();
    const userData = this.globalService.getLoggedInData();
    const userSettings = this.globalService.getUserSettings();
    return this.http.get(environment.ENVIRONMENT.baseURL
      + userSettings.LanguageCode + '/Topwebshopcategories?customerID='
      + webShopSettings.CustomerID + (userData != null ? '&userID=' + userData.UserID : ''), {responseType: 'json'}).publishReplay(1)
      .refCount();
  }

  getCategories(categoryId = null) {
    let params = '';
    if (categoryId !== null) {
      params = '/' + categoryId;
    }
    const userSettings = this.globalService.getUserSettings();
    const webShopSettings = this.globalService.webShopSettings();
    const userData = this.globalService.getLoggedInData();
    // return this.http.get(environment.ENVIRONMENT.baseURL
    //   + userSettings.LanguageCode + '/categories' + params + '?filterBatchProducts=true&noEmptyCategories=true&CustomerID='
    //   + webShopSettings.CustomerID).map(
    //   (response: Response) => (response.json())
    //   ).publishReplay(1)
    //   .refCount();

    // return this.http.get(environment.ENVIRONMENT.baseURL
    //   + userSettings.LanguageCode + '/webshopcategories' + params + '?customerID='
    //   + webShopSettings.CustomerID).pipe(map(
    //   (response: Response) => (response.json()))
    //   ).publishReplay(1)
    //   .refCount();

    // let campaignRaw = sessionStorage.getItem('campaign');
    // let campagin = campaignRaw ? JSON.parse(campaignRaw) : null;
    let campaignRaw = sessionStorage.getItem('campaign');
    let campagin = null;
    if (campaignRaw && campaignRaw !== "undefined") {
    try {
      campagin = JSON.parse(campaignRaw);
    } catch (e) {
        console.error("Error parsing campaign JSON:", e);
        // Handle the error as needed
    }
    }
    //  let campagin = JSON.parse(sessionStorage.getItem('campaign'))
    if((webShopSettings.ParentCustomerDomainName === webShopSettings.DomainName) || !campagin){
    return this.http.get(environment.ENVIRONMENT.baseURL
      + userSettings.LanguageCode + '/webshopcategories' + params + '?customerID='
      + webShopSettings.CustomerID + (userData != null ? '&userID=' + userData.UserID : ''), {responseType: 'json'}).publishReplay(1)
      .refCount();
    }
    // else{
    //   return this.http.get(environment.ENVIRONMENT.baseURL
    //     + userSettings.LanguageCode + '/webshopcategories' + params + '?customerID='
    //     + webShopSettings.CustomerID + (userData != null ? '&userID=' + userData.UserID : '')+ '&CampaignId='+ campagin.CampaignId +'&IsChildShop=true', {responseType: 'json'}).publishReplay(1)
    //     .refCount();
    // }
  }
}
