import { Component, OnInit } from "@angular/core";
import { WebshopService } from "app/linknet-common/restful-services/restful.service";
import { VoucherService } from "app/linknet-common/restful-services/restful.service";
import { ToastrService } from "ngx-toastr";
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from "@angular/router";
import {NgbAccordion, NgbAccordionConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'shop-app-voucher-billboard',
    templateUrl: './voucher-billboard.component.html',
    styleUrls: ['./voucher-billboard.component.scss']
})

export class VoucherBillboardComponent implements OnInit{
    public billBoardList: any;
    public billBoardLists: any;
    isParentCustomer: boolean = true;
    isMobile: boolean = false;
    campaignId: any;
    public myInterval: number = 10000;
    voucherList:any;
    customOptions: OwlOptions = {
      loop: false,
      margin: 10,
      autoplay:true,
      dots: false,
      navSpeed: 700,
      navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
      responsive: {
        0: {
          items: 2
        },
        480: {
          items: 4
        },
        940: {
          items: 6
        }
      },
      nav: true
    }
    webshopName:any;
    isVoucherKart:boolean = false;
    constructor(private router: Router,config: NgbAccordionConfig, private _httpWebshop: WebshopService, private toastr: ToastrService, private httpVoucher: VoucherService){
      if (window.innerWidth > 768) {
        this.isMobile = false;
      }else{
        this.isMobile = true;
        config.closeOthers = true;
      }
      const webshopDetail = JSON.parse(sessionStorage.getItem("webshopDetail"));
      this.webshopName = webshopDetail;
      if(this.webshopName.DomainName === "voucherkart"){
        this.isVoucherKart = true;
      }else{
        this.isVoucherKart = false;
      }
    }
    ngOnInit() {
      this.getVoucherList();
      this.getAllBillBoardList();
      if(this.isMobile){
        this.getMobileBillBoardList();
      }
    }

    getVoucherList(){
      this.httpVoucher.getVoucherCategories().subscribe(data=>{
          this.voucherList = data;
      })
    }
    
    getAllBillBoardList() {
        try {
          let check;
          if(this.isParentCustomer){
           check = this._httpWebshop.getAllBillBoards();
          }else{
            check = this._httpWebshop.getAllCmapaignBillBoards(this.campaignId);
          }
          check.subscribe(
            (data: any[]) => {
              this.billBoardList = data["ItemsCollection"];
              this.billBoardList = this.billBoardList.filter(item => item.VisibleOnLinkNetwork === true);
              //sessionStorage.setItem('billBoardList', JSON.stringify(this.billBoardList));
            },
            (error) => {
              this.toastr.error(error);
            }
          );
        } catch (error) {
          // this.toastr.error(error);
        }
      }
      
      voucherProducts(type, id){
        this.router.navigate(['/vouchers', type, id], { replaceUrl: false })
      }

      openLink(billBoard) {
       
          billBoard.AdURL = billBoard.AdURL.replace(/ /g, "");
          //billBoard.AdURL = billBoard.AdURL.toLowerCase();
          if (billBoard.AdURL !== "" && billBoard.AdURL.indexOf("newsletter") <= 0) {
            window.open(billBoard.AdURL);
          } else if (billBoard.AdURL.indexOf("newsletter") > 0) {

          }
        
      }

      getMobileBillBoardList() {
        this._httpWebshop.getMobBillBoards().subscribe(
          (data: any) => {
            this.billBoardLists = data["ItemsCollection"];
            sessionStorage.setItem("mobileBillBoard", JSON.stringify(data["ItemsCollection"]));
            this.billBoardLists = this.billBoardLists.filter(item => item.VisibleOnLinkNetwork === true);
          },
          (error) => {
            console.error('An error occurred:', error);
          }
        );
      }
}